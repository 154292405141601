import { AppThunk } from "../../store/AppThunkType";
import { authService } from "./authService";
import { loginStarted, loginSuccessful, loginFailed } from "./actions";
import database from "../../Database";
import database_Local from "../../Database_Local";
import { UserProfile } from "../../models/UserProfile";
import { User } from "../../models/User";
import { isPlatform } from "@ionic/react";

export function getSyncGatewayDetails() {
    return {
        syncGatewayAddress: isPlatform("hybrid")
            ? process.env.REACT_APP_SYNC_GATEWAY_ADDRESS
            : process.env.REACT_APP_SYNC_GATEWAY_API,
        syncGatewayDatabase: process.env.REACT_APP_SYNC_GATEWAY_DB_NAME,
        isSsl:
            (isPlatform("hybrid")
                ? process.env.REACT_APP_SYNC_GATEWAY_IS_SECURE
                : process.env.REACT_APP_SYNC_GATEWAY_API_IS_SECURE) === "true",
    };
}

export function login(username: string, password: string): AppThunk {
    return function (dispatch) {
        dispatch(loginStarted());

        authService.login(username, password).then(
            (userProfile) => {
                const { syncGatewayAddress, syncGatewayDatabase, isSsl } = getSyncGatewayDetails();

                database_Local.save("userProfile", userProfile);

                database
                    .openDatabase(
                        syncGatewayAddress,
                        isSsl,
                        syncGatewayDatabase,
                        userProfile.email,
                        userProfile.syncGatewaySession
                    )
                    .then(() => {
                        if (userProfile.clientUsers.length === 1) {
                            const defaultCurrentUser = userProfile.clientUsers[0];
                            database_Local.save("currentUser", defaultCurrentUser);
                            dispatch(loginSuccessful(userProfile, defaultCurrentUser));
                        } else {
                            dispatch(loginSuccessful(userProfile));
                        }
                    })
                    .catch((error: any) => {
                        console.log(error);
                        dispatch(loginFailed(new Error("Invalid username or password!")));
                    });
            },
            (error) => {
                console.log(error);
                dispatch(loginFailed(new Error("Invalid username or password!")));
            }
        );
    };
}

export function loginWithLastSession(userProfile: UserProfile, currentUser: User): AppThunk {
    return function (dispatch) {
        const { syncGatewayAddress, syncGatewayDatabase, isSsl } = getSyncGatewayDetails();

        database
            .openDatabase(
                syncGatewayAddress,
                isSsl,
                syncGatewayDatabase,
                userProfile.email,
                userProfile.syncGatewaySession
            )
            .then(() => {
                dispatch(loginSuccessful(userProfile, currentUser));
            })
            .catch((error: any) => {
                console.log(error);
                dispatch(loginFailed(new Error("Invalid username or password!")));
            });
    };
}
