import { PermitGangMember } from "../../models/PermitGangMember";
import { GangMemberSignature } from "../../models/GangMemberSignature";
import { RawFile } from "../../models/RawFile";

// Constants
export const ADD_GANG_MEMBER = "ADD_GANG_MEMBER";
export const DELETE_GANG_MEMBER = "DELETE_GANG_MEMBER";
export const UPDATE_GANG_MEMBER = "UPDATE_GANG_MEMBER";
export const SET_GANG_MEMBER_UPDATE_MESSAGE = "SET_GANG_MEMBER_UPDATE_MESSAGE";
export const SUBMIT_GANG_MEMBER_SIGNATURE = "SUBMIT_GANG_MEMBER_SIGNATURE";

// Action Types
interface AddPermitGangMemberAction {
    type: typeof ADD_GANG_MEMBER;
    permitGangMember: PermitGangMember;
}

interface DeletePermitGangMemberAction {
    type: typeof DELETE_GANG_MEMBER;
    permitGangMember: PermitGangMember;
}

interface UpdatePermitGangMemberAction {
    type: typeof UPDATE_GANG_MEMBER;
    permitGangMember: PermitGangMember;
}

interface SetPermitGangMemberUpdateMessageAction {
    type: typeof SET_GANG_MEMBER_UPDATE_MESSAGE;
    message: string;
}

interface SubmitGangMemberSignatureAction {
    type: typeof SUBMIT_GANG_MEMBER_SIGNATURE;
    permitGangMemberId: string;
    signature: GangMemberSignature;
    signatureFile: RawFile;
}

export type EightPointChecklistTabActionTypes =
    | AddPermitGangMemberAction
    | DeletePermitGangMemberAction
    | UpdatePermitGangMemberAction
    | SetPermitGangMemberUpdateMessageAction
    | SubmitGangMemberSignatureAction;
