import React, { useEffect, useState } from "react";
import { Polygon } from "geojson";
import { URLOpenListenerEvent, App } from "@capacitor/app";
import Map from "./Map";
import { EsriConfig } from "../models/Client";
import { IonFab, IonFabButton, IonIcon, isPlatform } from "@ionic/react";
import { cubeOutline } from "ionicons/icons";
import { AppLauncher } from "@capacitor/app-launcher";
import { CreateMapItem, MapItem } from "../arcgisUtils";
import { Project } from "../models/Project";
import locationService from "../LocationService";

interface PermitBoundariesProps {
    esriConfig: EsriConfig;
    boundaries?: Polygon;
    isReadOnly: boolean;
    project?: Project;
}

const PermitBoundariesTab: React.FC<PermitBoundariesProps> = ({ esriConfig, boundaries, isReadOnly, project }) => {
    const [points, setPoints] = useState<MapItem[]>([]);
    const [token, setToken] = useState("");

    useEffect(() => {
        locationService.getCurrentPosition().then((position) => {
            setPoints([
                {
                    geometry: {
                        type: "point",
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    } as __esri.Point,
                },
            ]);
        });
    }, []);

    useEffect(() => {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            const accessToken = event.url.split("access_token=").pop();
            const token = accessToken?.split("&expires_in")[0];
            if (token) {
                setToken(token);
            }
        });
    }, []);

    const geometry = {
        type: "polygon",
        rings: boundaries?.coordinates as number[][][],
        spatialReference: { wkid: 4326 },
    } as __esri.Polygon;

    const runOnDevice = isPlatform("hybrid");

    const handleArButtonClick = async () => {
        var url = isPlatform("ios") ? "meemimvgis://deeplinks?" : "com.meemim.vgis";
        const { value } = await AppLauncher.canOpenUrl({ url });

        //if (value) {
        await AppLauncher.openUrl({ url });
        //}
    };

    const projectBoundaries =
        project && project.boundaries
            ? [
                  CreateMapItem(
                      "polyline",
                      project.boundaries.coordinates as number[][][],
                      project.id,
                      "View project",
                      `Project: ${project.name} (${project.number})`,
                      "",
                      false,
                      { r: 119, g: 226, b: 40 } as __esri.Color
                  ),
              ]
            : [];

    const items = [...projectBoundaries, ...points, { geometry: geometry }];
    console.log(items);

    return (
        <>
            <Map token={token} webMapItemId={esriConfig.webMapItemId} items={items} />
            {runOnDevice && (
                <IonFab horizontal="end" vertical="bottom" style={{ marginBottom: 70 }} onClick={handleArButtonClick}>
                    <IonFabButton>
                        <IonIcon icon={cubeOutline} />
                    </IonFabButton>
                </IonFab>
            )}
        </>
    );
};

export default PermitBoundariesTab;
