import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import MyPermitsReducer from "../containers/MyPermitsContainer/reducer";
import PermitReducer from "../containers/PermitContainer/reducer";
import PermitDetailsTabContainerReducer from "../containers/PermitDetailsTabContainer/reducer";
import AuthorisationTabContainerReducer from "../containers/SignaturesTabContainer/reducer";
import EightPointChecklistTabContainerReducer from "../containers/EightPointChecklistTabContainer/reducer";
import LoginReducer from "../containers/LoginContainer/reducer";
import AddPermitGangMemberReducer from "../containers/AddPermitGangMemberContainer/reducer";
import PermitAssignmentsReducer from "../containers/PermitAssignmentsContainer/reducer";
import { PermitState } from "../containers/PermitContainer/types";
import { LOAD_MY_PERMITS_STARTED, LOGOUT } from "../containers/MyPermitsContainer/types";
import { RootStateOrAny } from "react-redux";

// interface RootState {
//     permitPage?: PermitState;
//     addPermitGangMember?: GangMembersState;
//     permitAssignments?: PermitAssignmentsState;
//     myPermits?: MyPermitsState;
//     user?: UserState;
// }

const rootReducer = (state: RootStateOrAny, action: any) => {
    switch (action.type) {
        case LOAD_MY_PERMITS_STARTED:
            state = {
                ...state,
                permitPage: undefined,
                addPermitGangMember: undefined,
            };
            break;
        case LOGOUT:
            state = {
                ...state,
                permitPage: undefined,
                addPermitGangMember: undefined,
                permitAssignments: undefined,
                myPermits: undefined,
                user: undefined,
            };
            break;
        default:
            break;
    }

    return appReducer(state, action);
};

const appReducer = combineReducers({
    myPermits: MyPermitsReducer,
    permitPage: (state: PermitState | undefined, action) => {
        state = PermitReducer(state, action);
        state = PermitDetailsTabContainerReducer(state, action);
        state = AuthorisationTabContainerReducer(state, action);
        return EightPointChecklistTabContainerReducer(state, action);
    },
    user: LoginReducer,
    addPermitGangMember: AddPermitGangMemberReducer,
    permitAssignments: PermitAssignmentsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
    const middlewares = [thunkMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    const store = createStore(rootReducer, middleWareEnhancer);

    return store;
}
