import React, { useState, useEffect } from "react";
import { IonText, IonItem, IonLabel, IonImg, IonSpinner } from "@ionic/react";
import moment from "moment";
import SignatureModal from "./SignatureModal";
import database from "../Database";
import { Capacitor } from "@capacitor/core";
import { isToday } from "../DateService";

interface Props {
    title?: string;
    date?: Date;
    isAbsent?: boolean;
    dateSigned?: Date;
    permitId: string;
    signatureFileKey?: string;
    onSignatureSubmitted: (dataUrl: string) => void;
    onValidation?: () => boolean;
    isReadOnly?: boolean;
    signatoryName: string;
}

const CustomSignItem: React.FC<Props> = ({
    title,
    date,
    isAbsent,
    dateSigned,
    permitId,
    signatureFileKey,
    onSignatureSubmitted,
    onValidation,
    isReadOnly,
    signatoryName,
}) => {
    const isSigned = dateSigned !== undefined && dateSigned !== null;

    const [filePath, setFilePath] = useState("");

    useEffect(() => {
        if (signatureFileKey) {
            database.loadPermitFile(permitId, signatureFileKey).then(
                (result: any) => {
                    if (result) {
                        setFilePath(Capacitor.convertFileSrc(result));
                    }
                },
                (err) => {
                    setFilePath("");
                }
            );
        }
    });

    return (
        <IonItem lines="none">
            <IonLabel class="ion-text-wrap">
                {title && (
                    <IonText color="medium">
                        <h3>{title}</h3>
                    </IonText>
                )}
                {isSigned ? (
                    <>
                        <IonLabel>
                            <p>{moment(dateSigned).format("L") + " - " + moment(dateSigned).format("LT")}</p>
                        </IonLabel>
                        {filePath ? (
                            <IonImg src={filePath} style={{ height: "50pt", width: "fit-content" }} />
                        ) : (
                            <IonSpinner name="dots" />
                        )}
                    </>
                ) : (
                    <>
                        {date ? (
                            <>
                                <IonLabel>
                                    <p>{moment(date).format("L")}</p>
                                </IonLabel>

                                {isAbsent ? (
                                    <p>
                                        <IonLabel class="ion-text-wrap">Absent</IonLabel>
                                    </p>
                                ) : isToday(date) ? (
                                    <SignatureModal
                                        buttonLabel="sign"
                                        signatoryName={signatoryName}
                                        onSubmit={onSignatureSubmitted}
                                        onModalOpening={onValidation}
                                        isReadOnly={isReadOnly}
                                    />
                                ) : (
                                    <p>
                                        <IonLabel class="ion-text-wrap">Not Signed</IonLabel>
                                    </p>
                                )}
                            </>
                        ) : (
                            <SignatureModal
                                buttonLabel="sign"
                                signatoryName={signatoryName}
                                onSubmit={onSignatureSubmitted}
                                onModalOpening={onValidation}
                                isReadOnly={isReadOnly}
                            />
                        )}
                    </>
                )}
            </IonLabel>
        </IonItem>
    );
};

export default CustomSignItem;
