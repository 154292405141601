import { PermitGangMember } from "../../models/PermitGangMember";
import { isToday } from "../../DateService";
import { GangMember } from "../../models/GangMember";
import { GangMemberSignature } from "../../models/GangMemberSignature";
import { Guid } from "guid-typescript";
import { ISSUED, READY_TO_ISSUE } from "../../constants";

function removePermitGangMemberFromPermit(
    permitGangMember: PermitGangMember,
    deletePermitGangMemberCallback: (permitGangMember: PermitGangMember) => void,
    setPermitGangMemberUpdateMessageCallback: (message: string) => void,
    permitStatus: string
) {
    const permitGangMemberName = permitGangMember.gangMember.firstName + " " + permitGangMember.gangMember.lastName;

    //If permit state is ready to issue the gangmember is deleted no matter if already signed.
    //If there is any signature, the signatures will be deleted as well
    if (permitStatus === READY_TO_ISSUE) {
        deletePermitGangMemberCallback(permitGangMember);
        setPermitGangMemberUpdateMessageCallback(
            `${permitGangMemberName} removed from gang members list successfully!`
        );
        return;
    }
}

function markGangMemberAsAbsent(
    permitGangMember: PermitGangMember,
    updatePermitGangMemberCallback: (permitGangMember: PermitGangMember) => void,
    setPermitGangMemberUpdateMessageCallback: (message: string) => void,
    permitStatus: string
) {
    const permitGangMemberName = permitGangMember.gangMember.firstName + " " + permitGangMember.gangMember.lastName;

    if (permitStatus === ISSUED) {
        const hasGangMemberSignedToday =
            permitGangMember.signatures.filter((a) => isToday(a.date) && a.dateSigned).length > 0;

        if (hasGangMemberSignedToday) {
            setPermitGangMemberUpdateMessageCallback(
                `${permitGangMemberName} has already signed for the day and cannot be deleted!`
            );
            return;
        }

        //Implements absent scenario
        const permitGangMemberUpdated = { ...permitGangMember };
        const absentSignature = createGangMemberSignature(new Date());
        absentSignature.isAbsent = true;
        absentSignature.pendingSync = true;
        permitGangMemberUpdated.signatures.push(absentSignature);
        updatePermitGangMemberCallback(permitGangMemberUpdated);
        setPermitGangMemberUpdateMessageCallback(`Marked ${permitGangMemberName} as absent for today successfully!`);
    }
}

function createPermitGangMember(gangMember: GangMember) {
    const gangMemberSignatures: GangMemberSignature[] = [];

    let gangMemberObject: any = { ...gangMember };
    delete gangMemberObject["type"];
    delete gangMemberObject["channels"];

    const permitGangMember: PermitGangMember = {
        id: Guid.create().toString(),
        gangMember: gangMemberObject,
        signatures: gangMemberSignatures,
    };

    return permitGangMember;
}

function createGangMemberSignature(date: Date) {
    const gangMemberSignature: GangMemberSignature = {
        id: Guid.create().toString(),
        date: new Date(date),
        loggedInUserId: "",
        signatureFileKey: "",
        isAbsent: false,
    };
    return gangMemberSignature;
}

export { removePermitGangMemberFromPermit, markGangMemberAsAbsent, createPermitGangMember, createGangMemberSignature };
