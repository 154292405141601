import React, { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import PdfViewer from "./PdfViewer";
import database from "../Database";
import { useParams } from "react-router";
import { ReferenceProcedure } from "../models/ReferenceProcedure";

const ReferenceProcedureFile: React.FC = () => {
    const [filePath, setFilePath] = useState("");
    const { docId } = useParams<{ docId: string }>();

    useEffect(() => {
        if (docId) {
            database.queryReferenceProcedure(docId).then((result: ReferenceProcedure) => {
                var referenceProcedure = result;
                if (referenceProcedure) {
                    database.loadReferenceProcedureFile(docId, referenceProcedure.documentKey).then(
                        (result: any) => {
                            setFilePath(result);
                        },
                        (err) => {
                            setFilePath("");
                        }
                    );
                }
            });
        }
    }, [docId]);

    return <IonContent>{filePath !== "" && <PdfViewer path={filePath} />}</IonContent>;
};

export default ReferenceProcedureFile;
