import React from "react";
import { IonLabel, IonIcon, IonButton } from "@ionic/react";
import { cameraOutline, imageOutline } from "ionicons/icons";
import CustomBox from "./CustomBox";
import { Camera as CapCam, CameraResultType, CameraSource, CameraDirection } from "@capacitor/camera";
import { Camera } from "@ionic-native/camera";
import SitePhotosList from "./SitePhotosList";

interface Props {
    sitePhotoFileKeys: string[] | [];
    onSitePhotoCaptured: (dataUrl: string) => void;
    onSitePhotoDeleted: (fileKey: string) => void;
    isReadOnly: boolean;
    permitId: string;
    ableToAddPhotos: boolean;
}

const PermitDetailsSitePhoto: React.FC<Props> = ({
    sitePhotoFileKeys,
    permitId,
    onSitePhotoCaptured,
    onSitePhotoDeleted,
    isReadOnly,
    ableToAddPhotos,
}) => {
    const takePicture = async (from: string) => {
        if (!Camera) {
            const image = await CapCam.getPhoto({
                resultType: CameraResultType.DataUrl,
                source: CameraSource.Prompt,
                direction: CameraDirection.Rear,
            });

            if (image.dataUrl) {
                onSitePhotoCaptured(image.dataUrl);
            }
        } else {
            let sourceType;
            if (from === "fromCamera") {
                sourceType = Camera.PictureSourceType.CAMERA;
            } else if (from === "fromGallery") {
                sourceType = Camera.PictureSourceType.PHOTOLIBRARY;
            }
            Camera.getPicture({
                destinationType: Camera.DestinationType.DATA_URL,
                encodingType: Camera.EncodingType.JPEG,
                sourceType: sourceType,
                correctOrientation: true,
            }).then((imageData: string) => {
                if (imageData) {
                    onSitePhotoCaptured("data:image/jpeg;base64," + imageData);
                }
            });
        }
    };

    return (
        <CustomBox title="Site mark-up photo">
            <SitePhotosList
                permitId={permitId}
                sitePhotoFileKeys={sitePhotoFileKeys}
                onSitePhotoDeleted={onSitePhotoDeleted}
                isReadOnly={isReadOnly}
            />

            {ableToAddPhotos && sitePhotoFileKeys.length < 10 && (
                <IonButton
                    onClick={() => takePicture("fromCamera")}
                    shape="round"
                    color="medium"
                    size="small"
                    style={{ marginLeft: "16px" }}
                >
                    <IonIcon slot="start" icon={cameraOutline} />
                    <IonLabel>Take Photo</IonLabel>
                </IonButton>
            )}
            {ableToAddPhotos && sitePhotoFileKeys.length < 10 && (
                <IonButton
                    onClick={() => takePicture("fromGallery")}
                    shape="round"
                    color="medium"
                    size="small"
                    style={{ marginLeft: "16px" }}
                >
                    <IonIcon slot="start" icon={imageOutline} />
                    <IonLabel>Add from Gallery</IonLabel>
                </IonButton>
            )}
        </CustomBox>
    );
};

export default PermitDetailsSitePhoto;
