import React from "react";
import { IonText, IonItem, IonLabel, IonChip, IonIcon } from "@ionic/react";
import "./CustomItem.css";

interface Props {
    title: string;
    text: string;
    tag?: string;
    tagIcon?: any;
    lines?: "full" | "inset" | "none";
    textColor?: string;
}

const CustomItem: React.FC<Props> = ({ text, title, tag, tagIcon, textColor, lines }) => {
    const showTag = !!tag;

    return (
        <IonItem lines={lines ? lines : undefined}>
            <IonLabel class="ion-text-wrap">
                <IonText color="medium">
                    <h3>{title}</h3>
                </IonText>
                <IonText color={textColor}>
                    <p>{text ? text : "-"}</p>
                </IonText>
            </IonLabel>
            {showTag && (
                <IonChip color="tertiary" slot="end">
                    {tagIcon && <IonIcon icon={tagIcon} color="tertiary" />}
                    <IonLabel color="tertiary">{tag}</IonLabel>
                </IonChip>
            )}
        </IonItem>
    );
};

export default CustomItem;
