import React from "react";
import PermitAuthorisationBox from "./PermitAuthorisationBox";
import { User } from "../models/User";
import { Guid } from "guid-typescript";
import { PermitAuthorisationSignature } from "../models/PermitAuthorisationSignature";
import { PermitAssignment } from "../models/PermitAssignment";
import { Role } from "../models/Role";

interface Props {
    permitId: string;
    assignment: PermitAssignment;
    signature?: PermitAuthorisationSignature;
    role?: Role;
    user?: User;
    onPermitAssignmentSignatureSubmitted: (signature: PermitAuthorisationSignature, signatureDataUrl: string) => void;
    currentUser: User;
    isReadOnly: boolean;
}

const PermitAssignmentSignature: React.FC<Props> = ({
    permitId,
    assignment,
    signature,
    role,
    user,
    onPermitAssignmentSignatureSubmitted,
    currentUser,
    isReadOnly,
}) => {
    const handleSignatureSubmitted = (signatureDataUrl: string) => {
        const signature = {
            id: Guid.create().toString(),
            roleId: assignment.roleId,
            userId: assignment.userId,
            declaration: role?.permitRoleSettings.authorizationDeclaration,
            dateSigned: new Date(),
            loggedInUserId: currentUser.id,
            signatureFileKey: "",
            signatory: {
                name: user?.firstName + " " + user?.lastName,
                roleName: role?.name,
                phoneNumber: user?.telephoneNumber,
            },
        } as PermitAuthorisationSignature;

        onPermitAssignmentSignatureSubmitted(signature, signatureDataUrl);
    };

    const name = signature?.signatory.name || (user ? user.firstName + " " + user.lastName : "");
    const telephoneNumber = signature?.signatory.phoneNumber || (user ? user.telephoneNumber : "");

    return (
        <>
            {role && (
                <PermitAuthorisationBox
                    permitId={permitId}
                    title={role.name}
                    declarations={[role.permitRoleSettings.authorizationDeclaration]}
                    name={name}
                    telephoneNumber={telephoneNumber}
                    dateSigned={signature?.dateSigned}
                    signatureFileKey={signature?.signatureFileKey}
                    onSignatureSubmitted={handleSignatureSubmitted}
                    isReadOnly={isReadOnly}
                />
            )}
        </>
    );
};

export default PermitAssignmentSignature;
