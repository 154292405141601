import { IonProgressBar, IonRippleEffect } from "@ionic/react";
import React from "react";
import "./SyncStatusProgressBar.css";

interface Props {
    currentSyncStatus: string;
    onTryToConnect: () => void;
}

const SyncStatusProgressBar: React.FC<Props> = ({ currentSyncStatus, onTryToConnect }) => {
    const currentStatus = currentSyncStatus.toUpperCase();
    var color = "Primary";
    var type: "determinate" | "indeterminate" = "determinate";

    switch (currentStatus) {
        case "INITIAL":
            color = "medium";
            type = "indeterminate";
            break;
        case "RUNNING":
        case "BUSY":
            color = "primary";
            type = "indeterminate";
            break;
        case "IDLE":
            color = "success";
            break;
        case "OFFLINE":
        case "STOPPING":
        case "STOPPED":
            color = "warning";
            type = "indeterminate";
            break;
    }

    if (currentStatus === "IDLE") return null;
    const disconnect = ["OFFLINE", "STOPPING", "STOPPED"];

    return (
        <>
            {disconnect.includes(currentStatus) ? (
                <div className="wrapper" onTouchStart={onTryToConnect}>
                    <div className="ion-activatable ripple-parent custom-parent">
                        Try to connect
                        <IonRippleEffect className="custom-ripple"></IonRippleEffect>
                    </div>
                </div>
            ) : (
                <IonProgressBar color={color} type={type}></IonProgressBar>
            )}
        </>
    );
};

export default SyncStatusProgressBar;
