import {
    PermitDetailsTabActionTypes,
    UPDATE_PERMIT_ASSIGNEE,
    ADD_SITE_PHOTO,
    DELETE_SITE_PHOTO,
    LOAD_PERMIT_SUBMITTER_SUCCESSFUL,
    UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL,
} from "./types";
import { User } from "../../models/User";
import { RawFile } from "../../models/RawFile";
import { LocationLog } from "../../models/LocationLog";
import { PermitAssignment } from "../../models/PermitAssignment";

export function updatePermitAssignee(assignment: PermitAssignment): PermitDetailsTabActionTypes {
    return {
        type: UPDATE_PERMIT_ASSIGNEE,
        assignment,
    };
}

export function addSitePhoto(sitePhotoFileKey: string, photoFile: RawFile): PermitDetailsTabActionTypes {
    return {
        type: ADD_SITE_PHOTO,
        sitePhotoFileKey,
        photoFile,
    };
}

export function deleteSitePhoto(sitePhotoFileKeys: string[]): PermitDetailsTabActionTypes {
    return {
        type: DELETE_SITE_PHOTO,
        sitePhotoFileKeys,
    };
}

export function loadPermitSubmitterSuccessful(user: User): PermitDetailsTabActionTypes {
    return {
        type: LOAD_PERMIT_SUBMITTER_SUCCESSFUL,
        user,
    };
}

export function updatePermitLastAccessSuccessful(
    permitId: string,
    locationLog: LocationLog
): PermitDetailsTabActionTypes {
    return {
        type: UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL,
        locationLog,
        permitId,
    };
}
