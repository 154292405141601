import {
    GangMembersActionTypes,
    LOAD_GANGMEMBERS_STARTED,
    LOAD_GANGMEMBERS_SUCCESSFUL,
    LOAD_GANGMEMBERS_FAILED,
} from "./types";
import { GangMember } from "../../models/GangMember";

export function loadGangMembersStarted(): GangMembersActionTypes {
    return {
        type: LOAD_GANGMEMBERS_STARTED,
    };
}

export function loadGangMembersSuccessful(gangMembers: GangMember[]): GangMembersActionTypes {
    return {
        type: LOAD_GANGMEMBERS_SUCCESSFUL,
        gangMembers,
    };
}

export function loadGangMembersFailed(error: Error): GangMembersActionTypes {
    return {
        type: LOAD_GANGMEMBERS_FAILED,
        error,
    };
}
