import React from "react";
import {
    IonContent,
    IonFooter,
    IonIcon,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonToast,
    IonToolbar,
} from "@ionic/react";
import EightPointChecklist from "./EightPointChecklist";
import AddPermitGangMember from "./AddPermitGangMember";
import { PermitGangMember } from "../models/PermitGangMember";
import { EightPointCheckList } from "../models/EightPointCheckList";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";

interface Props {
    children: React.ReactNode;
    eightPointCheckList: EightPointCheckList;
    notificationMessage: string;
    onNotificationMessageDisplayed: () => void;
    onPermitGangMemberAdded: (permitGangMember: PermitGangMember) => void;
    isReadOnly: boolean;
    client?: string;
    onNextWeekClick?: () => void;
    onPreviousWeekClick?: () => void;
}

const EightPointChecklistTab: React.FC<Props> = ({
    children,
    eightPointCheckList,
    isReadOnly,
    onPermitGangMemberAdded,
    notificationMessage,
    onNotificationMessageDisplayed,
    client,
    onNextWeekClick,
    onPreviousWeekClick,
}) => {
    return (
        <>
            <IonContent>
                <EightPointChecklist
                    eightPointCheckListItems={eightPointCheckList.items}
                    client={client}
                ></EightPointChecklist>
                <AddPermitGangMember
                    eightPointCheckListDeclaration={eightPointCheckList.declaration}
                    isReadOnly={isReadOnly}
                    onPermitGangMemberAdded={onPermitGangMemberAdded}
                />
                {children}
            </IonContent>
            {(onNextWeekClick || onPreviousWeekClick) && (
                <IonFooter>
                    <IonToolbar>
                        <IonSegment>
                            <IonSegmentButton onClick={onPreviousWeekClick} disabled={!onPreviousWeekClick}>
                                <IonIcon icon={chevronBackOutline}></IonIcon>
                                <IonLabel>Previous week</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton onClick={onNextWeekClick} disabled={!onNextWeekClick}>
                                <IonIcon icon={chevronForwardOutline}></IonIcon>
                                <IonLabel>Next week</IonLabel>
                            </IonSegmentButton>
                        </IonSegment>
                    </IonToolbar>
                </IonFooter>
            )}
            <IonToast
                isOpen={notificationMessage !== ""}
                message={notificationMessage}
                duration={5000}
                position="top"
                onDidDismiss={() => {
                    onNotificationMessageDisplayed();
                }}
            />
        </>
    );
};

export default EightPointChecklistTab;
