import React, { useState, useEffect } from "react";
import PermitDetailsTabContainer from "../PermitDetailsTabContainer";
import PermitBoundariesTabContainer from "../PermitBoundariesTabContainer";
import SignaturesTabContainer from "../SignaturesTabContainer";
import EightPointChecklistTabContainer from "../EightPointChecklistTabContainer";
import PermitDocTabContainer from "../PermitDocTabContainer";
import { Permit } from "../../models/Permit";
import { UserProfile } from "../../models/UserProfile";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { loadPermit, savePermit, updatePermitLastAccess } from "./thunks";
import { User } from "../../models/User";
import database from "../../Database";
import PermitHeader from "../../components/PermitHeader";
import { IonToast } from "@ionic/react";
import { reportProblem, workCompleted } from "./actions";
import {
    EXPIRED,
    IN_PROGRESS,
    ISSUED,
    MARK_AS_WORK_COMPLETED,
    READY_TO_ISSUE,
    REPORT_PROBLEM_ON_READY_TO_ISSUE_PERMITS,
} from "../../constants";
import moment from "moment";
import { ESRI_INTEGRATION_TYPE_MANAGED_BY_VISLOCK_ID } from "../../models/Client";
import PermissionService from "../../PermissionService";
import { Role } from "../../models/Role";

interface PermitContainerProps {
    permit: Permit | null;
    userProfile: UserProfile | null;
    currentUser: User | null;
    loadPermit: typeof loadPermit;
    savePermit: typeof savePermit;
    updatePermitLastAccess: typeof updatePermitLastAccess;
    saving: boolean;
    reportProblem: typeof reportProblem;
    workCompleted: typeof workCompleted;
    clientRoles: Role[];
}

const PermitContainer: React.FC<PermitContainerProps> = ({
    currentUser,
    savePermit,
    loadPermit,
    permit,
    updatePermitLastAccess,
    saving,
    reportProblem,
    workCompleted,
    clientRoles,
}) => {
    let history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [selectedTab, setSelectedTab] = useState<string>("permit-details");
    const displayPermitBoundariesTab =
        currentUser?.client.esriConfig?.integrationType.id === ESRI_INTEGRATION_TYPE_MANAGED_BY_VISLOCK_ID;

    useEffect(() => {
        if (id) {
            loadPermit(id);
            database.setReplicationCompletedEventHandler((docId) => {
                if (docId.endsWith(id)) loadPermit(id);
            });
            return () => {
                database.setReplicationCompletedEventHandler(undefined);
            };
        }
    }, [id, loadPermit]);

    useEffect(() => {
        if (!saving && permit && currentUser) {
            if (permit.lastAccess) {
                if (new Date(permit.lastAccess.date).getTime() + 10 * 60000 < new Date().getTime()) {
                    updatePermitLastAccess(permit.id, currentUser.id);
                }
            } else {
                updatePermitLastAccess(permit.id, currentUser.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permit, currentUser]);

    useEffect(() => {
        if (permit?.pendingSave) {
            savePermit(permit);
        }
    }, [permit, savePermit]);

    useEffect(() => {
        if ((permit?.status === IN_PROGRESS || permit?.status === EXPIRED) && !permit.pendingSave) {
            history.push(`/home`);
        }
    }, [permit]);

    const handleTabChange = (selectedTab: string) => {
        setSelectedTab(selectedTab);
    };

    const getIsReadOnly = () => {
        if (permit?.status.toLowerCase() === "ready to issue") return false;

        return true;
    };

    const handleReportProblem = (comments: string) => {
        currentUser && reportProblem(comments, currentUser.id);
    };

    const handleWorkCompleted = () => {
        currentUser && workCompleted(currentUser.id);
    };

    const isReadOnly = getIsReadOnly();

    const renderActiveTab = (selectedTab: string, permit: Permit) => {
        switch (selectedTab) {
            case "permit-details":
                return <PermitDetailsTabContainer isReadOnly={isReadOnly} />;

            case "permit-boundaries":
                return <PermitBoundariesTabContainer isReadOnly={isReadOnly} />;

            case "gang-members":
            case "eight-point-checklist":
                return <EightPointChecklistTabContainer />;

            case "authorisation":
                return <SignaturesTabContainer isReadOnly={isReadOnly} />;

            default:
                return (
                    <PermitDocTabContainer
                        isReadOnly={isReadOnly}
                        doc={permit.documents.find((doc) => selectedTab === "doc-" + doc.id)}
                    />
                );
        }
    };

    const permitIssuedToast = () => {
        if (!permit) return false;

        if (!permit.pendingStatusSync) return false;

        if (permit.status !== ISSUED) return false;

        return true;
    };

    if (!(permit && currentUser && clientRoles)) return null;
    const ableToMarkAsWorkCompleted =
        permit.status === ISSUED &&
        PermissionService.userHasPermission(currentUser, MARK_AS_WORK_COMPLETED, clientRoles, permit.project.id);
    const ableToReportProblem =
        permit.status === READY_TO_ISSUE &&
        PermissionService.userHasPermission(
            currentUser,
            REPORT_PROBLEM_ON_READY_TO_ISSUE_PERMITS,
            clientRoles,
            permit.project.id
        );

    return (
        <>
            <PermitHeader
                permit={permit}
                selectedTab={selectedTab}
                onTabChange={handleTabChange}
                onReportProblem={ableToReportProblem ? handleReportProblem : undefined}
                onWorkCompleted={ableToMarkAsWorkCompleted ? handleWorkCompleted : undefined}
                displayPermitBoundariesTab={displayPermitBoundariesTab}
            />
            {permit && currentUser && renderActiveTab(selectedTab, permit)}
            <IonToast
                isOpen={permitIssuedToast()}
                message={`Permit is now live until ${permit && moment(permit.validTo).format("L")}`}
                position="top"
                duration={5000}
            />
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        permit: state.permitPage.permit,
        userProfile: state.user.currentProfile,
        currentUser: state.user.currentUser,
        saving: state.permitPage.saving,
        clientRoles: state.myPermits.clientRoles,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadPermit: (id: string) => dispatch(loadPermit(id)),
        savePermit: (permit: Permit) => dispatch(savePermit(permit)),
        updatePermitLastAccess: (permitId: string, userId: string) =>
            dispatch(updatePermitLastAccess(permitId, userId)),
        reportProblem: (comments: string, loggedInUserId: string) => dispatch(reportProblem(comments, loggedInUserId)),
        workCompleted: (loggedInUserId: string) => dispatch(workCompleted(loggedInUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermitContainer);
