import { registerPlugin } from "@capacitor/core";
import { isPlatform } from "@ionic/react";
import {
    ReplicationCompletedEventHandler,
    ReplicationFailedEventHandler,
    ReplicatorStatusChangeEventHandler,
} from "./Database";
import { RawFile } from "./models/RawFile";
import { SyncGatewaySession } from "./models/UserProfile";

let DatabasePlugin = isPlatform("hybrid")
    ? registerPlugin<CouchbaseLitePlugin>("Database")
    : ({} as CouchbaseLitePlugin);

if (isPlatform("electron")) {
    const win: any = window;
    DatabasePlugin = win.databasePlugin;
}

const loadedFiles: Map<string, string> = new Map();

interface CouchbaseLitePlugin {
    replicatorStatusChangeEventHandler?: ReplicatorStatusChangeEventHandler;
    replicationFailedEventHandler?: ReplicationFailedEventHandler;
    replicationCompletedEventHandler?: ReplicationCompletedEventHandler;
    closeDatabase: () => Promise<void>;
    openDatabase: (options: {
        syncGatewayUri: string;
        syncGatewayDatabase: string;
        userId: string;
        session: SyncGatewaySession;
    }) => Promise<void>;
    queryDoc: <T>(options: { docId: string }) => Promise<T>;
    queryDocs: <T>(options: { predicate: any }) => Promise<{ data: T[] }>;
    loadDocFile: (options: { docId: string; fileKey: string }) => Promise<string>;
    saveDoc: <T>(options: { docId: string; doc: any; newFiles: RawFile[]; fileKeysUsed: string[] }) => Promise<T>;
    addListener: (event: string, listener: (info: any) => void) => void;
}

interface CouchbaseDatabase {
    replicatorStatusChangeEventHandler?: ReplicatorStatusChangeEventHandler;
    replicationFailedEventHandler?: ReplicationFailedEventHandler;
    replicationCompletedEventHandler?: ReplicationCompletedEventHandler;
    closeDatabase: () => Promise<void>;
    openDatabase: (
        syncGatewayAddress: string,
        isSsl: boolean,
        syncGatewayDatabase: string,
        userId: string,
        session: SyncGatewaySession
    ) => Promise<void>;
    queryDoc: <T>(docId: string) => Promise<T>;
    queryDocs: <T>(predicate: any) => Promise<T[]>;
    loadDocFile: (docId: string, fileKey: string) => Promise<string>;
    saveDoc: <T>(docId: string, doc: any, newFiles: RawFile[], fileKeysUsed: string[]) => Promise<T>;
}

const couchbaseLitePlugin: CouchbaseDatabase = {
    replicatorStatusChangeEventHandler: undefined,
    replicationFailedEventHandler: undefined,
    replicationCompletedEventHandler: undefined,

    closeDatabase(): Promise<void> {
        return DatabasePlugin.closeDatabase();
    },

    openDatabase(
        syncGatewayAddress: string,
        isSsl: boolean,
        syncGatewayDatabase: string,
        userId: string,
        session: SyncGatewaySession
    ): Promise<void> {
        const syncGatewayUri = `ws${isSsl ? "s" : ""}://${syncGatewayAddress}`;
        return DatabasePlugin.openDatabase({
            syncGatewayUri,
            syncGatewayDatabase,
            userId,
            session,
        }).catch((error: any) => {
            return Promise.reject(error);
        });
    },

    queryDoc<T>(docId: string): Promise<T> {
        return DatabasePlugin.queryDoc({ docId }).then(
            (result: any) => {
                return result.data;
            },
            (err: any) => {
                console.log(err);
            }
        );
    },

    queryDocs<T>(predicate: any): Promise<T[]> {
        return DatabasePlugin.queryDocs<T>({ predicate }).then(
            (result) => {
                return result.data as T[];
            },
            (err: any) => {
                console.log(err);
                return [] as T[];
            }
        );
    },

    loadDocFile(docId: string, fileKey: string): Promise<string> {
        return new Promise((resolve, reject) => {
            if (loadedFiles.has(fileKey)) {
                resolve(loadedFiles.get(fileKey) || "");
            } else {
                DatabasePlugin.loadDocFile({ docId, fileKey }).then(
                    (result: any) => {
                        loadedFiles.set(fileKey, result.data);
                        resolve(result.data);
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
            }
        });
    },

    saveDoc<T>(docId: string, doc: T, newFiles: RawFile[], fileKeysUsed: string[]): Promise<T> {
        return DatabasePlugin.saveDoc({ docId, doc, newFiles, fileKeysUsed }).then(
            (result: any) => {
                console.log(result);
                return result.data;
            },
            (err: any) => {
                console.log(err);
            }
        );
    },
};

if (DatabasePlugin && DatabasePlugin.addListener) {
    DatabasePlugin.addListener("replicatorStatusChangeEvent", (info: any) => {
        console.log("replicatorStatusChangeEvent", info);
        if (couchbaseLitePlugin.replicatorStatusChangeEventHandler) {
            couchbaseLitePlugin.replicatorStatusChangeEventHandler(info.status);
        }
    });

    DatabasePlugin.addListener("replicationFailedEvent", (info: any) => {
        console.log("replicationFailedEvent", info);
        if (couchbaseLitePlugin.replicationFailedEventHandler) {
            couchbaseLitePlugin.replicationFailedEventHandler(info.error);
        }
    });

    const replicationCompletedEvent = (info: any) => {
        console.log("replicationCompletedEvent", info);
        if (couchbaseLitePlugin.replicationCompletedEventHandler) {
            couchbaseLitePlugin.replicationCompletedEventHandler(info.docId);
        }
    };

    DatabasePlugin.addListener("replicationCompletedEvent", replicationCompletedEvent);
}

export default couchbaseLitePlugin;
