import React from "react";
import { IonPage } from "@ionic/react";
import PermitContainer from "../containers/PermitContainer";

const PermitPage: React.FC = () => {
    return (
        <IonPage>
            <PermitContainer />
        </IonPage>
    );
};

export default PermitPage;
