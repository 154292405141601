import React from "react";
import { call } from "ionicons/icons";
import CustomItem from "./CustomItem";
import CustomBox from "./CustomBox";
import CustomMultiColView from "./CustomMultiColView";
import { Project } from "../models/Project";
import moment from "moment";
import { EmergencyContact } from "../models/EmergencyContact";

interface Props {
    locationOfWork: string;
    validFrom: Date;
    validTo: Date;
    comments: string;
    emergencyContact?: EmergencyContact;
    submittedBy: string;
    dateSubmitted?: Date;
    workDescription: string;
    maxPenetrationDepth: string;
    permittedEquipment: string;
    subContractor: string;
    project: Project;
    catSerialNumber: string;
    gennySerialNumber: string;
    catCalibrationDate: Date;
    gennyCalibrationDate: Date;
}

const PermitDetails: React.FC<Props> = ({
    locationOfWork,
    validFrom,
    validTo,
    comments,
    emergencyContact,
    submittedBy,
    dateSubmitted,
    workDescription,
    maxPenetrationDepth,
    permittedEquipment,
    subContractor,
    project,
    catSerialNumber,
    catCalibrationDate,
    gennySerialNumber,
    gennyCalibrationDate,
}) => {
    const permitValid = `${moment(validFrom).format("L")} - ${moment(validTo).format("L")}`;
    const submission = `${submittedBy} at ${moment(dateSubmitted).format("lll")}`;

    return (
        <>
            <CustomBox title="Basic details">
                <CustomMultiColView>
                    <CustomItem title="Location of work" text={locationOfWork}></CustomItem>
                    <CustomItem title="Permit valid" text={permitValid}></CustomItem>
                    <CustomItem title="Authorised by" text={submission}></CustomItem>
                    <CustomItem title="Sub-contractor" text={subContractor}></CustomItem>
                    <CustomItem title="Works description" text={workDescription}></CustomItem>
                    <CustomItem title="Max penetration depth" text={maxPenetrationDepth}></CustomItem>
                    <CustomItem title="Permitted equipment" text={permittedEquipment}></CustomItem>
                    <CustomItem title="CAT serial number" text={catSerialNumber}></CustomItem>
                    <CustomItem title="CAT calibration date" text={moment(catCalibrationDate).format("L")}></CustomItem>
                    <CustomItem title="Genny serial number" text={gennySerialNumber}></CustomItem>
                    <CustomItem
                        title="Genny calibration date"
                        lines="none"
                        text={moment(gennyCalibrationDate).format("L")}
                    ></CustomItem>
                </CustomMultiColView>
            </CustomBox>
            <CustomBox title="Comments">
                <div style={{ padding: 16 }}>{comments}</div>
            </CustomBox>
            <CustomBox title="Project">
                <CustomMultiColView>
                    <CustomItem title="Project name" text={project.name}></CustomItem>
                    <CustomItem title="Project number" text={project.number?.toString()}></CustomItem>
                    {emergencyContact && emergencyContact.name !== "" ? (
                        <CustomItem
                            title="Emergency contact"
                            text={emergencyContact.name}
                            tagIcon={call}
                            tag={emergencyContact.phone}
                            lines="none"
                        ></CustomItem>
                    ) : (
                        <CustomItem title="Emergency contact" text="-" lines="none"></CustomItem>
                    )}
                </CustomMultiColView>
            </CustomBox>
        </>
    );
};

export default PermitDetails;
