import { Project } from "../../models/Project";
import { ReferenceProcedure } from "../../models/ReferenceProcedure";
import { Permit } from "../../models/Permit";
import { Role } from "../../models/Role";
import { User } from "../../models/User";

// Constants
export const SET_SYNC_STATUS = "SET_SYNC_STATUS";
export const LOAD_MY_PERMITS_STARTED = "LOAD_MY_PERMITS_STARTED";
export const LOAD_MY_PERMITS_SUCCESSFUL = "LOAD_MY_PERMITS_SUCCESSFUL";
export const LOAD_MY_PERMITS_FAILED = "LOAD_MY_PERMITS_FAILED";
export const LOAD_CLIENT_ROLES_STARTED = "LOAD_CLIENT_ROLES_STARTED";
export const LOAD_CLIENT_ROLES_SUCCESSFUL = "LOAD_CLIENT_ROLES_SUCCESSFUL";
export const LOAD_CLIENT_ROLES_FAILED = "LOAD_CLIENT_ROLES_FAILED";
export const LOAD_REFERENCE_PROCEDURES_SUCCESSFUL = "LOAD_REFERENCE_PROCEDURES_SUCCESSFUL";
export const SELECT_PERMIT = "SELECT_PERMIT";
export const SET_SHOW_EXPIRED_PERMITS_FLAG = "SET_SHOW_EXPIRED_PERMITS_FLAG";
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const LOGOUT = "LOGOUT";
export const LOAD_SYNCED_USER_SUCCESSFUL = "LOAD_SYNCED_USER_SUCCESSFUL";

// State Type
export interface MyPermitsState {
    loading: boolean;
    showExpired: boolean;
    selectedProject: Project | null;
    permits: Permit[];
    referenceProcedures: ReferenceProcedure[];
    currentSyncStatus: string;
    clientRoles: Role[];
    rolesLoading: boolean;
}

// Action Types
interface SetSyncStatus {
    type: typeof SET_SYNC_STATUS;
    newStatus: string;
}

interface LoadMyPermitsStartedAction {
    type: typeof LOAD_MY_PERMITS_STARTED;
}

interface LoadMyPermitsSuccessfulAction {
    type: typeof LOAD_MY_PERMITS_SUCCESSFUL;
    permits: Permit[];
}

interface LoadMyPermitsFailedAction {
    type: typeof LOAD_MY_PERMITS_FAILED;
    error: Error;
}

interface LoadClientRolesStartedAction {
    type: typeof LOAD_CLIENT_ROLES_STARTED;
}

interface LoadClientRolesSuccessfulAction {
    type: typeof LOAD_CLIENT_ROLES_SUCCESSFUL;
    clientRoles: Role[];
}

interface LoadClientRolesFailedAction {
    type: typeof LOAD_CLIENT_ROLES_FAILED;
    error: Error;
}

interface LoadReferenceProceduresSuccessfulAction {
    type: typeof LOAD_REFERENCE_PROCEDURES_SUCCESSFUL;
    referenceProcedures: ReferenceProcedure[];
}

interface SelectPermitAction {
    type: typeof SELECT_PERMIT;
    permitId: string;
}

interface SetShowExpiredFlagAction {
    type: typeof SET_SHOW_EXPIRED_PERMITS_FLAG;
    value: boolean;
}

interface SetSelectedProject {
    type: typeof SET_SELECTED_PROJECT;
    value: Project | null;
}

interface LogOutAction {
    type: typeof LOGOUT;
}

interface LoadSyncedUserSuccessfulAction {
    type: typeof LOAD_SYNCED_USER_SUCCESSFUL;
    user: User;
}

export type MyPermitsActionTypes =
    | LoadMyPermitsStartedAction
    | SelectPermitAction
    | LoadMyPermitsSuccessfulAction
    | LoadMyPermitsFailedAction
    | LoadClientRolesStartedAction
    | LoadClientRolesSuccessfulAction
    | LoadClientRolesFailedAction
    | LoadReferenceProceduresSuccessfulAction
    | SetShowExpiredFlagAction
    | SetSelectedProject
    | LogOutAction
    | LoadSyncedUserSuccessfulAction
    | SetSyncStatus;
