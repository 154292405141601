import React, { useEffect } from "react";
import PermitDetailsTab from "../../components/PermitDetailsTab";
import { Permit } from "../../models/Permit";
import { UserProfile } from "../../models/UserProfile";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { loadPermitSubmitter } from "./thunks";
import { updatePermitAssignee, addSitePhoto, deleteSitePhoto } from "./actions";
import { submitPermitAuthorisation } from "../SignaturesTabContainer/actions";
import { User } from "../../models/User";
import { Guid } from "guid-typescript";
import { RawFile } from "../../models/RawFile";
import { PermitAssignment } from "../../models/PermitAssignment";
import { PermitAuthorisationSignature } from "../../models/PermitAuthorisationSignature";
import PermissionService from "../../PermissionService";
import { ISSUE_PERMIT } from "../../constants";
import { Role } from "../../models/Role";

interface PermitDetailsTabContainerProps {
    submitPermitAuthorisation: typeof submitPermitAuthorisation;
    permit: Permit | null;
    userProfile: UserProfile | null;
    currentUser: User | null;
    permitSubmitter: User | null;
    loadPermitSubmitter: typeof loadPermitSubmitter;
    updatePermitAssignee: typeof updatePermitAssignee;
    addSitePhoto: typeof addSitePhoto;
    deleteSitePhoto: typeof deleteSitePhoto;
    isReadOnly: boolean;
    clientRoles: Role[];
}

const PermitDetailsTabContainer: React.FC<PermitDetailsTabContainerProps> = ({
    submitPermitAuthorisation,
    currentUser,
    permitSubmitter,
    loadPermitSubmitter,
    permit,
    updatePermitAssignee,
    addSitePhoto,
    deleteSitePhoto,
    isReadOnly,
    clientRoles,
}) => {
    const submittedById = permit?.submittedById;
    useEffect(() => {
        if (submittedById) {
            loadPermitSubmitter(submittedById);
        }
    }, [submittedById, loadPermitSubmitter]);

    const generateFileKey = (): string => {
        return "file-" + Guid.create().toString();
    };

    const handlePermitAssignmentSignatureSubmitted = async (
        signature: PermitAuthorisationSignature,
        signatureDataUrl: string
    ) => {
        const fileKey = generateFileKey();

        currentUser &&
            submitPermitAuthorisation(
                { ...signature, dateSigned: new Date(), signatureFileKey: fileKey } as PermitAuthorisationSignature,
                { fileName: fileKey, contentType: "image/png", dataUrl: signatureDataUrl } as RawFile,
                clientRoles,
                currentUser.id
            );
    };

    const handleSitePhotoCaptured = async (dataUrl: string) => {
        const fileKey = generateFileKey();

        addSitePhoto(fileKey, { fileName: fileKey, contentType: "image/png", dataUrl } as RawFile);
    };

    const handeleSitePhotoDeleted = async (filekey: string) => {
        const newSitePhotos = [...(permit?.sitePhotoFileKeys ?? [])];
        for (var i = 0; i < newSitePhotos.length; i++) {
            if (newSitePhotos[i] === filekey) {
                newSitePhotos.splice(i, 1);
            }
        }
        deleteSitePhoto(newSitePhotos);
    };

    if (!permit || !currentUser || !clientRoles) return null;
    const ableToIssuePermit = PermissionService.userHasPermission(
        currentUser,
        ISSUE_PERMIT,
        clientRoles,
        permit.project.id
    );

    return (
        <PermitDetailsTab
            permit={permit}
            currentUser={currentUser}
            permitSubmitterName={permitSubmitter ? permitSubmitter.firstName + " " + permitSubmitter.lastName : ""}
            onPermitAssignmentSignatureSubmitted={handlePermitAssignmentSignatureSubmitted}
            onPermitAssigneeUpdated={updatePermitAssignee}
            onSitePhotoCaptured={handleSitePhotoCaptured}
            onSitePhotoDeleted={handeleSitePhotoDeleted}
            isReadOnly={isReadOnly || !ableToIssuePermit}
            ableToAddPhotos={ableToIssuePermit}
        />
    );
};

function mapStateToProps(state: AppState) {
    return {
        permit: state.permitPage.permit,
        currentUser: state.user.currentUser,
        permitSubmitter: state.permitPage.permitSubmitter,
        clientRoles: state.myPermits.clientRoles,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadPermitSubmitter: (id: string) => dispatch(loadPermitSubmitter(id)),
        updatePermitAssignee: (assignment: PermitAssignment) => dispatch(updatePermitAssignee(assignment)),
        addSitePhoto: (sitePhotoFileKey: string, photoFile: RawFile) =>
            dispatch(addSitePhoto(sitePhotoFileKey, photoFile)),
        deleteSitePhoto: (sitePhotoFileKeys: string[]) => dispatch(deleteSitePhoto(sitePhotoFileKeys)),
        submitPermitAuthorisation: (
            authorisation: PermitAuthorisationSignature,
            signatureFile: RawFile,
            clientRoles: Role[],
            loggedInUserId: string
        ) => dispatch(submitPermitAuthorisation(authorisation, signatureFile, clientRoles, loggedInUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermitDetailsTabContainer);
