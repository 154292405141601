import { AppThunk } from "../../store/AppThunkType";
import { loadGangMembersSuccessful, loadGangMembersStarted } from "./actions";
import database from "../../Database";

export function loadGangMembers(clientId: string): AppThunk {
    return function (dispatch) {
        dispatch(loadGangMembersStarted());

        database.queryGangMembers(clientId).then((result) => {
            dispatch(loadGangMembersSuccessful(result));
        });
    };
}
