import React from "react";
import PermitDocTab from "../../components/PermitDocTab";
import { Permit } from "../../models/Permit";
import { UserProfile } from "../../models/UserProfile";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { User } from "../../models/User";
import { Document } from "../../models/PermitDocument";

interface PermitContainerProps {
    doc: Document | undefined;
    permit: Permit | null;
    userProfile: UserProfile | null;
    currentUser: User | null;
    isReadOnly: boolean;
}

const PermitDocTabContainer: React.FC<PermitContainerProps> = ({ doc, currentUser, permit, isReadOnly }) => {
    if (!permit || !currentUser) return null;

    return <PermitDocTab permitId={permit.id} doc={doc} isReadOnly={isReadOnly} />;
};

function mapStateToProps(state: AppState) {
    return {
        permit: state.permitPage.permit,
        userProfile: state.user.currentProfile,
        currentUser: state.user.currentUser,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermitDocTabContainer);
