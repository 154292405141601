import {
    PermitDetailsTabActionTypes,
    UPDATE_PERMIT_ASSIGNEE,
    ADD_SITE_PHOTO,
    DELETE_SITE_PHOTO,
    LOAD_PERMIT_SUBMITTER_SUCCESSFUL,
    UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL,
} from "./types";
import { Permit } from "../../models/Permit";
import { PermitState } from "../PermitContainer/types";

const reducer = (state: PermitState, action: PermitDetailsTabActionTypes) => {
    switch (action.type) {
        case UPDATE_PERMIT_ASSIGNEE:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    permitAssignments: state.permit?.permitAssignments?.map((a) =>
                        a.id === action.assignment.id ? { ...action.assignment, pendingSync: true } : a
                    ),
                    pendingSave: true,
                } as Permit,
            };
        case ADD_SITE_PHOTO:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    sitePhotoFileKeys: state.permit?.sitePhotoFileKeys
                        ? [...state.permit?.sitePhotoFileKeys, action.sitePhotoFileKey]
                        : [action.sitePhotoFileKey],
                    pendingSave: true,
                    pendingSync: true,
                } as Permit,
                newFiles: [...state.newFiles, action.photoFile],
            };
        case DELETE_SITE_PHOTO:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    sitePhotoFileKeys: action.sitePhotoFileKeys,
                    pendingSave: true,
                    pendingSync: true,
                } as Permit,
            };
        case LOAD_PERMIT_SUBMITTER_SUCCESSFUL:
            return {
                ...state,
                permitSubmitter: action.user,
            };
        case UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL:
            if (!state.permit || state.permit.id !== action.permitId) {
                return state;
            }
            return {
                ...state,
                permit: {
                    ...state.permit,
                    lastAccess: action.locationLog,
                } as Permit,
            };
        default:
            return state;
    }
};

export default reducer;
