export interface Client {
    id: string;
    name: string;
    esriConfig?: EsriConfig;
    isArchived: boolean;
}

export interface EsriConfig {
    webMapItemId: string;
    integrationType: EsriIntegrationType;
}

export interface EsriIntegrationType {
    id: string;
    name: string;
}

// Constants
export const ESRI_INTEGRATION_TYPE_NONE_ID = "1";
export const ESRI_INTEGRATION_TYPE_MANAGED_BY_VISLOCK_ID = "2";
