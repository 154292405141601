import React from "react";
import PermitBoundariesTab from "../../components/PermitBoundariesTab";
import { Permit } from "../../models/Permit";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { User } from "../../models/User";

interface PermitContainerProps {
    permit: Permit | null;
    isReadOnly: boolean;
    currentUser: User | null;
}

const PermitBoundariesTabContainer: React.FC<PermitContainerProps> = ({ permit, isReadOnly, currentUser }) => {
    if (!permit || !currentUser || !currentUser.client.esriConfig) return null;

    return (
        <PermitBoundariesTab
            esriConfig={currentUser.client.esriConfig}
            boundaries={permit.boundaries}
            isReadOnly={isReadOnly}
            project={permit.project}
        />
    );
};

function mapStateToProps(state: AppState) {
    return {
        permit: state.permitPage.permit,
        currentUser: state.user.currentUser,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PermitBoundariesTabContainer);
