import React, { useRef, RefObject } from "react";
import {
    IonButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent,
    IonLabel,
    useIonModal,
} from "@ionic/react";
import CustomBox from "./CustomBox";
import CustomMultiColView from "./CustomMultiColView";
import CustomItem from "./CustomItem";
import SignatureCanvas from "react-signature-canvas";
import "./SignatureModal.css";

interface Props {
    buttonLabel: string;
    signatoryName: string;
    onSubmit: (dataUrl: string) => void;
    onModalOpening?: () => boolean;
    isReadOnly?: boolean;
}

const ModalBody: React.FC<{
    signatoryName: string;
    signPadRef: RefObject<SignatureCanvas>;
    onCancelClick: () => void;
    onDoneClick: () => void;
}> = ({ signatoryName, signPadRef, onCancelClick, onDoneClick }) => (
    <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>Please sign:</IonTitle>
                <IonButtons slot="end">
                    <IonButton onClick={onCancelClick} strong>
                        Cancel
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent class="outer-content">
            <CustomBox>
                <div className="sign-pad-wrapper">
                    <SignatureCanvas
                        ref={signPadRef}
                        canvasProps={{
                            width: 520,
                            height: 200,
                            className: "sign-pad",
                        }}
                    />
                </div>
            </CustomBox>
            <CustomBox>
                <CustomMultiColView>
                    <CustomItem title="Name" text={signatoryName}></CustomItem>
                </CustomMultiColView>
            </CustomBox>
        </IonContent>
        <IonButton onClick={onDoneClick}>Done</IonButton>
    </>
);

const SignatureModal: React.FC<Props> = ({ buttonLabel, signatoryName, onSubmit, onModalOpening, isReadOnly }) => {
    let signPadRef = useRef<SignatureCanvas>(null);
    const [present, dismiss] = useIonModal(ModalBody, {
        signatoryName,
        signPadRef,
        onCancelClick: () => {
            dismiss();
        },
        onDoneClick: () => {
            if (signPadRef.current) {
                dismiss();
                onSubmit(signPadRef.current.toDataURL("image/png"));
            }
        },
    });

    const handleButtonClicked = () => {
        let shouldOpen = true;

        if (onModalOpening) {
            shouldOpen = onModalOpening();
        }

        if (shouldOpen) {
            present({
                cssClass: "signature-modal",
            });
        }
    };

    return (
        <div>
            {isReadOnly ? (
                <p>
                    <IonLabel class="ion-text-wrap">Not Signed</IonLabel>
                </p>
            ) : (
                <IonButton shape="round" color="primary" size="small" onClick={handleButtonClicked}>
                    {buttonLabel}
                </IonButton>
            )}
        </div>
    );
};

export default SignatureModal;
