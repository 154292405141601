import { AuthorisationTabActionTypes, SUBMIT_PERMIT_AUTHORISATION } from "./types";
import { Permit } from "../../models/Permit";
import { PermitState } from "../PermitContainer/types";
import { ISSUED } from "../../constants";

const reducer = (state: PermitState, action: AuthorisationTabActionTypes) => {
    switch (action.type) {
        case SUBMIT_PERMIT_AUTHORISATION:
            const signatures = [
                ...(state.permit ? state.permit.signatures : []),
                { ...action.signature, pendingSync: true },
            ];

            const requiredSignatureRoles = action.clientRoles.filter((r) => r.permitRoleSettings.shouldAuthorizePermit);

            // If permit has all the authorizations and can be issued
            const hasRemainingSignature =
                state.permit &&
                state.permit.permitAssignments &&
                state.permit.permitAssignments
                    .filter((a) => requiredSignatureRoles.some((r) => r.id === a.roleId))
                    .filter((a) => !(signatures.filter((s) => s.roleId === a.roleId).length > 0)).length > 0;
            const newStatus = !hasRemainingSignature ? ISSUED : state.permit?.status;

            return {
                ...state,
                permit: {
                    ...state.permit,
                    signatures: signatures,
                    status: newStatus,
                    pendingStatusSync: newStatus === ISSUED,
                    pendingStatusLoggedInUserId: action.loggedInUserId,
                    pendingSave: true,
                } as Permit,
                newFiles: [...state.newFiles, action.signatureFile],
            };
        default:
            return state;
    }
};

export default reducer;
