import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import GlobalErrorHandler from "./components/GlobalErrorHandler";
import configureStore from "./store";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import moment from "moment";
import "moment/locale/en-gb";

const store = configureStore();
export type AppDispatch = typeof store.dispatch;

moment.locale("en-gb");

const Root = () => (
    <Provider store={store}>
        <GlobalErrorHandler>
            <App />
        </GlobalErrorHandler>
    </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));
defineCustomElements(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
