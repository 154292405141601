import {
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonLabel,
    IonTitle,
    IonToolbar,
    IonImg,
    useIonPopover,
} from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import HomeMenu from "../components/HomeMenu";
import SelectClient from "../components/SelectClient";
import { Client } from "../models/Client";
import { User } from "../models/User";

interface Props {
    userName: string;
    currentUser: User | null;
    clientUsers: User[] | undefined;
    onLogOut: () => void;
    onSelectClientUser: (user: User | undefined) => void;
}

const HomeHeader: React.FC<Props> = ({ userName, currentUser, clientUsers, onLogOut, onSelectClientUser }) => {
    const [showSelectClient, setShowSelectClient] = useState(false);

    useEffect(() => {
        if (!currentUser && clientUsers) {
            setShowSelectClient(true);
        }
    }, [currentUser, clientUsers]);

    const handleLogoutClick = () => {
        onLogOut();
    };

    const [present, dismiss] = useIonPopover(HomeMenu, {
        userName: userName,
        onLogOut: () => {
            dismiss();
            handleLogoutClick();
        },
        onSwitchAccount: () => {
            dismiss();
            setShowSelectClient(true);
        },
    });

    const handleSelectClient = (client: Client) => {
        let user = clientUsers?.find((u) => u.client.id === client.id);
        onSelectClientUser(user);
        setShowSelectClient(false);
    };

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        <IonImg src="./assets/Logo.png" style={{ width: "120pt" }} />
                    </IonTitle>
                    <IonButtons slot="primary">
                        <IonLabel className="ion-hide-md-down">{userName}</IonLabel>
                        <IonButton
                            onClick={(e) =>
                                present({
                                    event: e.nativeEvent,
                                })
                            }
                        >
                            <IonIcon icon={chevronDownOutline} slot="icon-only" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <SelectClient
                isOpen={showSelectClient}
                clients={clientUsers?.map((u) => u.client)}
                onClose={() => {
                    setShowSelectClient(false);
                }}
                onSelectClient={handleSelectClient}
            />
        </>
    );
};

export default HomeHeader;
