import {
    LOGIN_STARTED,
    LOGIN_SUCCESSFUL,
    LOGIN_FAILED,
    LoginActionTypes,
    SET_CURRENT_USER,
    SET_CURRENT_PROFILE,
} from "./types";
import { UserProfile } from "../../models/UserProfile";
import { User } from "../../models/User";

export function loginStarted(): LoginActionTypes {
    return {
        type: LOGIN_STARTED,
    };
}

export function loginSuccessful(userProfile: UserProfile, currentUser?: User): LoginActionTypes {
    return {
        type: LOGIN_SUCCESSFUL,
        userProfile,
        currentUser,
    };
}

export function loginFailed(error: Error): LoginActionTypes {
    return {
        type: LOGIN_FAILED,
        error,
    };
}

export function setCurrentUser(user: User): LoginActionTypes {
    return {
        type: SET_CURRENT_USER,
        user,
    };
}

export function setCurrentProfile(profile: UserProfile): LoginActionTypes {
    return {
        type: SET_CURRENT_PROFILE,
        profile,
    };
}
