import { GangMember } from "../../models/GangMember";
import { Permit } from "../../models/Permit";

export const service = {
    getAvailableGangMembers,
};

function getAvailableGangMembers(permit: Permit, gangMembers: GangMember[]) {
    const existingPermitGangMemberIds = permit.permitGangMembers.map(({ gangMember }) => gangMember.id);
    return gangMembers.filter((g) => !existingPermitGangMemberIds.includes(g.id) && !g.isArchived);
}
