import React, { useState } from "react";
import {
    IonCard,
    IonList,
    IonCardContent,
    IonText,
    IonIcon,
    IonButton,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonAlert,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";

interface Props {
    title?: string;
    text?: string;
    contentText?: JSX.Element;
    withClose?: boolean;
    onCloseBox?: () => void;
    closeButtonText?: string;
    children?: React.ReactNode;
}

const CustomBox: React.FC<Props> = ({ title, text, contentText, withClose, onCloseBox, closeButtonText, children }) => {
    const [showAlert, setShowAlert] = useState(false);

    return (
        <>
            <IonCard>
                <IonList lines="full">
                    {title && (
                        <IonToolbar mode="md">
                            <IonTitle
                                slot={withClose ? "start" : ""}
                                style={{ padding: "16px" }}
                                class="ion-text-uppercase"
                                size="small"
                            >
                                {title}
                            </IonTitle>
                            {withClose && (
                                <IonButtons slot="end">
                                    <IonButton
                                        onClick={() => setShowAlert(true)}
                                        fill="solid"
                                        shape="round"
                                        color="primary"
                                        size="small"
                                    >
                                        <IonIcon icon={closeOutline} style={{ color: "white" }}></IonIcon>
                                        {closeButtonText}
                                    </IonButton>
                                </IonButtons>
                            )}
                        </IonToolbar>
                    )}
                    {text && (
                        <IonText color="medium" style={{ padding: "16px" }}>
                            {text}
                        </IonText>
                    )}
                    {contentText && <IonCardContent>{contentText}</IonCardContent>}
                    {children}
                </IonList>
            </IonCard>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                message={"Are you sure?"}
                buttons={[
                    {
                        text: "Cancel",
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: "Okay",
                        handler: () => {
                            if (onCloseBox) onCloseBox();
                        },
                    },
                ]}
            />
        </>
    );
};

export default CustomBox;
