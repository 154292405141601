import React from "react";
import { connect } from "react-redux";
import { IonToast } from "@ionic/react";
import Login from "../../components/Login";
import { login, loginWithLastSession } from "./thunks";
import { AppState } from "../../store";
import { UserProfile } from "../../models/UserProfile";
import { User } from "../../models/User";
import Database_Local from "../../Database_Local";

interface Props {
    login: typeof login;
    loginWithLastSession: typeof loginWithLastSession;
    loginError: string;
}

const LoginContainer: React.FC<Props> = ({ login, loginWithLastSession, loginError }) => {
    const storedUserProfile = Database_Local.load<UserProfile>("userProfile");
    const storedUser = Database_Local.load<User>("currentUser");

    if (storedUserProfile !== null && storedUser !== null && loginError === "") {
        loginWithLastSession(storedUserProfile, storedUser);
        return <div></div>;
    }

    return (
        <>
            <Login onSubmit={login} />
            <IonToast isOpen={loginError !== ""} message={loginError} duration={5000} position="top" />
        </>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        loginError: state.user.loginError,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        login: (username: string, password: string) => dispatch(login(username, password)),
        loginWithLastSession: (userProfile: UserProfile, currentUser: User) =>
            dispatch(loginWithLastSession(userProfile, currentUser)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
