import { User } from "../../models/User";

// Constants
export const LOAD_PROJECT_USERS_STARTED = "LOAD_PROJECT_USERS_STARTED";
export const LOAD_PROJECT_USERS_SUCCESSFUL = "LOAD_PROJECT_USERS_SUCCESSFUL";
export const LOAD_PROJECT_USERS_FAILED = "LOAD_PROJECT_USERS_FAILED";

// State Type
export interface PermitAssignmentsState {
    loading: boolean;
    projectUsers: User[];
}

// Action Types
interface LoadProjectUsersStartedAction {
    type: typeof LOAD_PROJECT_USERS_STARTED;
}

interface LoadProjectUsersSuccessfulAction {
    type: typeof LOAD_PROJECT_USERS_SUCCESSFUL;
    projectUsers: User[];
}

interface LoadProjectUsersFailedAction {
    type: typeof LOAD_PROJECT_USERS_FAILED;
    error: Error;
}

export type PermitAssignmentsActionTypes =
    | LoadProjectUsersStartedAction
    | LoadProjectUsersSuccessfulAction
    | LoadProjectUsersFailedAction;
