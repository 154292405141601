import React, { useEffect, useState } from "react";
import { IonContent } from "@ionic/react";
import PdfViewer from "./PdfViewer";
import database from "../Database";

interface Props {
    fileKey: string;
    permitId: string;
}

const PermitDocFileTab: React.FC<Props> = ({ fileKey, permitId }) => {
    const [filePath, setFilePath] = useState("");

    useEffect(() => {
        database.loadPermitFile(permitId, fileKey).then(
            (result: any) => {
                setFilePath(result);
            },
            (err) => {
                setFilePath("");
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileKey]);

    return <IonContent>{filePath !== "" && <PdfViewer path={filePath} />}</IonContent>;
};

export default PermitDocFileTab;
