import { Permit } from "./models/Permit";
import { SyncGatewaySession } from "./models/UserProfile";
import { GangMember } from "./models/GangMember";
import { ReferenceProcedure } from "./models/ReferenceProcedure";
import { User } from "./models/User";
import sgwClient from "./SyncGatewayApiClient";
import {
    ReplicationCompletedEventHandler,
    ReplicationFailedEventHandler,
    ReplicatorStatusChangeEventHandler,
} from "./Database";
import { Role } from "./models/Role";

class WebDatabase {
    closeDatabase(): Promise<void> {
        return sgwClient.closeDatabase();
    }

    openDatabase(
        syncGatewayAddress: string,
        isSsl: boolean,
        syncGatewayDatabase: string,
        userId: string,
        session: SyncGatewaySession
    ): Promise<void> {
        return sgwClient.openDatabase(syncGatewayAddress, isSsl, syncGatewayDatabase, session);
    }

    queryPermits(): Promise<Permit[]> {
        return sgwClient.queryDocs<Permit>("permit:", "pf");
    }

    loadPermitFile(permitId: string, fileKey: string): Promise<string> {
        const docId = `permit::${permitId}`;
        return sgwClient.loadDocFile(docId, fileKey);
    }

    loadReferenceProcedureFile(referenceProcedureId: string, fileKey: string): Promise<string> {
        const docId = `referenceProcedure::${referenceProcedureId}`;
        return sgwClient.loadDocFile(docId, fileKey);
    }

    queryPermit(permitId: string): Promise<Permit> {
        const docId = `permit::${permitId}`;
        return sgwClient.queryDoc<Permit>(docId);
    }

    queryGangMembers(clientId: string): Promise<GangMember[]> {
        return sgwClient.queryDocs<GangMember>("gangMember", "gangMembes").then((list) => {
            return list.filter((b) => b.clientId === clientId);
        });
    }

    queryReferenceProcedures(clientId: string): Promise<ReferenceProcedure[]> {
        return sgwClient.queryDocs<ReferenceProcedure>("referenceProcedure", "referenceProcedurf").then((list) => {
            return list.filter((b) => b.clientId === clientId);
        });
    }

    queryReferenceProcedure(referenceProcedureId: string): Promise<ReferenceProcedure> {
        const docId = `referenceProcedure::${referenceProcedureId}`;
        return sgwClient.queryDoc<ReferenceProcedure>(docId);
    }

    queryProjectUsers(projectId: string): Promise<User[]> {
        return sgwClient.queryDocs<User>("user", "uses").then((list) => {
            return list.filter((u) => u.assignments.find((a) => a.project.id === projectId));
        });
    }

    queryClientRoles(clientId: string): Promise<Role[]> {
        return sgwClient.queryDocs<Role>("role", "rolf").then((list) => {
            return list.filter((r) => r.clientId === clientId);
        });
    }

    queryUser(userId: string): Promise<User> {
        const docId = `user::${userId}`;
        return sgwClient.queryDoc<User>(docId);
    }

    savePermit(permit: Permit): Promise<Permit> {
        return new Promise((resolve) => {
            setTimeout(() => {
                console.log("savePermit is not available");
                resolve(permit);
            });
        });
    }

    queryUserFromWeb(
        userId: string,
        syncGatewayAddress: string,
        syncGatewayDatabase: string,
        isSsl: boolean,
        syncGatewaySession: SyncGatewaySession
    ): Promise<User> {
        const docId = `user::${userId}`;

        return sgwClient
            .queryDocDirectly<User>(docId, syncGatewayAddress, syncGatewayDatabase, isSsl, syncGatewaySession)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
    setReplicatorStatusChangeEventHandler = function (handler?: ReplicatorStatusChangeEventHandler) {};
    setReplicationFailedEventHandler = function (handler?: ReplicationFailedEventHandler) {};
    setReplicationCompletedEventHandler = function (handler?: ReplicationCompletedEventHandler) {};
}

const database = new WebDatabase();
export default database;
