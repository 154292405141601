import { AuthorisationTabActionTypes, SUBMIT_PERMIT_AUTHORISATION } from "./types";
import { RawFile } from "../../models/RawFile";
import { PermitAuthorisationSignature } from "../../models/PermitAuthorisationSignature";
import { Role } from "../../models/Role";

export function submitPermitAuthorisation(
    signature: PermitAuthorisationSignature,
    signatureFile: RawFile,
    clientRoles: Role[],
    loggedInUserId: string
): AuthorisationTabActionTypes {
    return {
        type: SUBMIT_PERMIT_AUTHORISATION,
        signature,
        signatureFile,
        clientRoles,
        loggedInUserId,
    };
}
