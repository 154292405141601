import { AppThunk } from "../../store/AppThunkType";
import {
    loadPermitSuccessful,
    loadPermitStarted,
    savePermitStarted,
    savePermitSuccessful,
    loadPermitSubmitterSuccessful,
    updatePermitLastAccessSuccessful,
    loadPermitFailed,
} from "./actions";
import database from "../../Database";
import { Permit } from "../../models/Permit";
import LocationService from "../../LocationService";

export function loadPermit(id: string): AppThunk {
    return function (dispatch) {
        dispatch(loadPermitStarted());

        database
            .queryPermit(id)
            .then((result) => {
                dispatch(loadPermitSuccessful(result));
            })
            .catch((error) => {
                dispatch(loadPermitFailed(error));
            });
    };
}

export function savePermit(permit: Permit): AppThunk {
    return function (dispatch, getState) {
        dispatch(savePermitStarted());

        database.savePermit(permit, getState().permitPage.newFiles).then((result) => {
            dispatch(savePermitSuccessful(result));
        });
    };
}

export function loadPermitSubmitter(userId: string): AppThunk {
    return function (dispatch) {
        database.queryUser(userId).then((result) => {
            dispatch(loadPermitSubmitterSuccessful(result));
        });
    };
}

export function updatePermitLastAccess(permitId: string, userId: string): AppThunk {
    return function (dispatch) {
        LocationService.getLocationLog(userId).then((locationLog) => {
            dispatch(updatePermitLastAccessSuccessful(permitId, locationLog));
        });
    };
}
