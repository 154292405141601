import React from "react";
import { IonContent } from "@ionic/react";
import CustomBox from "./CustomBox";
import CustomMultiColView from "./CustomMultiColView";
import CustomItem from "./CustomItem";
import { startCase } from "lodash";
interface Props {
    attributes?: any;
}

const PermitDocAttributesTab: React.FC<Props> = ({ attributes }) => {
    const keys = Object.keys(attributes);
    return (
        <IonContent>
            <CustomBox title="More details">
                <CustomMultiColView>
                    {keys.map((key, index) => (
                        <CustomItem key={key} title={startCase(key)} text={attributes[key]}></CustomItem>
                    ))}
                </CustomMultiColView>
            </CustomBox>
        </IonContent>
    );
};

export default PermitDocAttributesTab;
