import { PermitAuthorisationSignature } from "../../models/PermitAuthorisationSignature";
import { RawFile } from "../../models/RawFile";
import { Role } from "../../models/Role";

// Constants
export const SUBMIT_PERMIT_AUTHORISATION = "SUBMIT_PERMIT_AUTHORISATION";

// Action Types
interface SubmitPermitAuthorisationAction {
    type: typeof SUBMIT_PERMIT_AUTHORISATION;
    clientRoles: Role[];
    signature: PermitAuthorisationSignature;
    signatureFile: RawFile;
    loggedInUserId: string;
}

export type AuthorisationTabActionTypes = SubmitPermitAuthorisationAction;
