import React, { useEffect } from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import { debounce } from "lodash";

interface Props {
    children: React.ReactNode;
}

const CustomMultiColView: React.FC<Props> = ({ children }) => {
    const childrenArray = React.Children.toArray(children);
    const childrenCount = React.Children.count(children);

    const [width, setWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setWidth(window.innerWidth);
        }, 100);

        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    });

    const twoColMode = width >= 768;

    const gridRows = [];
    let index = 1;
    for (let i = 0; i < childrenCount / 2; i++) {
        const lastRow = i === Math.round(childrenCount / 2) - 1;
        gridRows.push(
            <IonRow key={index++}>
                <IonCol
                    size="12"
                    sizeMd=""
                    className={lastRow && (twoColMode || !childrenArray[i * 2 + 1]) ? "bottom-row-item" : ""}
                >
                    {childrenArray[i * 2]}
                </IonCol>
                {childrenArray[i * 2 + 1] ? (
                    <IonCol size="12" sizeMd="" className={lastRow ? "bottom-row-item" : ""}>
                        {childrenArray[i * 2 + 1]}
                    </IonCol>
                ) : (
                    <IonCol size="12" sizeMd=""></IonCol>
                )}
            </IonRow>
        );
    }

    return <IonGrid>{gridRows}</IonGrid>;
};

export default CustomMultiColView;
