import React, { useState } from "react";
import {
    IonSegment,
    IonSegmentButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { Permit } from "../models/Permit";
import CustomLabelWithFlag from "./CustomLabelWithFlag";
import PermitFlagService from "../PermitFlagService";
import { arrowBack, ellipsisVertical } from "ionicons/icons";
import { useHistory } from "react-router";
import "./PermitHeader.css";
import { READY_TO_ISSUE } from "../constants";
import PermitMenu from "../components/PermitMenu";
import ReportProblemModal from "./ReportProblemModal";
import ShowCurrentLocationModal from "./ShowCurrentLocationModal";

interface Props {
    permit: Permit | null;
    selectedTab: string;
    displayPermitBoundariesTab: boolean;
    onTabChange: (selectedTab: string) => void;
    onReportProblem?: (comments: string) => void;
    onWorkCompleted?: () => void;
}

const PermitHeader: React.FC<Props> = ({
    permit,
    selectedTab,
    displayPermitBoundariesTab,
    onTabChange,
    onReportProblem,
    onWorkCompleted,
}) => {
    const [reportProblemModal, setReportProblemModal] = useState(false);
    const [showCurrentLocationModal, setShowCurrentLocationModal] = useState(false);

    let history = useHistory();

    const handleTabChange = (event: any) => {
        onTabChange(event.detail.value);
    };

    const [showPermitMenu, setShowPermitMenu] = useState(false);

    const handleOpenMenu = () => {
        setShowPermitMenu(true);
    };

    const handleCloseMenu = () => {
        setShowPermitMenu(false);
    };

    const handleWorkCompletedClicked = () => {
        setShowPermitMenu(false);
        onWorkCompleted!();
    };

    const handleReportProblemClicked = () => {
        setShowPermitMenu(false);
        setReportProblemModal(true);
    };

    const handleShowCurrentLocationClicked = () => {
        setShowPermitMenu(false);
        setShowCurrentLocationModal(true);
    };

    const handleCloseReportProblemModal = () => {
        setReportProblemModal(false);
    };

    const handleCloseShowCurrentLocationModal = () => {
        setShowCurrentLocationModal(false);
    };

    const handleReportProblem = (comments: string) => {
        onReportProblem!(comments);
        setReportProblemModal(false);
    };

    return (
        <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="end">
                        <IonButtons onClick={handleOpenMenu}>
                            <IonIcon slot="icon-only" icon={ellipsisVertical}></IonIcon>
                        </IonButtons>
                    </IonButtons>
                    <IonButtons slot="start">
                        {/* <IonBackButton defaultHref="/home" /> */}
                        <IonButton
                            onClick={() => {
                                history.push(`/home`);
                            }}
                        >
                            <IonIcon icon={arrowBack}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    {permit && (
                        <IonTitle>
                            {permit.permitNumber} - {permit.worksDescription}
                        </IonTitle>
                    )}
                </IonToolbar>
                <IonToolbar color="primary">
                    <IonSegment scrollable value={selectedTab} onIonChange={handleTabChange}>
                        <IonSegmentButton value="permit-details">
                            <CustomLabelWithFlag
                                text="Permit Details"
                                flag={permit ? PermitFlagService.getDetailsFlag(permit) : undefined}
                            />
                        </IonSegmentButton>

                        {displayPermitBoundariesTab && (
                            <IonSegmentButton value="permit-boundaries">
                                <CustomLabelWithFlag text="Boundaries" flag={"Green"} />
                            </IonSegmentButton>
                        )}

                        {permit &&
                            permit.documents.map((doc) => (
                                <IonSegmentButton value={"doc-" + doc.id} key={doc.id}>
                                    <CustomLabelWithFlag text={doc.type} />
                                </IonSegmentButton>
                            ))}

                        {permit?.status === READY_TO_ISSUE ? (
                            <IonSegmentButton value="gang-members">
                                <CustomLabelWithFlag
                                    text="Gang Members"
                                    flag={permit ? PermitFlagService.getPermitGangMembersFlag(permit) : undefined}
                                />
                            </IonSegmentButton>
                        ) : (
                            <IonSegmentButton value="eight-point-checklist">
                                <CustomLabelWithFlag
                                    text="8 Point Checklist"
                                    flag={permit ? PermitFlagService.getEightPointChecklistFlag(permit) : undefined}
                                />
                            </IonSegmentButton>
                        )}

                        <IonSegmentButton value="authorisation">
                            <CustomLabelWithFlag
                                text="Authorisation"
                                flag={permit ? PermitFlagService.getAuthorisationFlag(permit) : undefined}
                            />
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonHeader>

            <PermitMenu
                isOpen={showPermitMenu}
                onClose={handleCloseMenu}
                onReportProblem={onReportProblem ? handleReportProblemClicked : undefined}
                onShowCurrentLocation={handleShowCurrentLocationClicked}
                onWorkCompleted={onWorkCompleted ? handleWorkCompletedClicked : undefined}
            />

            <ReportProblemModal
                isOpen={reportProblemModal}
                onClose={handleCloseReportProblemModal}
                onSubmit={handleReportProblem}
            />

            <ShowCurrentLocationModal isOpen={showCurrentLocationModal} onClose={handleCloseShowCurrentLocationModal} />
        </>
    );
};

export default PermitHeader;
