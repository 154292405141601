import { GangMember } from "../../models/GangMember";

// Constants
export const LOAD_GANGMEMBERS_STARTED = "LOAD_GANGMEMBERS_STARTED";
export const LOAD_GANGMEMBERS_SUCCESSFUL = "LOAD_GANGMEMBERS_SUCCESSFUL";
export const LOAD_GANGMEMBERS_FAILED = "LOAD_GANGMEMBERS_FAILED";

// State Type
export interface GangMembersState {
    loading: boolean;
    gangMembers: GangMember[];
}

// Action Types
interface LoadGangMembersStartedAction {
    type: typeof LOAD_GANGMEMBERS_STARTED;
}

interface LoadGangMembersSuccessfulAction {
    type: typeof LOAD_GANGMEMBERS_SUCCESSFUL;
    gangMembers: GangMember[];
}

interface LoadGangMembersFailedAction {
    type: typeof LOAD_GANGMEMBERS_FAILED;
    error: Error;
}

export type GangMembersActionTypes =
    | LoadGangMembersStartedAction
    | LoadGangMembersSuccessfulAction
    | LoadGangMembersFailedAction;
