import React from "react";
import { Permit } from "../models/Permit";
import CustomBox from "./CustomBox";
import { PermitGangMember } from "../models/PermitGangMember";
import CustomItem from "./CustomItem";
import CustomMultiColView from "./CustomMultiColView";
import CustomSignItem from "./CustomSignItem";
import { GangMemberSignature } from "../models/GangMemberSignature";
import { isToday, isBetween } from "../DateService";
import { createGangMemberSignature } from "../containers/EightPointChecklistTabContainer/permitGangMemberService";

interface Props {
    permit: Permit;
    onPermitGangMemberDeleted: (permitGangMember: PermitGangMember) => void;
    isReadOnly: boolean;
    onGangMemberSignatureSubmitted: (
        permitGangMemberId: string,
        signature: GangMemberSignature,
        signatureDataUrl: string
    ) => void;
}

const PermitGangMembersList: React.FC<Props> = ({
    permit,
    onPermitGangMemberDeleted,
    isReadOnly,
    onGangMemberSignatureSubmitted,
}) => {
    const renderPermitGangMember = (permitGangMember: PermitGangMember) => {
        const canBeSignedToday = isBetween(new Date(), permit.validFrom, permit.validTo);
        let todayAuthorisation = permitGangMember.signatures.find((a) => isToday(a.date));

        if (!todayAuthorisation) {
            todayAuthorisation = createGangMemberSignature(new Date());
        }

        return (
            <CustomBox
                withClose={!isReadOnly}
                onCloseBox={() => {
                    onPermitGangMemberDeleted(permitGangMember);
                }}
                closeButtonText="Remove"
                key={permitGangMember.id}
                title={permitGangMember.gangMember.firstName + " " + permitGangMember.gangMember.lastName}
            >
                <CustomMultiColView>
                    <CustomItem
                        title="Induction number"
                        text={permitGangMember.gangMember.inductionNumber}
                    ></CustomItem>
                    <CustomItem title="Employers name" text={permitGangMember.gangMember.employerName}></CustomItem>
                    <CustomItem lines="none" title="Job title" text={permitGangMember.gangMember.jobTitle}></CustomItem>
                    {canBeSignedToday ? (
                        <CustomSignItem
                            isReadOnly={isReadOnly}
                            permitId={permit.id}
                            date={todayAuthorisation.date}
                            dateSigned={todayAuthorisation.dateSigned}
                            signatureFileKey={todayAuthorisation.signatureFileKey}
                            onSignatureSubmitted={(dataUrl) => {
                                onGangMemberSignatureSubmitted(permitGangMember.id, todayAuthorisation!, dataUrl);
                            }}
                            signatoryName={
                                permitGangMember.gangMember.firstName + " " + permitGangMember.gangMember.lastName
                            }
                        />
                    ) : (
                        <CustomItem
                            lines="none"
                            textColor="danger"
                            title="Signature"
                            text="This Permit cannot be signed today."
                        ></CustomItem>
                    )}
                </CustomMultiColView>
            </CustomBox>
        );
    };

    return <>{permit.permitGangMembers.map((g) => g.pendingDelete !== true && renderPermitGangMember(g))}</>;
};

export default PermitGangMembersList;
