import { User } from "../../models/User";
import { RawFile } from "../../models/RawFile";
import { LocationLog } from "../../models/LocationLog";
import { PermitAssignment } from "../../models/PermitAssignment";

// Constants
export const UPDATE_PERMIT_ASSIGNEE = "UPDATE_PERMIT_ASSIGNEE";
export const ADD_SITE_PHOTO = "ADD_SITE_PHOTO";
export const DELETE_SITE_PHOTO = "DELETE_SITE_PHOTO";
export const LOAD_PERMIT_SUBMITTER_SUCCESSFUL = "LOAD_PERMIT_SUBMITTER_SUCCESSFUL";
export const UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL = "UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL";

// Action Types
interface UpdatePermitAssigneeAction {
    type: typeof UPDATE_PERMIT_ASSIGNEE;
    assignment: PermitAssignment;
}

interface AddSitePhotoAction {
    type: typeof ADD_SITE_PHOTO;
    sitePhotoFileKey: string;
    photoFile: RawFile;
}

interface DeleteSitePhotoAction {
    type: typeof DELETE_SITE_PHOTO;
    sitePhotoFileKeys: string[];
}

interface LoadPermitSubmitterSuccessfulAction {
    type: typeof LOAD_PERMIT_SUBMITTER_SUCCESSFUL;
    user: User;
}

interface updatePermitLastAccessSuccessfulAction {
    type: typeof UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL;
    locationLog: LocationLog;
    permitId: string;
}

export type PermitDetailsTabActionTypes =
    | UpdatePermitAssigneeAction
    | AddSitePhotoAction
    | DeleteSitePhotoAction
    | LoadPermitSubmitterSuccessfulAction
    | updatePermitLastAccessSuccessfulAction;
