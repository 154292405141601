import React, { Component } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { AppState } from "../store";
import LoginPage from "../pages/LoginPage";

interface Props {
    component: any;
    exact: boolean;
    path: string;
    loggedIn: boolean;
}

export class PrivateRoute extends Component<Props> {
    render() {
        const { loggedIn, component: Component, ...rest } = this.props;
        return <Route {...rest} render={(props) => (loggedIn ? <Component {...props} /> : <LoginPage />)} />;
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        loggedIn: state.user?.loggedIn ?? false,
    };
};

export default connect(mapStateToProps)(PrivateRoute);
