import {
    MyPermitsState,
    MyPermitsActionTypes,
    LOAD_MY_PERMITS_SUCCESSFUL,
    LOAD_MY_PERMITS_STARTED,
    LOAD_MY_PERMITS_FAILED,
    LOAD_CLIENT_ROLES_STARTED,
    LOAD_CLIENT_ROLES_SUCCESSFUL,
    LOAD_CLIENT_ROLES_FAILED,
    LOAD_REFERENCE_PROCEDURES_SUCCESSFUL,
    SET_SHOW_EXPIRED_PERMITS_FLAG,
    SET_SELECTED_PROJECT,
    SET_SYNC_STATUS,
} from "./types";
import { Project } from "../../models/Project";
import find from "lodash/find";
import { Permit } from "../../models/Permit";

const initialState: MyPermitsState = {
    loading: false,
    showExpired: false,
    selectedProject: null,
    permits: [],
    referenceProcedures: [],
    currentSyncStatus: "",
    clientRoles: [],
    rolesLoading: false,
};

const getSelectedProject = (currentSelectedProject: Project | null, permits: Permit[]): Project | null => {
    if (
        currentSelectedProject === null ||
        find(permits, (p) => p.project.id === currentSelectedProject.id) === undefined
    ) {
        return permits.length > 0 ? { ...permits[0].project } : null;
    }
    return { ...currentSelectedProject };
};

const reducer = (state = initialState, action: MyPermitsActionTypes) => {
    switch (action.type) {
        case LOAD_MY_PERMITS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case LOAD_MY_PERMITS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                permits: action.permits,
                selectedProject: getSelectedProject(state.selectedProject, action.permits),
            };
        case LOAD_MY_PERMITS_FAILED:
            return {
                ...state,
                loading: false,
            };
        case LOAD_CLIENT_ROLES_STARTED:
            return {
                ...state,
                rolesLoading: true,
            };
        case LOAD_CLIENT_ROLES_SUCCESSFUL:
            return {
                ...state,
                rolesLoading: false,
                clientRoles: action.clientRoles,
            };
        case LOAD_CLIENT_ROLES_FAILED:
            return {
                ...state,
                rolesLoading: false,
            };
        case LOAD_REFERENCE_PROCEDURES_SUCCESSFUL:
            return {
                ...state,
                referenceProcedures: action.referenceProcedures,
            };
        case SET_SHOW_EXPIRED_PERMITS_FLAG:
            return {
                ...state,
                showExpired: action.value,
            };
        case SET_SELECTED_PROJECT:
            return {
                ...state,
                selectedProject: action.value,
            };
        case SET_SYNC_STATUS:
            return {
                ...state,
                currentSyncStatus: action.newStatus,
            };
        default:
            return state;
    }
};

export default reducer;
