import {
    PermitActionTypes,
    LOAD_PERMIT_STARTED,
    LOAD_PERMIT_SUCCESSFUL,
    LOAD_PERMIT_FAILED,
    SAVE_PERMIT_STARTED,
    SAVE_PERMIT_SUCCESSFUL,
    SAVE_PERMIT_FAILED,
    LOAD_PERMIT_SUBMITTER_SUCCESSFUL,
    UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL,
    REPORT_PROBLEM,
    WORK_COMPLETED,
} from "./types";
import { Permit } from "../../models/Permit";
import { User } from "../../models/User";
import { LocationLog } from "../../models/LocationLog";

export function loadPermitStarted(): PermitActionTypes {
    return {
        type: LOAD_PERMIT_STARTED,
    };
}

export function loadPermitSuccessful(permit: Permit): PermitActionTypes {
    return {
        type: LOAD_PERMIT_SUCCESSFUL,
        permit,
    };
}

export function loadPermitFailed(error: Error): PermitActionTypes {
    return {
        type: LOAD_PERMIT_FAILED,
        error,
    };
}

export function savePermitStarted(): PermitActionTypes {
    return {
        type: SAVE_PERMIT_STARTED,
    };
}

export function savePermitSuccessful(permit: Permit): PermitActionTypes {
    return {
        type: SAVE_PERMIT_SUCCESSFUL,
        permit,
    };
}

export function savePermitFailed(error: Error): PermitActionTypes {
    return {
        type: SAVE_PERMIT_FAILED,
        error,
    };
}

export function loadPermitSubmitterSuccessful(user: User): PermitActionTypes {
    return {
        type: LOAD_PERMIT_SUBMITTER_SUCCESSFUL,
        user,
    };
}

export function updatePermitLastAccessSuccessful(permitId: string, locationLog: LocationLog): PermitActionTypes {
    return {
        type: UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL,
        locationLog,
        permitId,
    };
}

export function reportProblem(comments: string, loggedInUserId: string): PermitActionTypes {
    return {
        type: REPORT_PROBLEM,
        comments,
        loggedInUserId,
    };
}

export function workCompleted(loggedInUserId: string): PermitActionTypes {
    return {
        type: WORK_COMPLETED,
        loggedInUserId,
    };
}
