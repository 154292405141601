import {
    ADD_GANG_MEMBER,
    DELETE_GANG_MEMBER,
    UPDATE_GANG_MEMBER,
    SET_GANG_MEMBER_UPDATE_MESSAGE,
    SUBMIT_GANG_MEMBER_SIGNATURE,
    EightPointChecklistTabActionTypes,
} from "./types";
import { Permit } from "../../models/Permit";
import { PermitState } from "../PermitContainer/types";

const reducer = (state: PermitState, action: EightPointChecklistTabActionTypes) => {
    switch (action.type) {
        case ADD_GANG_MEMBER:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    permitGangMembers: [
                        ...(state.permit ? state.permit.permitGangMembers : []),
                        { ...action.permitGangMember, pendingSync: true },
                    ],
                    pendingSave: true,
                } as Permit,
                permitGangMemberUpdateMessage: "",
            };
        case DELETE_GANG_MEMBER:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    permitGangMembers: state.permit?.permitGangMembers.map((m) =>
                        m.id === action.permitGangMember.id
                            ? {
                                  ...m,
                                  pendingDelete: true,
                              }
                            : { ...m }
                    ),
                    pendingSave: true,
                } as Permit,
                permitGangMemberUpdateMessage: "",
            };
        case UPDATE_GANG_MEMBER:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    permitGangMembers:
                        state.permit?.permitGangMembers.map((g) =>
                            g.id === action.permitGangMember.id ? { ...action.permitGangMember } : g
                        ) ?? [],
                    pendingSave: true,
                } as Permit,
                permitGangMemberUpdateMessage: "",
            };
        case SET_GANG_MEMBER_UPDATE_MESSAGE:
            return {
                ...state,
                permitGangMemberUpdateMessage: action.message,
            };
        case SUBMIT_GANG_MEMBER_SIGNATURE:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    permitGangMembers: state.permit?.permitGangMembers.map((m) =>
                        m.id === action.permitGangMemberId
                            ? {
                                  ...m,
                                  signatures: [
                                      ...m.signatures,
                                      {
                                          ...action.signature,
                                          pendingSync: true,
                                      },
                                  ],
                              }
                            : { ...m }
                    ),
                    pendingSave: true,
                } as Permit,
                newFiles: [...state.newFiles, action.signatureFile],
                permitGangMemberUpdateMessage: "",
            };

        default:
            return state;
    }
};

export default reducer;
