import {
    GangMembersState,
    GangMembersActionTypes,
    LOAD_GANGMEMBERS_SUCCESSFUL,
    LOAD_GANGMEMBERS_STARTED,
    LOAD_GANGMEMBERS_FAILED,
} from "./types";

const initialState: GangMembersState = {
    loading: false,
    gangMembers: [],
};

const reducer = (state = initialState, action: GangMembersActionTypes) => {
    switch (action.type) {
        case LOAD_GANGMEMBERS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case LOAD_GANGMEMBERS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                gangMembers: action.gangMembers,
            };
        case LOAD_GANGMEMBERS_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
