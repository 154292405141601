import React, { useEffect, useState } from "react";
import PermitGangMembersList from "../../components/PermitGangMembersList";
import EightPointChecklistTab from "../../components/EightPointChecklistTab";
import { Permit } from "../../models/Permit";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { PermitGangMember } from "../../models/PermitGangMember";
import {
    addPermitGangMember,
    deletePermitGangMember,
    updatePermitGangMember,
    setPermitGangMemberUpdateMessage,
    submitGangMemberSignature,
} from "./actions";
import { Guid } from "guid-typescript";
import { GangMemberSignature } from "../../models/GangMemberSignature";
import { markGangMemberAsAbsent, removePermitGangMemberFromPermit } from "./permitGangMemberService";
import { RawFile } from "../../models/RawFile";
import EightPointChecklistGangMembers from "../../components/EightPointChecklistGangMembers";
import {
    MANAGE_GANG_MEMBER_OF_READY_TO_ISSUE_PERMITS,
    READY_TO_ISSUE,
    RUN_EIGHT_POINT_CHECKLIST,
} from "../../constants";
import { User } from "../../models/User";
import PermissionService from "../../PermissionService";
import { Role } from "../../models/Role";
import moment from "moment";
import { isAfter, isBetween } from "../../DateService";

interface EightPointChecklistTabContainerProps {
    permitGangMemberUpdateMessage: string;
    permit: Permit | null;
    currentUser: User | null;
    addPermitGangMember: typeof addPermitGangMember;
    deletePermitGangMember: typeof deletePermitGangMember;
    updatePermitGangMember: typeof updatePermitGangMember;
    setPermitGangMemberUpdateMessage: typeof setPermitGangMemberUpdateMessage;
    submitGangMemberSignature: typeof submitGangMemberSignature;
    clientRoles: Role[] | null;
}

const EightPointChecklistTabContainer: React.FC<EightPointChecklistTabContainerProps> = ({
    permitGangMemberUpdateMessage,
    permit,
    currentUser,
    addPermitGangMember,
    deletePermitGangMember,
    updatePermitGangMember,
    setPermitGangMemberUpdateMessage,
    submitGangMemberSignature,
    clientRoles,
}) => {
    const [offset, setOffset] = useState(0);
    const limit = 7;

    const dateFrom = moment(permit?.validFrom).add(offset, "days").toDate();
    const dateTo =
        permit &&
        isAfter(
            moment(dateFrom)
                .add(limit - 1, "days")
                .toDate(),
            permit.validTo
        )
            ? permit.validTo
            : moment(dateFrom)
                  .add(limit - 1, "days")
                  .toDate();
    const ableToClickNextWeek =
        permit &&
        isAfter(
            permit.validTo,
            moment(permit.validFrom)
                .add(offset + limit - 1, "days")
                .toDate()
        );
    const ableToClickPreviousWeek = offset > 0;

    useEffect(() => {
        if (permit) {
            if (isBetween(new Date(), permit.validFrom, permit.validTo)) {
                const initialOffset =
                    Math.floor(moment(new Date()).diff(moment(permit.validFrom), "days") / limit) * limit;
                setOffset(initialOffset);
            }
        }
    }, [permit]);

    const handleNextWeekClick = () => {
        ableToClickNextWeek && setOffset(offset + limit);
    };

    const handlePreviousWeekClick = () => {
        ableToClickPreviousWeek && setOffset(offset - limit);
    };

    const handleDeletePermitGangMember = async (permitGangMember: PermitGangMember) => {
        removePermitGangMemberFromPermit(
            permitGangMember,
            deletePermitGangMember,
            setPermitGangMemberUpdateMessage,
            permit?.status ?? ""
        );
    };

    const handleMarkGangMemberAsAbsent = async (permitGangMember: PermitGangMember) => {
        markGangMemberAsAbsent(
            permitGangMember,
            updatePermitGangMember,
            setPermitGangMemberUpdateMessage,
            permit?.status ?? ""
        );
    };

    const generateFileKey = (): string => {
        return "file-" + Guid.create().toString();
    };

    const handleGangMemberSignatureSubmitted = async (
        permitGangMemberId: string,
        signature: GangMemberSignature,
        signatureDataUrl: string
    ) => {
        const fileKey = generateFileKey();

        submitGangMemberSignature(
            permitGangMemberId,
            {
                ...signature,
                signatureFileKey: fileKey,
                dateSigned: new Date(),
            } as GangMemberSignature,
            { fileName: fileKey, contentType: "image/png", dataUrl: signatureDataUrl } as RawFile
        );
    };

    if (!(permit && currentUser && clientRoles)) return null;
    const ableToRunEightPointChecklist = PermissionService.userHasPermission(
        currentUser,
        RUN_EIGHT_POINT_CHECKLIST,
        clientRoles,
        permit.project.id
    );
    const ableToEditGangmembers = PermissionService.userHasPermission(
        currentUser,
        MANAGE_GANG_MEMBER_OF_READY_TO_ISSUE_PERMITS,
        clientRoles,
        permit.project.id
    );

    return (
        <>
            {permit?.status === READY_TO_ISSUE ? (
                <EightPointChecklistTab
                    isReadOnly={!ableToEditGangmembers}
                    eightPointCheckList={permit.eightPointCheckList}
                    onPermitGangMemberAdded={addPermitGangMember}
                    notificationMessage={permitGangMemberUpdateMessage}
                    onNotificationMessageDisplayed={() => setPermitGangMemberUpdateMessage("")}
                    client={currentUser?.client.name}
                >
                    <PermitGangMembersList
                        isReadOnly={!ableToEditGangmembers}
                        onPermitGangMemberDeleted={handleDeletePermitGangMember}
                        permit={permit}
                        onGangMemberSignatureSubmitted={handleGangMemberSignatureSubmitted}
                    />
                </EightPointChecklistTab>
            ) : (
                <EightPointChecklistTab
                    isReadOnly={!ableToRunEightPointChecklist}
                    eightPointCheckList={permit.eightPointCheckList}
                    onPermitGangMemberAdded={addPermitGangMember}
                    notificationMessage={permitGangMemberUpdateMessage}
                    onNotificationMessageDisplayed={() => setPermitGangMemberUpdateMessage("")}
                    client={currentUser?.client.name}
                    onNextWeekClick={ableToClickNextWeek ? handleNextWeekClick : undefined}
                    onPreviousWeekClick={ableToClickPreviousWeek ? handlePreviousWeekClick : undefined}
                >
                    <EightPointChecklistGangMembers
                        onPermitGangMemberDeleted={handleDeletePermitGangMember}
                        onMarkGangMemberAsAbsent={handleMarkGangMemberAsAbsent}
                        permit={permit}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        onGangMemberSignatureSubmitted={handleGangMemberSignatureSubmitted}
                        isReadOnly={!ableToRunEightPointChecklist}
                    />
                </EightPointChecklistTab>
            )}
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        permit: state.permitPage.permit,
        userProfile: state.user.currentProfile,
        currentUser: state.user.currentUser,
        permitGangMemberUpdateMessage: state.permitPage.permitGangMemberUpdateMessage,
        clientRoles: state.myPermits.clientRoles,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        addPermitGangMember: (permitGangMember: PermitGangMember) => dispatch(addPermitGangMember(permitGangMember)),
        deletePermitGangMember: (permitGangMember: PermitGangMember) =>
            dispatch(deletePermitGangMember(permitGangMember)),
        updatePermitGangMember: (permitGangMember: PermitGangMember) =>
            dispatch(updatePermitGangMember(permitGangMember)),
        setPermitGangMemberUpdateMessage: (message: string) => dispatch(setPermitGangMemberUpdateMessage(message)),
        submitGangMemberSignature: (
            permitGangMemberId: string,
            signature: GangMemberSignature,
            signatureFile: RawFile
        ) => dispatch(submitGangMemberSignature(permitGangMemberId, signature, signatureFile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EightPointChecklistTabContainer);
