import { Permit } from "./models/Permit";
import { isToday } from "./DateService";
import { READY_TO_ISSUE } from "./constants";

export const GreenFlag = "Green";
export const RedFlag = "Red";

const permitFlagService = {
    getDetailsFlag(permit: Permit): string {
        if (permit.status !== READY_TO_ISSUE) {
            return GreenFlag;
        }

        if (permit.permitAssignments && permit.permitAssignments.filter((a) => !a.userId).length > 0) return RedFlag;

        if (permit.sitePhotoFileKeys && permit.sitePhotoFileKeys.length > 0) return GreenFlag;

        return RedFlag;
    },

    getPermitGangMembersFlag(permit: Permit): string {
        if (permit.shouldHaveGangMembers !== undefined && permit.shouldHaveGangMembers === false) {
            return GreenFlag;
        }

        if (permit.status !== READY_TO_ISSUE) {
            return GreenFlag;
        }

        const permitGangMembersHaveUnsignedSignature = permit.permitGangMembers.filter(
            (g) => !g.signatures.find((a) => isToday(a.date) && !g.pendingDelete)
        );

        if (
            permit.permitGangMembers.filter((g) => !g.pendingDelete).length > 0 &&
            !(permitGangMembersHaveUnsignedSignature.length > 0)
        ) {
            return GreenFlag;
        }

        return RedFlag;
    },

    getEightPointChecklistFlag(permit: Permit): string {
        if (permit.shouldHaveGangMembers !== undefined && permit.shouldHaveGangMembers === false) {
            return GreenFlag;
        }

        const permitGangMembersHaveUnsignedSignature = permit.permitGangMembers.filter(
            (g) => !g.signatures.find((a) => isToday(a.date) && !g.pendingDelete)
        );

        if (permitGangMembersHaveUnsignedSignature.length > 0) return RedFlag;
        return GreenFlag;
    },

    getAuthorisationFlag(permit: Permit): string {
        if (permit.status !== READY_TO_ISSUE) {
            return GreenFlag;
        }

        if (
            permit.permitAssignments &&
            permit.permitAssignments?.filter(
                (a) => !(permit.signatures.filter((s) => s.roleId === a.roleId).length > 0)
            ).length > 0
        )
            return RedFlag;

        return GreenFlag;
    },

    getPermitFlag(permit: Permit): string {
        if (this.getDetailsFlag(permit) === RedFlag) {
            return RedFlag;
        }

        if (this.getAuthorisationFlag(permit) === RedFlag) {
            return RedFlag;
        }

        if (permit.status === READY_TO_ISSUE) {
            if (this.getPermitGangMembersFlag(permit) === RedFlag) {
                return RedFlag;
            }
        } else {
            if (this.getEightPointChecklistFlag(permit) === RedFlag) {
                return RedFlag;
            }
        }

        return GreenFlag;
    },

    getPermitFlagForAuthorisation(permit: Permit): string {
        if (this.getDetailsFlag(permit) === RedFlag) {
            return RedFlag;
        }

        if (permit.status === READY_TO_ISSUE) {
            if (this.getPermitGangMembersFlag(permit) === RedFlag) {
                return RedFlag;
            }
        }

        return GreenFlag;
    },
};

export default permitFlagService;
