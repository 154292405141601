import React from "react";
import AddPermitGangMemberContainer from "../containers/AddPermitGangMemberContainer";
import { PermitGangMember } from "../models/PermitGangMember";
import CustomBox from "./CustomBox";

interface Props {
    eightPointCheckListDeclaration: string;
    onPermitGangMemberAdded: (permitGangMember: PermitGangMember) => void;
    isReadOnly: boolean;
}

const AddPermitGangMember: React.FC<Props> = ({
    eightPointCheckListDeclaration,
    onPermitGangMemberAdded,
    isReadOnly,
}) => {
    const declarationLines = eightPointCheckListDeclaration.split(/\r?\n/);
    return (
        <CustomBox
            title="Gang Member"
            contentText={
                <>
                    {declarationLines.map((l) => (
                        <h2>{l}</h2>
                    ))}
                    <AddPermitGangMemberContainer
                        isDisabled={isReadOnly}
                        onPermitGangMemberAdded={onPermitGangMemberAdded}
                    />
                </>
            }
        ></CustomBox>
    );
};

export default AddPermitGangMember;
