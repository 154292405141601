import { Role } from "./models/Role";
import { User } from "./models/User";

const userHasPermission = (user: User, permissionKey: string, clientRoles: Role[], projectId: string): boolean => {
    const rolesThatHavePermission = clientRoles.filter((r) => r.permissionKeys.includes(permissionKey));
    return !!user.assignments.find(
        (a) => a.project.id === projectId && rolesThatHavePermission.find((r) => r.id === a.roleId)
    );
};

const service = { userHasPermission };
export default service;
