import {
    LoginActionTypes,
    LOGIN_STARTED,
    LOGIN_SUCCESSFUL,
    LOGIN_FAILED,
    UserState,
    SET_CURRENT_USER,
    SET_CURRENT_PROFILE,
} from "./types";

const initialState: UserState = {
    loggedIn: false,
    loggingInProgress: false,
    currentProfile: null,
    currentUser: null,
    loginError: "",
};

const reducer = (state = initialState, action: LoginActionTypes): UserState => {
    switch (action.type) {
        case LOGIN_STARTED:
            return {
                ...state,
                loginError: "",
                loggingInProgress: true,
            };
        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                loginError: "",
                loggingInProgress: false,
                currentProfile: action.userProfile,
                currentUser: action.currentUser ? action.currentUser : null,
                loggedIn: true,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loginError: action.error.message,
                loggingInProgress: false,
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.user,
            };
        case SET_CURRENT_PROFILE:
            return {
                ...state,
                currentProfile: action.profile,
            };
        default:
            return state;
    }
};

export default reducer;
