import moment from "moment";

function isToday(dateToCheck: Date): boolean {
    var onlyDate = moment(moment(dateToCheck).format("LL"));
    var todayDate = moment(moment(new Date()).format("LL"));
    const result = onlyDate.isSame(todayDate);
    return result;
}

function isSame(firstDate: Date, secondDate: Date): boolean {
    var firstOnlyDate = moment(moment(firstDate).format("LL"));
    var secondOnlyDate = moment(moment(secondDate).format("LL"));
    const result = firstOnlyDate.isSame(secondOnlyDate);
    return result;
}

function isAfter(firstDate: Date, secondDate: Date): boolean {
    var firstOnlyDate = moment(moment(firstDate).format("LL"));
    var secondOnlyDate = moment(moment(secondDate).format("LL"));
    const result = firstOnlyDate.isAfter(secondOnlyDate, "day");
    return result;
}

function isBetween(dateToCheck: Date, startDate: Date, endDate: Date): boolean {
    var dateOnlyToCheck = moment(moment(dateToCheck).format("LL"));
    var startOnlyDate = moment(moment(startDate).format("LL"));
    var endOnlyDate = moment(moment(endDate).format("LL"));
    const result = dateOnlyToCheck.isBetween(startOnlyDate, endOnlyDate, "day", "[]");
    return result;
}

export { isToday, isSame, isAfter, isBetween };
