import { IonToolbar, IonButton, IonActionSheet } from "@ionic/react";
import React, { useState } from "react";
import { close } from "ionicons/icons";
import { ReferenceProcedure } from "../models/ReferenceProcedure";
import { useHistory } from "react-router";

interface Props {
    referenceProcedures: ReferenceProcedure[];
}

const HomeFooter: React.FC<Props> = ({ referenceProcedures }) => {
    const [showActionSheet, setShowActionSheet] = useState(false);

    let history = useHistory();

    const handleActionSheetButtonClick = (procedure: string, docId: string): void => {
        const encodedProcedure = encodeURI(procedure);
        history.push(`/home/referenceprocedure/${encodedProcedure}/${docId}`);
    };

    const createButtons = () => {
        let buttons = [];
        var sortedRP = referenceProcedures.sort((a, b) => (a.procedure > b.procedure ? 1 : -1));
        for (let i = 0; i < sortedRP.length; i++) {
            let button = {
                text: sortedRP[i].procedure,
                handler: () => {
                    handleActionSheetButtonClick(sortedRP[i].procedure, sortedRP[i].id);
                },
            };
            buttons.push(button);
        }

        let cancelButton = {
            text: "Cancel",
            icon: close,
            role: "cancel",
            handler: () => {},
        };
        buttons.push(cancelButton);
        return buttons;
    };

    return (
        <>
            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                buttons={createButtons()}
            ></IonActionSheet>

            <IonToolbar>
                <IonButton fill="clear" expand="block" onClick={() => setShowActionSheet(true)}>
                    Reference Procedures
                </IonButton>
            </IonToolbar>
        </>
    );
};

export default HomeFooter;
