import React, { useEffect, useState } from "react";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { loadGangMembers } from "./thunks";
import AddPermitGangMemberModal from "../../components/AddPermitGangMemberModal";
import { GangMember } from "../../models/GangMember";
import { Permit } from "../../models/Permit";
import { service } from "./service";
import { createPermitGangMember } from "../EightPointChecklistTabContainer/permitGangMemberService";
import { PermitGangMember } from "../../models/PermitGangMember";
import { IonButton } from "@ionic/react";

interface AddPermitGangMemberContainerProps {
    clientId?: string;
    gangMembers: GangMember[];
    loadGangMembers: typeof loadGangMembers;
    permit: Permit | null;
    onPermitGangMemberAdded: (permitGangMember: PermitGangMember) => void;
    isDisabled: boolean;
}

const AddPermitGangMemberContainer: React.FC<AddPermitGangMemberContainerProps> = ({
    clientId,
    loadGangMembers,
    gangMembers,
    permit,
    onPermitGangMemberAdded,
    isDisabled,
}) => {
    const [showAddPermitGangMemberModal, setShowAddPermitGangMemberModal] = useState(false);

    useEffect(() => {
        if (clientId) {
            loadGangMembers(clientId);
        }
    }, [clientId, loadGangMembers]);

    const handleClickAddPermitGangMemberButton = () => {
        setShowAddPermitGangMemberModal(true);
    };

    const handleCloseAddPermitGangMemberModal = (gangMember?: GangMember) => {
        if (!gangMember) {
            setShowAddPermitGangMemberModal(false);
            return;
        }

        const permitGangMember = createPermitGangMember(gangMember);
        onPermitGangMemberAdded(permitGangMember);
        setShowAddPermitGangMemberModal(false);
    };

    if (!permit) return null;

    const availableGangMembers = service.getAvailableGangMembers(permit, gangMembers);

    return (
        <>
            <AddPermitGangMemberModal
                gangMembers={availableGangMembers}
                isOpen={showAddPermitGangMemberModal}
                onClose={handleCloseAddPermitGangMemberModal}
            />
            <IonButton
                disabled={isDisabled}
                onClick={handleClickAddPermitGangMemberButton}
                shape="round"
                color="primary"
                size="small"
                padding-start="14"
            >
                Add Gang Member
            </IonButton>
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        clientId: state.user.currentUser ? state.user.currentUser.client.id : undefined,
        gangMembers: state.addPermitGangMember.gangMembers,
        permit: state.permitPage.permit,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadGangMembers: (clientId: string) => dispatch(loadGangMembers(clientId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPermitGangMemberContainer);
