import {
    ADD_GANG_MEMBER,
    DELETE_GANG_MEMBER,
    UPDATE_GANG_MEMBER,
    SET_GANG_MEMBER_UPDATE_MESSAGE,
    SUBMIT_GANG_MEMBER_SIGNATURE,
    EightPointChecklistTabActionTypes,
} from "./types";
import { PermitGangMember } from "../../models/PermitGangMember";
import { GangMemberSignature } from "../../models/GangMemberSignature";
import { RawFile } from "../../models/RawFile";

export function addPermitGangMember(permitGangMember: PermitGangMember): EightPointChecklistTabActionTypes {
    return {
        type: ADD_GANG_MEMBER,
        permitGangMember,
    };
}

export function deletePermitGangMember(permitGangMember: PermitGangMember): EightPointChecklistTabActionTypes {
    return {
        type: DELETE_GANG_MEMBER,
        permitGangMember,
    };
}

export function updatePermitGangMember(permitGangMember: PermitGangMember): EightPointChecklistTabActionTypes {
    return {
        type: UPDATE_GANG_MEMBER,
        permitGangMember,
    };
}

export function setPermitGangMemberUpdateMessage(message: string): EightPointChecklistTabActionTypes {
    return {
        type: SET_GANG_MEMBER_UPDATE_MESSAGE,
        message,
    };
}

export function submitGangMemberSignature(
    permitGangMemberId: string,
    signature: GangMemberSignature,
    signatureFile: RawFile
): EightPointChecklistTabActionTypes {
    return {
        type: SUBMIT_GANG_MEMBER_SIGNATURE,
        permitGangMemberId,
        signature: signature,
        signatureFile,
    };
}
