import { AppThunk } from "../../store/AppThunkType";
import { loadPermitSubmitterSuccessful } from "./actions";
import database from "../../Database";

export function loadPermitSubmitter(userId: string): AppThunk {
    return function (dispatch) {
        database.queryUser(userId).then((result) => {
            dispatch(loadPermitSubmitterSuccessful(result));
        });
    };
}
