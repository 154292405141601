import {
    IonItem,
    IonLabel,
    IonList,
    IonToggle,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonModal,
    IonTitle,
    IonContent,
} from "@ionic/react";
import React from "react";

interface MyPermitsFilterModalProps {
    isOpen: boolean;
    onClose: () => void;
    showExpired: boolean;
    onShowExpiredChange: (value: boolean) => void;
}

const MyPermitsFilterModal: React.FC<MyPermitsFilterModalProps> = ({
    isOpen,
    onClose,
    showExpired,
    onShowExpiredChange,
}) => {
    const onShowExpiredChangeHandler = (event: any) => {
        onShowExpiredChange(event.detail.checked);
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Filters</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose} strong>
                            Done
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent class="outer-content">
                <IonList>
                    <IonItem>
                        <IonLabel>Show expired</IonLabel>
                        <IonToggle checked={showExpired} onIonChange={onShowExpiredChangeHandler} color="success" />
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal>
    );
};

export default MyPermitsFilterModal;
