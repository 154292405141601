import {
    IonItem,
    IonLabel,
    IonList,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonModal,
    IonTitle,
    IonContent,
    IonTextarea,
} from "@ionic/react";
import React, { useEffect, useState } from "react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (comments: string) => void;
}

const ReportProblemModal: React.FC<Props> = ({ isOpen, onClose, onSubmit }) => {
    const [comments, setComments] = useState<string>("");

    useEffect(() => {
        if (isOpen) {
            setComments("");
        }
    }, [isOpen]);

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Report Problem</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose} strong>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent class="outer-content">
                <IonList>
                    <IonItem>
                        <IonLabel position="floating">Comments</IonLabel>
                        <IonTextarea value={comments} onIonChange={(e) => setComments(e.detail.value!)}></IonTextarea>
                    </IonItem>
                    <IonItem>
                        <IonButton color="primary" onClick={(e) => onSubmit(comments)}>
                            Submit
                        </IonButton>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonModal>
    );
};

export default ReportProblemModal;
