import React from "react";
import { IonPage } from "@ionic/react";
import LoginContainer from "../containers/LoginContainer";

const LoginPage: React.FC = () => {
    return (
        <IonPage>
            <LoginContainer />
        </IonPage>
    );
};

export default LoginPage;
