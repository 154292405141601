import { SyncGatewaySession } from "./models/UserProfile";
import WebDatabase from "./Database_Web";
import NativeDatabase from "./Database_Native";
import { Permit } from "./models/Permit";
import { isPlatform } from "@ionic/react";
import { RawFile } from "./models/RawFile";
import { GangMember } from "./models/GangMember";
import { ReferenceProcedure } from "./models/ReferenceProcedure";
import { User } from "./models/User";
import { Role } from "./models/Role";

export type ReplicatorStatusChangeEventHandler = (newStatus: string) => void;
export type ReplicationFailedEventHandler = (error: string) => void;
export type ReplicationCompletedEventHandler = (docId: string) => void;

export interface Database {
    setReplicatorStatusChangeEventHandler: (handler?: ReplicatorStatusChangeEventHandler) => void;
    setReplicationFailedEventHandler: (handler?: ReplicationFailedEventHandler) => void;
    setReplicationCompletedEventHandler: (handler?: ReplicationCompletedEventHandler) => void;
    closeDatabase: () => Promise<void>;
    openDatabase: (
        syncGatewayAddress: string,
        isSsl: boolean,
        syncGatewayDatabase: string,
        userId: string,
        session: SyncGatewaySession
    ) => Promise<void>;

    queryPermits: () => Promise<Permit[]>;
    queryPermit: (permitId: string) => Promise<Permit>;
    savePermit: (permit: Permit, newFiles: RawFile[]) => Promise<Permit>;
    loadPermitFile: (permitId: string, fileKey: string) => Promise<string>;
    loadReferenceProcedureFile: (referenceProcedureId: string, fileKey: string) => Promise<string>;
    queryGangMembers: (clientId: string) => Promise<GangMember[]>;
    queryReferenceProcedures: (clientId: string) => Promise<ReferenceProcedure[]>;
    queryReferenceProcedure: (docId: string) => Promise<ReferenceProcedure>;
    queryProjectUsers: (projectId: string) => Promise<User[]>;
    queryClientRoles: (clientId: string) => Promise<Role[]>;
    queryUser: (userId: string) => Promise<User>;
}

const database: Database = isPlatform("hybrid") ? NativeDatabase : WebDatabase;

export default database;
