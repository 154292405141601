import React from "react";
import { IonGrid, IonRow, IonCol } from "@ionic/react";
import CustomSignItem from "./CustomSignItem";
import CustomBox from "./CustomBox";
import { PermitGangMember } from "../models/PermitGangMember";
import { isAfter, isSame, isToday } from "../DateService";
import moment from "moment";
import { createGangMemberSignature } from "../containers/EightPointChecklistTabContainer/permitGangMemberService";
import { GangMemberSignature } from "../models/GangMemberSignature";

interface EightPointChecklistGangMemberSignProps {
    permitId: string;
    dateFrom: Date;
    dateTo: Date;
    permitGangMember: PermitGangMember;
    onGangMemberSignatureSubmitted: (
        permitGangMemberId: string,
        signature: GangMemberSignature,
        signatureDataUrl: string
    ) => void;
    onPermitGangMemberDeleted?: (permitGangMember: PermitGangMember) => void;
    onMarkGangMemberAsAbsent?: (PermitGangMember: PermitGangMember) => void;
    isReadOnly?: boolean;
}

const EightPointChecklistGangMemberSign: React.FC<EightPointChecklistGangMemberSignProps> = ({
    permitId,
    dateFrom,
    dateTo,
    permitGangMember,
    onGangMemberSignatureSubmitted: onGangMemberAuthorisationSubmitted,
    onPermitGangMemberDeleted,
    onMarkGangMemberAsAbsent,
    isReadOnly,
}) => {
    const handleSignatureSubmitted = (signature: GangMemberSignature, signatureDataUrl: string) => {
        onGangMemberAuthorisationSubmitted(permitGangMember.id, signature, signatureDataUrl);
    };

    const signatures: GangMemberSignature[] = [];

    let d: Date = new Date(dateFrom);

    while (!isAfter(d, dateTo)) {
        // eslint-disable-next-line no-loop-func
        let gangMemberAuthorisation = permitGangMember.signatures.find((a) => isSame(a.date, d));

        if (!gangMemberAuthorisation) {
            gangMemberAuthorisation = createGangMemberSignature(d);
        }

        signatures.push(gangMemberAuthorisation);
        d = moment(d).add(1, "days").toDate();
    }

    const renderGangMemberAuthorisations = (signature: GangMemberSignature) => {
        return (
            <IonCol size="12" sizeMd="" key={signature.id}>
                <CustomSignItem
                    permitId={permitId}
                    date={signature.date}
                    isAbsent={signature.isAbsent}
                    dateSigned={signature.dateSigned}
                    signatureFileKey={signature.signatureFileKey}
                    onSignatureSubmitted={(dataUrl) => {
                        handleSignatureSubmitted(signature, dataUrl);
                    }}
                    signatoryName={permitGangMember.gangMember.firstName + " " + permitGangMember.gangMember.lastName}
                    isReadOnly={isReadOnly}
                />
            </IonCol>
        );
    };

    return (
        <CustomBox
            withClose={!isReadOnly && !permitGangMember.signatures.find((a) => isToday(a.date))?.isAbsent}
            onCloseBox={
                onPermitGangMemberDeleted
                    ? () => {
                          onPermitGangMemberDeleted(permitGangMember);
                      }
                    : onMarkGangMemberAsAbsent
                    ? () => {
                          onMarkGangMemberAsAbsent(permitGangMember);
                      }
                    : undefined
            }
            closeButtonText={onPermitGangMemberDeleted ? "Remove" : onMarkGangMemberAsAbsent ? "Absent today" : ""}
            title={permitGangMember.gangMember.firstName + " " + permitGangMember.gangMember.lastName}
            text={
                "Company: " +
                (permitGangMember.gangMember.employerName ? permitGangMember.gangMember.employerName : "-")
            }
        >
            <IonGrid>
                <IonRow>{signatures.map(renderGangMemberAuthorisations)}</IonRow>
            </IonGrid>
        </CustomBox>
    );
};

export default EightPointChecklistGangMemberSign;
