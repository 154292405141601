import React from "react";
import EightPointChecklistGangMemberSign from "./EightPointChecklistGangMemberSign";
import { PermitGangMember } from "../models/PermitGangMember";
import { Permit } from "../models/Permit";
import { GangMemberSignature } from "../models/GangMemberSignature";
import { ISSUED, READY_TO_ISSUE } from "../constants";

interface EightPointChecklistGangMembersProps {
    permit: Permit;
    dateFrom: Date;
    dateTo: Date;
    onPermitGangMemberDeleted: (permitGangMember: PermitGangMember) => void;
    onMarkGangMemberAsAbsent: (permitGangMember: PermitGangMember) => void;
    onGangMemberSignatureSubmitted: (
        permitGangMemberId: string,
        signature: GangMemberSignature,
        signatureDataUrl: string
    ) => void;
    isReadOnly?: boolean;
}

const EightPointChecklistGangMembers: React.FC<EightPointChecklistGangMembersProps> = ({
    permit,
    dateFrom,
    dateTo,
    onPermitGangMemberDeleted,
    onMarkGangMemberAsAbsent,
    onGangMemberSignatureSubmitted,
    isReadOnly,
}) => {
    const renderPermitGangMember = (permitGangMember: PermitGangMember) => {
        return (
            <EightPointChecklistGangMemberSign
                permitId={permit.id}
                dateFrom={dateFrom}
                dateTo={dateTo}
                key={permitGangMember.id}
                permitGangMember={permitGangMember}
                onGangMemberSignatureSubmitted={onGangMemberSignatureSubmitted}
                onPermitGangMemberDeleted={permit.status === READY_TO_ISSUE ? onPermitGangMemberDeleted : undefined}
                onMarkGangMemberAsAbsent={permit.status === ISSUED ? onMarkGangMemberAsAbsent : undefined}
                isReadOnly={isReadOnly}
            />
        );
    };

    return <>{permit.permitGangMembers.map((g) => g.pendingDelete !== true && renderPermitGangMember(g))}</>;
};

export default EightPointChecklistGangMembers;
