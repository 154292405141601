/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { loadCss, loadModules } from "esri-loader";

interface Props {
    longitude?: number;
    latitude?: number;
}

const CustomMap = ({ longitude, latitude }: Props) => {
    loadCss();
    const mapEl = useRef(null);
    useEffect(() => {
        let view: any;

        loadModules(["esri/config", "esri/WebMap", "esri/views/MapView", "esri/Graphic", "esri/widgets/ScaleBar"])
            .then(([esriConfig, WebMap, MapView, Graphic, ScaleBar]) => {
                esriConfig.apiKey =
                    "AAPKe7d21445ba604a5488756d8cd0d232f4OzbQd3I0wGKfs8gXxfkdow9QldSlgBNDJlyOdMSWpz5iRabyH8rT3-MOgq3t2HHD";

                const webmap = new WebMap({
                    portalItem: {
                        id: "5cd15f2b7ac1436ea9c6b072b120d537",
                    },
                });

                view = new MapView({
                    map: webmap,
                    center: longitude && latitude ? [longitude, latitude] : undefined,
                    zoom: 9,
                    container: mapEl.current,
                    constraints: {
                        snapToZoom: false,
                    },
                });

                const point = {
                    //Create a point
                    type: "point",
                    longitude,
                    latitude,
                };

                const simpleMarkerSymbol = {
                    type: "simple-marker",
                    color: [226, 119, 40], // Orange
                    outline: {
                        color: [255, 255, 255], // White
                        width: 1,
                    },
                };

                // Add the geometry and symbol to a new graphic
                const graphic = new Graphic({
                    geometry: point,
                    symbol: simpleMarkerSymbol,
                });

                view.graphics.add(graphic);

                const scalebar = new ScaleBar({
                    view: view,
                });
                view.ui.add(scalebar, "bottom-left");

                return () => {
                    // clean up the map view
                    if (!!view) {
                        view.destroy();
                        view = null;
                    }
                };
            })
            .catch((err) => console.error(err));
    }, []);

    return <div className="webmap" ref={mapEl} style={{ height: "100%" }}></div>;
};

export default CustomMap;
