import { SyncGatewaySession } from "./models/UserProfile";
import { Permit } from "./models/Permit";
import { RawFile } from "./models/RawFile";
import { GangMember } from "./models/GangMember";
import { ReferenceProcedure } from "./models/ReferenceProcedure";
import { User } from "./models/User";
import {
    Database,
    ReplicationCompletedEventHandler,
    ReplicationFailedEventHandler,
    ReplicatorStatusChangeEventHandler,
} from "./Database";
import cblPlugin from "./CouchbaseLitePlugin";
import { Role } from "./models/Role";

class NativeDatabase implements Database {
    closeDatabase(): Promise<void> {
        return cblPlugin.closeDatabase();
    }

    openDatabase(
        syncGatewayAddress: string,
        isSsl: boolean,
        syncGatewayDatabase: string,
        userId: string,
        session: SyncGatewaySession
    ): Promise<void> {
        return cblPlugin.openDatabase(syncGatewayAddress, isSsl, syncGatewayDatabase, userId, session);
    }

    queryPermits(): Promise<Permit[]> {
        return cblPlugin.queryDocs<Permit>({ type: "permit" });
    }

    queryPermit(permitId: string): Promise<Permit> {
        const docId = `permit::${permitId}`;
        return cblPlugin.queryDoc(docId);
    }

    savePermit(permit: Permit, newFiles: RawFile[]): Promise<Permit> {
        const docId = `permit::${permit.id}`;
        const fileKeysUsed = this.getPermitOwnedFileKeys(permit);
        return cblPlugin.saveDoc(docId, permit, newFiles, fileKeysUsed);
    }

    private getPermitOwnedFileKeys(permit: Permit): Array<string> {
        let fileKeysUsed: Array<string> = new Array<string>();

        permit.documents?.forEach((doc) => {
            fileKeysUsed.push(...doc.fileKeys);
            doc.signatures?.forEach((auth) => auth.signatureFileKey && fileKeysUsed.push(auth.signatureFileKey));
        });

        permit.permitGangMembers?.forEach((member) =>
            member.signatures?.forEach((auth) => auth.signatureFileKey && fileKeysUsed.push(auth.signatureFileKey))
        );

        fileKeysUsed.push(...(permit.sitePhotoFileKeys ?? []));

        permit.signatures?.forEach((auth) => auth.signatureFileKey && fileKeysUsed.push(auth.signatureFileKey));

        return fileKeysUsed;
    }

    loadPermitFile(permitId: string, fileKey: string): Promise<string> {
        const docId = `permit::${permitId}`;
        return cblPlugin.loadDocFile(docId, fileKey);
    }

    loadReferenceProcedureFile(referenceProcedureId: string, fileKey: string): Promise<string> {
        const docId = `referenceProcedure::${referenceProcedureId}`;
        return cblPlugin.loadDocFile(docId, fileKey);
    }

    queryGangMembers(clientId: string): Promise<GangMember[]> {
        return cblPlugin.queryDocs({ type: "gangMember", clientId: clientId });
    }

    queryReferenceProcedures(clientId: string): Promise<ReferenceProcedure[]> {
        return cblPlugin.queryDocs<ReferenceProcedure>({ type: "referenceProcedure", clientId: clientId });
    }

    queryReferenceProcedure(referenceProcedureId: string): Promise<ReferenceProcedure> {
        const docId = `referenceProcedure::${referenceProcedureId}`;
        return cblPlugin.queryDoc<ReferenceProcedure>(docId);
    }

    queryProjectUsers(projectId: string): Promise<User[]> {
        return new Promise((resolve, reject) => {
            cblPlugin
                .queryDocs<User>({ type: "user" })
                .then(
                    (result: User[]) => {
                        resolve(result.filter((u) => u.assignments.find((a) => a.project.id === projectId)));
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
        });
    }

    queryClientRoles(clientId: string): Promise<Role[]> {
        return new Promise((resolve, reject) => {
            cblPlugin
                .queryDocs<Role>({ type: "role" })
                .then(
                    (result: Role[]) => {
                        resolve(result.filter((r) => r.clientId === clientId));
                    },
                    (err: any) => {
                        reject(err);
                    }
                );
        });
    }

    queryUser(userId: string): Promise<User> {
        const docId = `user::${userId}`;
        return cblPlugin.queryDoc(docId);
    }

    setReplicatorStatusChangeEventHandler = (handler?: ReplicatorStatusChangeEventHandler) =>
        (cblPlugin.replicatorStatusChangeEventHandler = handler as any);
    setReplicationFailedEventHandler = (handler?: ReplicationFailedEventHandler) =>
        (cblPlugin.replicationFailedEventHandler = handler as any);
    setReplicationCompletedEventHandler = (handler?: ReplicationCompletedEventHandler) =>
        (cblPlugin.replicationCompletedEventHandler = handler as any);
}

const database = new NativeDatabase();

export default database;
