import React, { useState } from "react";
import { IonItem, IonLabel, IonCheckbox, IonIcon, IonToast } from "@ionic/react";
import CustomBox from "./CustomBox";
import CustomMultiColView from "./CustomMultiColView";
import CustomItem from "./CustomItem";
import CustomSignItem from "./CustomSignItem";
import { call, checkboxOutline, removeCircleOutline } from "ionicons/icons";

interface Props {
    title: string;
    declarations: string[];
    permitId: string;
    name: string;
    role?: string;
    telephoneNumber: string;
    dateSigned?: Date;
    signatureFileKey?: string;
    onSignatureSubmitted: (dataUrl: string) => void;
    isReadOnly: boolean;
}

const PermitAuthorisationBox: React.FC<Props> = ({
    title,
    declarations,
    permitId,
    name,
    role,
    telephoneNumber,
    dateSigned,
    signatureFileKey,
    onSignatureSubmitted,
    isReadOnly,
}) => {
    const [declarationCheckboxValues, setDeclarationCheckboxValues] = useState(declarations.map((d) => false));
    const [showError, setShowError] = useState(false);

    const handleSignatureValidation = (): boolean => {
        if (declarationCheckboxValues.includes(false)) {
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 5000);
            return false;
        }
        return true;
    };

    const handleDeclarationCheckboxChange = (index: number, value: boolean) => {
        const newState = [...declarationCheckboxValues];
        newState[index] = value;
        setDeclarationCheckboxValues(newState);
    };

    return (
        <>
            {name !== "" ? (
                <CustomBox title={title}>
                    {declarations?.map((d, index) => (
                        <IonItem lines="none" key={index}>
                            <IonLabel class="ion-text-wrap">{d}</IonLabel>
                            {dateSigned ? (
                                <IonIcon slot="start" icon={checkboxOutline} />
                            ) : (
                                <>
                                    {isReadOnly ? (
                                        <IonIcon slot="start" icon={removeCircleOutline} />
                                    ) : (
                                        <IonCheckbox
                                            slot="start"
                                            checked={declarationCheckboxValues[index]}
                                            onIonChange={(e) =>
                                                handleDeclarationCheckboxChange(index, e.detail.checked)
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </IonItem>
                    ))}
                    <CustomMultiColView>
                        <CustomItem
                            title="Name"
                            text={name}
                            tagIcon={call}
                            tag={telephoneNumber}
                            lines={role ? "full" : "none"}
                        ></CustomItem>
                        {role && <CustomItem title="Role" text={role}></CustomItem>}

                        <CustomSignItem
                            permitId={permitId}
                            title="Signature"
                            dateSigned={dateSigned}
                            signatureFileKey={signatureFileKey}
                            onSignatureSubmitted={onSignatureSubmitted}
                            onValidation={handleSignatureValidation}
                            isReadOnly={isReadOnly}
                            signatoryName={name}
                        />
                    </CustomMultiColView>
                </CustomBox>
            ) : (
                <CustomBox title={title} text="User not selected "></CustomBox>
            )}
            <IonToast
                isOpen={showError}
                message={`Please read and confirm ${
                    declarations.length === 1
                        ? "the declaration by clicking on the checkbox."
                        : "declarations by clicking on all the checkboxes."
                }`}
                position="top"
            />
        </>
    );
};

export default PermitAuthorisationBox;
