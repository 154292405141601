import React from "react";
import { IonLabel } from "@ionic/react";
import { GreenFlag, RedFlag } from "../PermitFlagService";

interface Props {
    text: string;
    flag?: string;
}

const CustomLabelWithFlag: React.FC<Props> = ({ text, flag }) => {
    return (
        <IonLabel>
            {flag === GreenFlag && <span style={{ color: "var(--ion-color-success)" }}>● </span>}
            {flag === RedFlag && <span style={{ color: "var(--ion-color-danger)" }}>● </span>}
            {text}
        </IonLabel>
    );
};

export default CustomLabelWithFlag;
