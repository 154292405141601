import React from "react";
import { IonItem, IonLabel, IonList, IonChip, IonCard } from "@ionic/react";

interface Props {
    client?: string;
    eightPointCheckListItems: string[];
}

const EightPointChecklist: React.FC<Props> = ({ client, eightPointCheckListItems }) => {
    const renderCheckListItem = (index: number, text: string, noLine: boolean = false) => {
        return (
            <IonItem lines={noLine ? "none" : "full"}>
                <IonChip slot="start" color="tertiary">
                    <IonLabel color="tertiary">{index}</IonLabel>
                </IonChip>
                <IonLabel class="ion-text-wrap">
                    <p>{text}</p>
                </IonLabel>
            </IonItem>
        );
    };

    return (
        <IonCard>
            <IonList lines="full">
                {eightPointCheckListItems.map((i, index) =>
                    renderCheckListItem(index + 1, i, index + 1 === eightPointCheckListItems.length ? true : false)
                )}
            </IonList>
        </IonCard>
    );
};

export default EightPointChecklist;
