import { Permit } from "../../models/Permit";
import { User } from "../../models/User";
import { RawFile } from "../../models/RawFile";
import { LocationLog } from "../../models/LocationLog";

// Constants
export const LOAD_PERMIT_STARTED = "LOAD_PERMIT_STARTED";
export const LOAD_PERMIT_SUCCESSFUL = "LOAD_PERMIT_SUCCESSFUL";
export const LOAD_PERMIT_FAILED = "LOAD_PERMIT_FAILED";
export const SAVE_PERMIT_STARTED = "SAVE_PERMIT_STARTED";
export const SAVE_PERMIT_SUCCESSFUL = "SAVE_PERMIT_SUCCESSFUL";
export const SAVE_PERMIT_FAILED = "SAVE_PERMIT_FAILED";
export const LOAD_PERMIT_SUBMITTER_SUCCESSFUL = "LOAD_PERMIT_SUBMITTER_SUCCESSFUL";
export const UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL = "UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL";
export const REPORT_PROBLEM = "REPORT_PROBLEM";
export const WORK_COMPLETED = "WORK_COMPLETED";

// State Type
export interface PermitState {
    loading: boolean;
    saving: boolean;
    permit: Permit | null;
    permitSubmitter: User | null;
    newFiles: RawFile[];
    permitGangMemberUpdateMessage: string;
}

// Action Types
interface LoadPermitStartedAction {
    type: typeof LOAD_PERMIT_STARTED;
}

interface LoadPermitSuccessfulAction {
    type: typeof LOAD_PERMIT_SUCCESSFUL;
    permit: Permit;
}

interface LoadPermitFailedAction {
    type: typeof LOAD_PERMIT_FAILED;
    error: Error;
}

interface SavePermitStartedAction {
    type: typeof SAVE_PERMIT_STARTED;
}

interface SavePermitSuccessfulAction {
    type: typeof SAVE_PERMIT_SUCCESSFUL;
    permit: Permit;
}

interface SavePermitFailedAction {
    type: typeof SAVE_PERMIT_FAILED;
    error: Error;
}

interface LoadPermitSubmitterSuccessfulAction {
    type: typeof LOAD_PERMIT_SUBMITTER_SUCCESSFUL;
    user: User;
}

interface updatePermitLastAccessSuccessfulAction {
    type: typeof UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL;
    locationLog: LocationLog;
    permitId: string;
}

interface reportProblemAction {
    type: typeof REPORT_PROBLEM;
    comments: string;
    loggedInUserId: string;
}

interface workCompletedAction {
    type: typeof WORK_COMPLETED;
    loggedInUserId: string;
}

export type PermitActionTypes =
    | LoadPermitStartedAction
    | LoadPermitSuccessfulAction
    | LoadPermitFailedAction
    | SavePermitStartedAction
    | SavePermitSuccessfulAction
    | SavePermitFailedAction
    | LoadPermitSubmitterSuccessfulAction
    | updatePermitLastAccessSuccessfulAction
    | reportProblemAction
    | workCompletedAction;
