/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import {
    configAuth,
    addPolygon,
    addPolyline,
    addSimpleMarker,
    createMapView,
    createWebMap,
    MapItem,
} from "../arcgisUtils";
import { loadModules, loadCss } from "esri-loader";

type Props = {
    webMapItemId: string | null | undefined;
    center?: __esri.Point;
    items: MapItem[];
    showLegend?: boolean;
    token?: string;
};

const Map = ({ webMapItemId, center, items, showLegend, token }: Props) => {
    const mapEl = useRef(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [graphicsLayer, setGraphicsLayer] = useState<__esri.GraphicsLayer | null>(null);
    const [view, setView] = useState<__esri.MapView | null>(null);

    useEffect(() => {
        loadCss();
        loadModules(["esri/config", "esri/identity/OAuthInfo", "esri/identity/IdentityManager"]).then(
            async ([config, OAuthInfo, esriId]) => {
                // config.apiKey =
                //  "AAPKe7d21445ba604a5488756d8cd0d232f4OzbQd3I0wGKfs8gXxfkdow9QldSlgBNDJlyOdMSWpz5iRabyH8rT3-MOgq3t2HHD";

                try {
                    await configAuth(OAuthInfo, esriId, token);
                    setLoggedIn(true);
                } catch {
                    setLoggedIn(false);
                }
            }
        );
    }, [token, mapEl]);

    useEffect(() => {
        loadCss();
        loadModules([
            "esri/WebMap",
            "esri/views/MapView",
            "esri/layers/GraphicsLayer",
            "esri/Graphic",
            "esri/PopupTemplate",
            "esri/geometry/Point",
            "esri/geometry/Polygon",
            "esri/symbols/SimpleMarkerSymbol",
            "esri/symbols/SimpleFillSymbol",
            "esri/symbols/SimpleLineSymbol",
            "esri/widgets/ScaleBar",
            "esri/widgets/Legend",
        ]).then(
            async ([
                WebMap,
                MapView,
                GraphicsLayer,
                Graphic,
                PopupTemplate,
                Point,
                Polygon,
                SimpleMarkerSymbol,
                SimpleFillSymbol,
                SimpleLineSymbol,
                ScaleBar,
                Legend,
            ]) => {
                if (!webMapItemId || !loggedIn) {
                    return;
                }

                const { webmap, graphicsLayer } = createWebMap(WebMap, GraphicsLayer, webMapItemId);
                setGraphicsLayer(graphicsLayer);

                var view = createMapView(
                    MapView,
                    ScaleBar,
                    Legend,
                    mapEl.current,
                    webmap,
                    showLegend ?? false,
                    graphicsLayer.graphics,
                    center
                );
                setView(view);

                items.forEach((item) => {
                    var geometry = item.geometry;

                    if (geometry.type === "point") {
                        addSimpleMarker(SimpleMarkerSymbol, Graphic, view, new Point(geometry));
                    } else if (geometry.type === "polygon") {
                        addPolygon(
                            SimpleFillSymbol,
                            Graphic,
                            PopupTemplate,
                            graphicsLayer,
                            new Polygon(geometry),
                            item.color,
                            item.popup
                        );
                    } else if (geometry.type === "polyline") {
                        addPolyline(
                            SimpleLineSymbol,
                            Graphic,
                            PopupTemplate,
                            graphicsLayer,
                            new Polygon(geometry),
                            item.color,
                            item.popup
                        );
                    }
                });
            }
        );

        return () => {
            // clean up the map view
            if (!!view) {
                graphicsLayer?.destroy();
                view.destroy();
                setView(null);
            }
        };
    }, [loggedIn, items]);

    return (
        <>
            <div className="webmap" ref={mapEl} style={{ height: "100%" }}></div>
        </>
    );
};

export default Map;
