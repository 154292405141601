import React from "react";
import "./PdfViewer.css";
import { Capacitor } from "@capacitor/core";
import { IonFab, IonFabButton, IonIcon, isPlatform } from "@ionic/react";
import { expandOutline } from "ionicons/icons";
import { DocumentViewer } from "@ionic-native/document-viewer";
import { FileOpener } from "@capacitor-community/file-opener";

const { MobilePDFReader } = require("react-read-pdf");

interface PdfViewerProps {
    path: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ path }) => {
    const url = Capacitor.convertFileSrc(path);
    const runOnDevice = isPlatform("hybrid");

    const handleExpandClick = () => {
        if (isPlatform("ios")) {
            DocumentViewer.viewDocument(path, "application/pdf", {});
        } else {
            FileOpener.open({
                filePath: path,
                contentType: "application/pdf",
            });
        }
    };

    return (
        <>
            <MobilePDFReader key={url} url={url} isShowHeader={false} />
            {runOnDevice && (
                <IonFab horizontal="end" vertical="bottom" style={{ marginBottom: 70 }} onClick={handleExpandClick}>
                    <IonFabButton>
                        <IonIcon icon={expandOutline} />
                    </IonFabButton>
                </IonFab>
            )}
        </>
    );
};

export default PdfViewer;
