import React, { useState } from "react";
import { Document } from "../models/PermitDocument";
import { IonSegment, IonSegmentButton, IonLabel, IonIcon } from "@ionic/react";
import { list, document } from "ionicons/icons";
import PermitDocFileTab from "./PermitDocFileTab";
import PermitDocAttributesTab from "./PermitDocAttributesTab";

interface PermitDocProps {
    doc: Document | undefined;
    permitId: string;
    isReadOnly: boolean;
}

const PermitDocTab: React.FC<PermitDocProps> = ({ doc, permitId, isReadOnly }) => {
    const [selectedTab, setSelectedTab] = useState<string | undefined>("doc-view");

    const handleTabChange = (event: CustomEvent<any>) => {
        setSelectedTab(event.detail.value || "");
    };

    const docId = doc?.id;
    React.useEffect(() => {
        setSelectedTab("doc-view");
    }, [docId]);

    return (
        <>
            {doc && doc.fileKeys && doc.fileKeys.length > 0 && selectedTab === "doc-view" && (
                <PermitDocFileTab permitId={permitId} fileKey={doc.fileKeys[0]} />
            )}
            {doc && doc.fileKeys && doc.fileKeys.length > 1 && selectedTab === "doc-view-2" && (
                <PermitDocFileTab permitId={permitId} fileKey={doc.fileKeys[1]} />
            )}
            {doc && doc.fileKeys && doc.fileKeys.length === 3 && selectedTab === "doc-view-3" && (
                <PermitDocFileTab permitId={permitId} fileKey={doc.fileKeys[2]} />
            )}
            {doc && selectedTab === "doc-attributes" && <PermitDocAttributesTab attributes={doc.attributes} />}

            <IonSegment value={selectedTab} onIonChange={handleTabChange}>
                <IonSegmentButton value="doc-view">
                    <IonIcon icon={document}></IonIcon>
                    <IonLabel>{(doc?.fileKeys?.length || 1) <= 1 ? "View" : "File 1"}</IonLabel>
                </IonSegmentButton>
                {doc && doc.fileKeys && doc.fileKeys.length >= 2 && (
                    <IonSegmentButton value="doc-view-2">
                        <IonIcon icon={document}></IonIcon>
                        <IonLabel>File 2</IonLabel>
                    </IonSegmentButton>
                )}
                {doc && doc.fileKeys && doc.fileKeys.length >= 3 && (
                    <IonSegmentButton value="doc-view-3">
                        <IonIcon icon={document}></IonIcon>
                        <IonLabel>File 3</IonLabel>
                    </IonSegmentButton>
                )}
                <IonSegmentButton value="doc-attributes">
                    <IonIcon icon={list}></IonIcon>
                    <IonLabel>Info</IonLabel>
                </IonSegmentButton>
            </IonSegment>
        </>
    );
};

export default PermitDocTab;
