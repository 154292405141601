import { User } from "../../models/User";
import { Permit } from "../../models/Permit";
import { CHANGE_PERMIT_ASSIGNEES_AFTER_ISSUE, ISSUED } from "../../constants";
import { PermitAssignment } from "../../models/PermitAssignment";
import { Role } from "../../models/Role";
import PermissionService from "../../PermissionService";

function availableUsers(projectId: string, projectUsers: User[], roleId: string) {
    return projectUsers.filter((u) => u.assignments.find((a) => a.project.id === projectId && a.roleId === roleId));
}

function getChangePermitAssigneesAvailability(permit: Permit, currentUser: User | null, clientRoles: Role[]) {
    if (permit.status !== ISSUED) return false;

    if (currentUser)
        return PermissionService.userHasPermission(
            currentUser,
            CHANGE_PERMIT_ASSIGNEES_AFTER_ISSUE,
            clientRoles,
            permit.project.id
        );

    // if (currentUser && permitIssuedBy)
    //     if (currentUser.id === permit.requestedById || currentUser.id === permitIssuedBy) return true;

    return false;
}

function hasPermitAssigneeSigned(assignment: PermitAssignment, permit: Permit) {
    if (permit.signatures?.filter((s) => s.roleId === assignment.roleId).length > 0) return true;

    return false;
}

export const service = {
    availableUsers: availableUsers,
    hasPermitAssigneeSigned: hasPermitAssigneeSigned,
    getChangePermitAssigneesAvailability: getChangePermitAssigneesAvailability,
};
