import { IonSelect, IonSelectOption, IonHeader, IonToolbar, IonButton, IonIcon } from "@ionic/react";
import React, { useState } from "react";
import { optionsOutline } from "ionicons/icons";
import MyPermitsFilterModal from "./MyPermitsFilterModal";
import { Project } from "../models/Project";
import find from "lodash/find";

interface Props {
    showExpired: boolean;
    onShowExpiredChange: (value: boolean) => void;
    projects: Project[];
    selectedProject: Project | null;
    onSelectedProjectChange: (project: Project | null) => void;
}

const MyPermitsHeader: React.FC<Props> = ({
    showExpired,
    onShowExpiredChange,
    selectedProject,
    projects,
    onSelectedProjectChange,
}) => {
    const [showFilterModal, setShowFilterModal] = useState(false);

    const handleClickFilterButton = () => {
        setShowFilterModal(true);
    };

    const handleCloseFilterModal = () => {
        setShowFilterModal(false);
    };

    const onSelectedProjectChangeHandler = (arg: any) => {
        if (arg && arg.detail && arg.detail.value) {
            const project = find(projects, (p) => p.id === arg.detail.value) || null;
            if (project) {
                onSelectedProjectChange(project);
            }
        }
    };

    const selectedProjectId = selectedProject ? selectedProject.id : undefined;
    return (
        <>
            <IonHeader>
                <IonToolbar color="primary" style={{ padding: 0 }}>
                    <IonSelect
                        value={selectedProjectId}
                        okText="Select"
                        cancelText="Cancel"
                        onIonChange={onSelectedProjectChangeHandler}
                    >
                        {projects.map((project) => (
                            <IonSelectOption key={project.id} value={project.id}>
                                {project.name}
                            </IonSelectOption>
                        ))}
                    </IonSelect>
                    <IonButton fill="solid" slot="end" onClick={handleClickFilterButton}>
                        <IonIcon icon={optionsOutline} slot="icon-only" />
                    </IonButton>
                </IonToolbar>
            </IonHeader>

            <MyPermitsFilterModal
                showExpired={showExpired}
                onShowExpiredChange={onShowExpiredChange}
                isOpen={showFilterModal}
                onClose={handleCloseFilterModal}
            />
        </>
    );
};

export default MyPermitsHeader;
