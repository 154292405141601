import React from "react";
import { IonContent, IonItem, IonLabel, IonBackdrop } from "@ionic/react";
import PermitAssignmentSignature from "./PermitAssignmentSignature";
import { User } from "../models/User";
import { PermitAssignment } from "../models/PermitAssignment";
import { PermitAuthorisationSignature } from "../models/PermitAuthorisationSignature";
import { Role } from "../models/Role";

interface Props {
    permitId: string;
    assigneesWhoShouldSign: PermitAssignment[] | undefined;
    signatures: PermitAuthorisationSignature[];
    clientRoles: Role[];
    projectUsers: User[];
    onPermitAssignmentSignatureSubmitted: (signature: PermitAuthorisationSignature, signatureDataUrl: string) => void;
    currentUser: User;
    abilityToSign: boolean;
    isReadOnly: boolean;
}

const SignaturesTab: React.FC<Props> = ({
    permitId,
    assigneesWhoShouldSign,
    signatures,
    clientRoles,
    projectUsers,
    onPermitAssignmentSignatureSubmitted,
    currentUser,
    abilityToSign,
    isReadOnly,
}) => {
    const renderAssignment = (assignment: PermitAssignment) => {
        const assignmentSignatures = signatures.filter((s) => s.roleId === assignment.roleId);

        return (
            <>
                {assignmentSignatures.length > 0
                    ? assignmentSignatures.map((s) => renderSignature(assignment, s))
                    : renderSignature(assignment)}
            </>
        );
    };

    const renderSignature = (assignment: PermitAssignment, signature?: PermitAuthorisationSignature) => {
        return (
            <PermitAssignmentSignature
                permitId={permitId}
                assignment={assignment}
                signature={signature}
                role={clientRoles.find((r) => r.id === assignment.roleId)}
                user={projectUsers.find((u) => u.id === assignment.userId)}
                onPermitAssignmentSignatureSubmitted={onPermitAssignmentSignatureSubmitted}
                currentUser={currentUser}
                isReadOnly={isReadOnly}
            />
        );
    };

    return (
        <>
            {!abilityToSign && (
                <IonItem color="warning">
                    <IonLabel class="ion-text-wrap">
                        Please complete previous tabs first before authorising the permit.
                    </IonLabel>
                </IonItem>
            )}
            <IonContent style={!abilityToSign ? { "--overflow": "hidden" } : {}}>
                {!abilityToSign && <IonBackdrop visible={true} style={{ opacity: "0.3" }} stopPropagation={true} />}

                {assigneesWhoShouldSign?.map((a) => renderAssignment(a))}
            </IonContent>
        </>
    );
};

export default SignaturesTab;
