import React, { useState } from "react";
import {
    IonContent,
    IonImg,
    IonItem,
    IonInput,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonRouterLink,
    IonList,
} from "@ionic/react";

interface Props {
    onSubmit: (username: string, password: string) => void;
}

const Login: React.FC<Props> = ({ onSubmit }) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const mainServerUrl = process.env.REACT_APP_MAIN_SERVER_URL;

    return (
        <>
            <IonContent>
                <IonGrid style={{ marginTop: "10%" }}>
                    <IonRow
                        justify-content-center
                        align-items-center
                        style={{ height: "100%", flexDirection: "column" }}
                    >
                        <IonCol size="12" sizeMd="6" offsetMd="3">
                            <IonImg src="./assets/LogoV.png" alt="logo" style={{ height: "60pt", margin: "50px" }} />
                            <IonCard>
                                <IonItem lines="full">
                                    <IonInput
                                        name="userName"
                                        type="text"
                                        value={username}
                                        onIonChange={(e) => setUserName(e.target.value as string)}
                                        placeholder="Username"
                                        mode="md"
                                    ></IonInput>
                                </IonItem>
                                <IonItem lines="none">
                                    <IonInput
                                        name="password"
                                        type="password"
                                        value={password}
                                        onIonChange={(e) => setPassword(e.target.value as string)}
                                        onKeyUp={(e) => {
                                            if (e.code === "Enter") onSubmit(username, password);
                                        }}
                                        placeholder="Password"
                                    ></IonInput>
                                </IonItem>
                            </IonCard>
                            <IonList>
                                <IonButton
                                    onClick={(event: any) => onSubmit(username, password)}
                                    expand="block"
                                    size="large"
                                >
                                    Login
                                </IonButton>

                                <IonRouterLink
                                    href={mainServerUrl + "/Identity/Account/ForgotPassword"}
                                    style={{
                                        marginTop: "25px",
                                        marginLeft: "10px",
                                    }}
                                >
                                    Forgot password?
                                </IonRouterLink>
                            </IonList>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </>
    );
};

export default Login;
