import { AppThunk } from "../../store/AppThunkType";
import {
    loadMyPermitsSuccessful,
    loadMyPermitsStarted,
    loadMyPermitsFailed,
    loadClientRolesStarted,
    loadClientRolesSuccessful,
    loadReferenceProceduresSuccessful,
    logOut,
    loadSyncedUserSuccessful,
} from "./actions";
import Database from "../../Database";
import { READY_TO_ISSUE, VIEW_ISSUED_PERMITS, VIEW_READY_TO_ISSUE_PERMITS } from "../../constants";
import database_Local from "../../Database_Local";
import { AppState } from "../../store";
import { setCurrentProfile, setCurrentUser } from "../LoginContainer/actions";
import { UserProfile } from "../../models/UserProfile";
import { User } from "../../models/User";
import { getSyncGatewayDetails } from "../LoginContainer/thunks";
import database from "../../Database";

export function loadMyPermits(): AppThunk {
    return function (dispatch, getState: () => AppState) {
        dispatch(loadMyPermitsStarted());

        Database.queryPermits()
            .then((result) => {
                const permitItems = result;
                const currentUser = getState().user.currentUser;
                if (!currentUser) {
                    dispatch(loadMyPermitsSuccessful([]));
                    return;
                }
                const clientRoles = getState().myPermits.clientRoles;

                const projectsAccess = currentUser.assignments.map((a) => {
                    return {
                        projectId: a.project.id,
                        roleId: a.roleId,
                    };
                });

                let projectAccessMapping: { [key: string]: string[] | undefined } = {};
                for (let i = 0; i < projectsAccess.length; i++) {
                    projectAccessMapping[projectsAccess[i].projectId] = clientRoles.find(
                        (r) => r.id === projectsAccess[i].roleId
                    )?.permissionKeys;
                }

                const availablePermitItems = permitItems.filter((item) => {
                    const permissionKeys = projectAccessMapping[item.project.id];
                    if (!permissionKeys)
                        // User doesn't have access to the item's project
                        return false;

                    // Users with "PERMIT_ISSUER access" have access to all permits, no matter the status
                    //if (permissionKeys.includes("IssuePermit")) return true;

                    // Users with "View only/Permit receivers" don't have access to "Ready to issue" permits
                    if (
                        !!item.permitAssignments?.find((a) => a.userId === currentUser.id) ||
                        (permissionKeys.includes(VIEW_READY_TO_ISSUE_PERMITS) && item.status === READY_TO_ISSUE) ||
                        (permissionKeys.includes(VIEW_ISSUED_PERMITS) && item.status !== READY_TO_ISSUE)
                    )
                        return true;

                    return false;
                });
                dispatch(loadMyPermitsSuccessful(availablePermitItems));
            })
            .catch((error) => {
                dispatch(loadMyPermitsFailed(error));
                database_Local.deleteAll();
                dispatch(logOut());
            });
    };
}

export function loadClientRoles(clientId: string): AppThunk {
    return function (dispatch) {
        dispatch(loadClientRolesStarted());

        Database.queryClientRoles(clientId).then((result) => {
            dispatch(loadClientRolesSuccessful(result));
        });
    };
}

export function loadReferenceProcedures(clientId: string): AppThunk {
    return function (dispatch) {
        Database.queryReferenceProcedures(clientId).then((result) => {
            dispatch(loadReferenceProceduresSuccessful(result));
        });
    };
}

export function loadSyncedUser(userId: string): AppThunk {
    return function (dispatch, getState: () => AppState) {
        Database.queryUser(userId).then((result) => {
            const currentUser = getState().user.currentUser;
            const currentProfile = getState().user.currentProfile;

            const isSyncedUserInCurrentProfile = currentProfile?.clientUsers.find((u) => u.id === result.id);
            if (isSyncedUserInCurrentProfile) {
                const newProfile =
                    currentProfile &&
                    ({
                        ...currentProfile,
                        clientUsers: currentProfile.clientUsers.map((u) => (u.id === result.id ? result : u)),
                    } as UserProfile);
                database_Local.save("userProfile", newProfile);
                newProfile && dispatch(setCurrentProfile(newProfile));
            }

            const syncedUserIsCurrentUser = currentUser?.id === result.id;
            if (syncedUserIsCurrentUser) {
                database_Local.save("currentUser", result);
                dispatch(setCurrentUser(result));
            }

            dispatch(loadSyncedUserSuccessful(result));
        });
    };
}

export function tryToConnect(userProfile: UserProfile): AppThunk {
    return function () {
        const { syncGatewayAddress, syncGatewayDatabase, isSsl } = getSyncGatewayDetails();

        database
            .openDatabase(
                syncGatewayAddress,
                isSsl,
                syncGatewayDatabase,
                userProfile.email,
                userProfile.syncGatewaySession
            )
            .then(() => {
                console.log("successfully connected");
            })
            .catch((error: any) => {
                console.log(error);
            });
    };
}
