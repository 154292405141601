import React, { useEffect, useState } from "react";
import {
    IonLabel,
    IonIcon,
    IonImg,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonModal,
    IonContent,
} from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import { Capacitor } from "@capacitor/core";
import database from "../Database";

// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface Props {
    sitePhotoFileKeys: string[] | [];
    onSitePhotoDeleted: (fileKey: string) => void;
    isReadOnly: boolean;
    permitId: string;
}
interface FileInfo {
    fileKey: string;
    filePath: String;
}

const SitePhotosList: React.FC<Props> = ({ sitePhotoFileKeys, permitId, onSitePhotoDeleted, isReadOnly }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedPhotoPath, setSelectedPhotoPath] = useState("");
    const [fileInfos, setFileInfos] = useState([] as Array<FileInfo>);

    useEffect(() => {
        const files: Array<FileInfo> = [];
        const filePromises: Array<Promise<void>> = [];
        sitePhotoFileKeys.forEach((photo) => {
            filePromises.push(
                database.loadPermitFile(permitId, photo).then(
                    (result: any) => {
                        if (result) {
                            files.push({
                                fileKey: photo,
                                filePath: Capacitor.convertFileSrc(result),
                            });
                        }
                    },
                    (err) => {
                        //setFilePath('');
                    }
                )
            );
        });
        Promise.all(filePromises).then(() => {
            setFileInfos(files);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sitePhotoFileKeys]);

    const handlePhotoClick = (filePath: string) => {
        setSelectedPhotoPath(filePath);
        setShowModal(true);
    };

    const renderPhotos = (fileInfo: FileInfo) => {
        return (
            <IonCol size="4" sizeMd="2" key={fileInfo.fileKey}>
                <IonGrid style={{ "--ion-grid-padding": 0 }}>
                    <IonRow>
                        <IonCol size="9" style={{ "--ion-grid-column-padding": 0 }}>
                            <div
                                style={{
                                    margin: 0,
                                    padding: "5px",
                                    backgroundColor: "var(--ion-color-light)",
                                }}
                                onClick={() => handlePhotoClick(fileInfo.filePath.toString())}
                            >
                                <IonImg
                                    src={fileInfo.filePath.toString()}
                                    style={{
                                        height: "50pt",
                                        width: "fit-content",
                                    }}
                                />
                            </div>
                        </IonCol>
                        <IonCol size="3">
                            {!isReadOnly && (
                                <IonButton
                                    style={{
                                        "--padding-start": 0,
                                        "--padding-end": 0,
                                        marginTop: 0,
                                    }}
                                    color="danger"
                                    size="small"
                                    fill="clear"
                                    onClick={() => onSitePhotoDeleted(fileInfo.fileKey)}
                                >
                                    <IonIcon slot="icon-only" icon={closeCircleOutline} />
                                </IonButton>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCol>
        );
    };

    return (
        <>
            <IonModal isOpen={showModal}>
                <IonContent>
                    <IonCard>
                        <img alt="SitePhoto" src={selectedPhotoPath} />
                    </IonCard>
                </IonContent>
                <IonButton onClick={() => setShowModal(false)}>Close</IonButton>
            </IonModal>

            <IonGrid style={{ "--ion-grid-padding": "11px" }}>
                <IonRow class="ion-justify-content-start">{fileInfos.map(renderPhotos)}</IonRow>
            </IonGrid>

            {isReadOnly && fileInfos.length === 0 && (
                <p>
                    <IonLabel style={{ marginLeft: "16px" }} class="ion-text-wrap">
                        There are no photos to display{" "}
                    </IonLabel>
                </p>
            )}
        </>
    );
};

export default SitePhotosList;
