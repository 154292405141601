import { request } from "./HttpService";
import { SGWCallResponse } from "./models/SGWCallResponse";
import { SyncGatewaySession } from "./models/UserProfile";

class SyncGatewayApiClient {
    private isSsl: boolean = false;
    private syncGatewayAddress: string = "";
    private syncGatewayDatabase: string = "";
    private syncGatewaySession: SyncGatewaySession | undefined;

    private getBaseUrl = () => `http${this.isSsl ? "s" : ""}://${this.syncGatewayAddress}/${this.syncGatewayDatabase}`;

    private getRequestHeader() {
        return {
            "Session-Id": this.syncGatewaySession?.session_id,
        };
    }

    private syncGatewayFetch<T>(url: string): Promise<T> {
        return request<T>("GET", url, this.getRequestHeader())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    private syncGatewayFetchList<T>(url: string): Promise<T[]> {
        return request<SGWCallResponse<T>>("GET", url, this.getRequestHeader())
            .then((result) => {
                return result.data.rows.map(({ doc }) => doc);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    closeDatabase(): Promise<void> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log("Closing Database");
                resolve();
            });
        });
    }

    openDatabase(
        syncGatewayAddress: string,
        isSsl: boolean,
        syncGatewayDatabase: string,
        session: SyncGatewaySession
    ): Promise<void> {
        this.isSsl = isSsl;
        this.syncGatewayAddress = syncGatewayAddress;
        this.syncGatewayDatabase = syncGatewayDatabase;
        this.syncGatewaySession = session;

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log("Opening Database");
                resolve();
            });
        });
    }

    queryDoc<T>(docId: string): Promise<T> {
        const url = `${this.getBaseUrl()}/${docId}?attachments=false&revs=false&show_exp=false`;
        return this.syncGatewayFetch<T>(url)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    queryDocs<T>(startkey: string, endkey: string): Promise<T[]> {
        const url = `${this.getBaseUrl()}/_all_docs?include_docs=true&startkey=${startkey}&endkey=${endkey}`;
        return this.syncGatewayFetchList<T>(url)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    loadDocFile(docId: string, fileKey: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const url = `${this.getBaseUrl()}/${docId}/blob_%2F${fileKey}`;

            request("GET", url, this.getRequestHeader(), "blob").then((response) => {
                var reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onload = function () {
                    var imageDataUrl = reader.result;
                    resolve(imageDataUrl as string);
                };
            });
        });
    }

    queryDocDirectly<T>(
        docId: string,
        syncGatewayAddress: string,
        syncGatewayDatabase: string,
        isSsl: boolean,
        syncGatewaySession: SyncGatewaySession
    ): Promise<T> {
        const baseUrl = `http${isSsl ? "s" : ""}://${syncGatewayAddress}/${syncGatewayDatabase}`;

        const url = `${baseUrl}/${docId}?attachments=false&revs=false&show_exp=false`;

        const requestHeader = {
            "Session-Id": syncGatewaySession.session_id,
        };

        return request<T>("GET", url, requestHeader)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }
}

const syncGatewayApiClient = new SyncGatewayApiClient();
export default syncGatewayApiClient;
