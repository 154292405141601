import { IonHeader, IonToolbar, IonButtons, IonButton, IonModal, IonTitle, IonContent } from "@ionic/react";
import React, { useState } from "react";
import LocationService from "../LocationService";
import LocationViewer from "./LocationViewer";

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

const ShowCurrentLocationModal: React.FC<Props> = ({ isOpen, onClose }) => {
    const [lat, setLat] = useState(0);
    const [lon, setLon] = useState(0);

    LocationService.getCurrentPosition().then((position) => {
        setLat(position.coords.latitude);
        setLon(position.coords.longitude);
    });

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Current Location</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={onClose} strong>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent class="outer-content">
                <LocationViewer longitude={lon} latitude={lat} />
            </IonContent>
        </IonModal>
    );
};

export default ShowCurrentLocationModal;
