import React from "react";
import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonButton, IonIcon } from "@ionic/react";
import ReferenceProcedureFile from "../components/ReferenceProcedureFile";
import { useParams, useHistory } from "react-router";
import { arrowBack } from "ionicons/icons";

const ReferenceProcedurePage: React.FC = () => {
    const { procedure } = useParams<{ procedure: string }>();
    const decodedProcedure = decodeURI(procedure || "");
    let history = useHistory();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        {/* <IonBackButton defaultHref="/home" /> */}
                        <IonButton
                            onClick={() => {
                                history.push(`/home`);
                            }}
                        >
                            <IonIcon icon={arrowBack}></IonIcon>
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{decodedProcedure}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <ReferenceProcedureFile />
        </IonPage>
    );
};

export default ReferenceProcedurePage;
