import { AppThunk } from "../../store/AppThunkType";
import { loadProjectUsersSuccessful, loadProjectUsersStarted } from "./actions";
import Database from "../../Database";

export function loadProjectUsers(projectId: string): AppThunk {
    return function (dispatch) {
        dispatch(loadProjectUsersStarted());

        Database.queryProjectUsers(projectId).then((result) => {
            dispatch(loadProjectUsersSuccessful(result));
        });
    };
}
