import {
    MyPermitsActionTypes,
    LOAD_MY_PERMITS_STARTED,
    SELECT_PERMIT,
    LOAD_MY_PERMITS_SUCCESSFUL,
    LOAD_MY_PERMITS_FAILED,
    LOAD_CLIENT_ROLES_STARTED,
    LOAD_CLIENT_ROLES_SUCCESSFUL,
    LOAD_CLIENT_ROLES_FAILED,
    LOAD_REFERENCE_PROCEDURES_SUCCESSFUL,
    SET_SHOW_EXPIRED_PERMITS_FLAG,
    SET_SELECTED_PROJECT,
    LOGOUT,
    LOAD_SYNCED_USER_SUCCESSFUL,
    SET_SYNC_STATUS,
} from "./types";
import { Project } from "../../models/Project";
import { ReferenceProcedure } from "../../models/ReferenceProcedure";
import { Permit } from "../../models/Permit";
import { Role } from "../../models/Role";
import { User } from "../../models/User";

export function setSyncStatus(newStatus: string): MyPermitsActionTypes {
    return {
        type: SET_SYNC_STATUS,
        newStatus,
    };
}

export function loadMyPermitsStarted(): MyPermitsActionTypes {
    return {
        type: LOAD_MY_PERMITS_STARTED,
    };
}

export function loadMyPermitsSuccessful(permits: Permit[]): MyPermitsActionTypes {
    return {
        type: LOAD_MY_PERMITS_SUCCESSFUL,
        permits,
    };
}

export function loadMyPermitsFailed(error: Error): MyPermitsActionTypes {
    return {
        type: LOAD_MY_PERMITS_FAILED,
        error,
    };
}

export function loadClientRolesStarted(): MyPermitsActionTypes {
    return {
        type: LOAD_CLIENT_ROLES_STARTED,
    };
}

export function loadClientRolesSuccessful(clientRoles: Role[]): MyPermitsActionTypes {
    return {
        type: LOAD_CLIENT_ROLES_SUCCESSFUL,
        clientRoles,
    };
}

export function loadClientRolesFailed(error: Error): MyPermitsActionTypes {
    return {
        type: LOAD_CLIENT_ROLES_FAILED,
        error,
    };
}

export function loadReferenceProceduresSuccessful(referenceProcedures: ReferenceProcedure[]): MyPermitsActionTypes {
    return {
        type: LOAD_REFERENCE_PROCEDURES_SUCCESSFUL,
        referenceProcedures,
    };
}

export function selectPermit(permitId: string): MyPermitsActionTypes {
    return {
        type: SELECT_PERMIT,
        permitId,
    };
}

export function setShowExpiredPermitsFlag(value: boolean): MyPermitsActionTypes {
    return {
        type: SET_SHOW_EXPIRED_PERMITS_FLAG,
        value,
    };
}

export function setSelectedProject(value: Project | null): MyPermitsActionTypes {
    return {
        type: SET_SELECTED_PROJECT,
        value,
    };
}

export function logOut(): MyPermitsActionTypes {
    return {
        type: LOGOUT,
    };
}

export function loadSyncedUserSuccessful(user: User): MyPermitsActionTypes {
    return {
        type: LOAD_SYNCED_USER_SUCCESSFUL,
        user,
    };
}
