import { Geolocation, Position } from "@capacitor/geolocation";
import { LocationLog } from "./models/LocationLog";

const getCurrentPosition = async (): Promise<Position> => {
    const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
    });
    return coordinates;
};

const getLocationLog = async (userId: string): Promise<LocationLog> => {
    return getCurrentPosition().then((geolocationPosition) => {
        return createLastAccess(userId, geolocationPosition.coords.latitude, geolocationPosition.coords.longitude);
    });
};

const createLastAccess = (userId: string, latitude: number, longitude: number): LocationLog => {
    const lastAccess: LocationLog = {
        pendingSync: true,
        userId: userId,
        date: new Date(),
        latitude,
        longitude,
    };
    return lastAccess;
};

const locationService = {
    getCurrentPosition,
    getLocationLog,
    createLastAccess,
};

export default locationService;
