import {
    PermitAssignmentsState,
    PermitAssignmentsActionTypes,
    LOAD_PROJECT_USERS_SUCCESSFUL,
    LOAD_PROJECT_USERS_STARTED,
    LOAD_PROJECT_USERS_FAILED,
} from "./types";

const initialState: PermitAssignmentsState = {
    loading: false,
    projectUsers: [],
};

const reducer = (state = initialState, action: PermitAssignmentsActionTypes) => {
    switch (action.type) {
        case LOAD_PROJECT_USERS_STARTED:
            return {
                ...state,
                loading: true,
            };
        case LOAD_PROJECT_USERS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                projectUsers: action.projectUsers,
            };
        case LOAD_PROJECT_USERS_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

export default reducer;
