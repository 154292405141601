import {
    PermitState,
    PermitActionTypes,
    LOAD_PERMIT_SUCCESSFUL,
    LOAD_PERMIT_STARTED,
    LOAD_PERMIT_FAILED,
    SAVE_PERMIT_SUCCESSFUL,
    SAVE_PERMIT_STARTED,
    SAVE_PERMIT_FAILED,
    LOAD_PERMIT_SUBMITTER_SUCCESSFUL,
    UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL,
    REPORT_PROBLEM,
    WORK_COMPLETED,
} from "./types";
import { Permit } from "../../models/Permit";
import { EXPIRED, IN_PROGRESS } from "../../constants";

const initialState: PermitState = {
    loading: false,
    saving: false,
    permit: null,
    permitSubmitter: null,
    newFiles: [],
    permitGangMemberUpdateMessage: "",
};

const reducer = (state = initialState, action: PermitActionTypes) => {
    switch (action.type) {
        case LOAD_PERMIT_STARTED:
            return {
                ...state,
                loading: true,
            };
        case LOAD_PERMIT_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                permit: action.permit,
            };
        case LOAD_PERMIT_FAILED:
            return {
                ...state,
                loading: false,
            };
        case SAVE_PERMIT_STARTED:
            return {
                ...state,
                saving: true,
            };
        case SAVE_PERMIT_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                permit: {
                    ...action.permit,
                    pendingSave: false,
                } as Permit,
                newFiles: [],
            };
        case SAVE_PERMIT_FAILED:
            return {
                ...state,
                saving: false,
            };
        case LOAD_PERMIT_SUBMITTER_SUCCESSFUL:
            return {
                ...state,
                permitSubmitter: action.user,
            };
        case UPDATE_PERMIT_LAST_ACCESS_SUCCESSFUL:
            if (!state.permit || state.permit.id !== action.permitId) {
                return state;
            }
            return {
                ...state,
                permit: {
                    ...state.permit,
                    lastAccess: action.locationLog,
                } as Permit,
            };
        case REPORT_PROBLEM:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    status: IN_PROGRESS,
                    pendingStatusLoggedInUserId: action.loggedInUserId,
                    reportProblemComments: action.comments,
                    pendingStatusSync: true,
                    pendingSave: true,
                } as Permit,
            };
        case WORK_COMPLETED:
            return {
                ...state,
                permit: {
                    ...state.permit,
                    status: EXPIRED,
                    pendingStatusLoggedInUserId: action.loggedInUserId,
                    pendingStatusSync: true,
                    pendingSave: true,
                } as Permit,
            };
        default:
            return state;
    }
};

export default reducer;
