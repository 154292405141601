import { IonIcon, IonLabel, IonList, IonItem, IonListHeader } from "@ionic/react";
import { person, logOut, swapHorizontal } from "ionicons/icons";
import React from "react";

interface HomeMenuProps {
    userName: string;
    onLogOut: () => void;
    onSwitchAccount: () => void;
}

const HomeMenu: React.FC<HomeMenuProps> = ({ userName, onLogOut, onSwitchAccount }) => {
    return (
        <IonList>
            <IonListHeader>
                <IonLabel color={"primary"} position={"fixed"}>
                    {userName}
                </IonLabel>
            </IonListHeader>
            <IonItem>
                <IonLabel>My Account</IonLabel>
                <IonIcon icon={person} slot="end" />
            </IonItem>
            <IonItem button onClick={onSwitchAccount}>
                Switch account
                <IonIcon icon={swapHorizontal} slot="end" />
            </IonItem>
            <IonItem button lines="none" onClick={onLogOut}>
                Log out
                <IonIcon icon={logOut} slot="end" />
            </IonItem>
        </IonList>
    );
};

export default HomeMenu;
