const Database_Local = {
    save(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    load<T>(key: string): T | null {
        const value = localStorage.getItem(key);
        if (value) {
            return JSON.parse(value) as T;
        }
        return null;
    },
    deleteAll() {
        localStorage.clear();
    },
};

export default Database_Local;
