import React from "react";
import SignaturesTab from "../../components/SignatureTab";
import { Permit } from "../../models/Permit";
import { UserProfile } from "../../models/UserProfile";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { submitPermitAuthorisation } from "./actions";
import { User } from "../../models/User";
import { Guid } from "guid-typescript";
import PermitFlagService, { GreenFlag } from "../../PermitFlagService";
import { RawFile } from "../../models/RawFile";
import { PermitAuthorisationSignature } from "../../models/PermitAuthorisationSignature";
import { Role } from "../../models/Role";
import PermissionService from "../../PermissionService";
import { ISSUE_PERMIT } from "../../constants";

interface SignaturesTabContainerProps {
    permit: Permit | null;
    clientRoles: Role[];
    userProfile: UserProfile | null;
    currentUser: User | null;
    projectUsers: User[];
    submitPermitAuthorisation: typeof submitPermitAuthorisation;
    isReadOnly: boolean;
}

const SignaturesTabContainer: React.FC<SignaturesTabContainerProps> = ({
    permit,
    clientRoles,
    currentUser,
    projectUsers,
    submitPermitAuthorisation,
    isReadOnly,
}) => {
    const generateFileKey = (): string => {
        return "file-" + Guid.create().toString();
    };

    const handlePermitAssignmentSignatureSubmitted = async (
        signature: PermitAuthorisationSignature,
        signatureDataUrl: string
    ) => {
        const fileKey = generateFileKey();

        currentUser &&
            submitPermitAuthorisation(
                { ...signature, dateSigned: new Date(), signatureFileKey: fileKey } as PermitAuthorisationSignature,
                { fileName: fileKey, contentType: "image/png", dataUrl: signatureDataUrl } as RawFile,
                clientRoles,
                currentUser.id
            );
    };

    const assigneesWhoShouldSign = permit?.permitAssignments?.filter(
        (a) => clientRoles.find((c) => c.id === a.roleId)?.permitRoleSettings.shouldAuthorizePermit
    );

    if (!permit || !currentUser) return null;
    const ableToIssuePermit = PermissionService.userHasPermission(
        currentUser,
        ISSUE_PERMIT,
        clientRoles,
        permit.project.id
    );

    return (
        <SignaturesTab
            permitId={permit.id}
            assigneesWhoShouldSign={assigneesWhoShouldSign}
            signatures={permit.signatures}
            clientRoles={clientRoles}
            projectUsers={projectUsers}
            onPermitAssignmentSignatureSubmitted={handlePermitAssignmentSignatureSubmitted}
            currentUser={currentUser}
            abilityToSign={PermitFlagService.getPermitFlagForAuthorisation(permit) === GreenFlag ? true : false}
            isReadOnly={!ableToIssuePermit}
        />
    );
};

function mapStateToProps(state: AppState) {
    return {
        permit: state.permitPage.permit,
        userProfile: state.user.currentProfile,
        currentUser: state.user.currentUser,
        clientRoles: state.myPermits.clientRoles,
        projectUsers: state.permitAssignments.projectUsers,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        submitPermitAuthorisation: (
            authorisation: PermitAuthorisationSignature,
            signatureFile: RawFile,
            clientRoles: Role[],
            loggedInUserId: string
        ) => dispatch(submitPermitAuthorisation(authorisation, signatureFile, clientRoles, loggedInUserId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignaturesTabContainer);
