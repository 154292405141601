import { IonPopover, IonList, IonItem, IonItemGroup, IonButton, IonAlert } from "@ionic/react";
import React, { useState } from "react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onReportProblem?: () => void;
    onShowCurrentLocation: () => void;
    onWorkCompleted?: () => void;
}

const PermitMenu: React.FC<Props> = ({ isOpen, onClose, onReportProblem, onShowCurrentLocation, onWorkCompleted }) => {
    const [showWorkCompletedAlert, setshowWorkCompletedAlert] = useState(false);

    return (
        <IonPopover translucent={true} isOpen={isOpen} onDidDismiss={onClose}>
            <IonList lines="none">
                <IonItemGroup>
                    <IonItem>
                        <IonButton fill="clear" onClick={onShowCurrentLocation}>
                            Show current location
                        </IonButton>
                    </IonItem>
                </IonItemGroup>
                {onReportProblem && (
                    <IonItemGroup>
                        <IonItem>
                            <IonButton fill="clear" onClick={onReportProblem}>
                                Report Problem
                            </IonButton>
                        </IonItem>
                    </IonItemGroup>
                )}
                {onWorkCompleted && (
                    <IonItemGroup>
                        <IonItem>
                            <IonButton
                                id="work-completed"
                                fill="clear"
                                onClick={() => {
                                    setshowWorkCompletedAlert(true);
                                }}
                            >
                                Mark as work completed
                            </IonButton>
                            <IonAlert
                                isOpen={showWorkCompletedAlert}
                                header="Are you sure ?"
                                buttons={[
                                    {
                                        text: "Cancel",
                                        role: "cancel",
                                    },
                                    {
                                        text: "OK",
                                        role: "confirm",
                                        handler: onWorkCompleted,
                                    },
                                ]}
                                onDidDismiss={() => {
                                    setshowWorkCompletedAlert(false);
                                }}
                            ></IonAlert>
                        </IonItem>
                    </IonItemGroup>
                )}
            </IonList>
        </IonPopover>
    );
};

export default PermitMenu;
