import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";

type Props = {};

const AppUrlListener = (props: Props) => {
    //let history = useHistory();
    useEffect(() => {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            // Example url: https://vislock-app-test.azurewebsites.net/#access_token=xxx
            // slug = /tabs/tab2
            //console.log(event.url);
            // const slug = event.url.split(".net").pop();
            // if (slug) {
            //     history.push(slug);
            // }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default AppUrlListener;
