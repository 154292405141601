import React, { useState, useRef } from "react";
import {
    IonModal,
    IonButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent,
    IonLabel,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonItem,
    IonToast,
} from "@ionic/react";
import CustomBox from "./CustomBox";
import CustomItem from "./CustomItem";
import SignatureCanvas from "react-signature-canvas";
import "./SignatureModal.css";
import { swapHorizontal, call } from "ionicons/icons";
import { User } from "../models/User";
import { Guid } from "guid-typescript";
import { PermitAuthorisationSignature } from "../models/PermitAuthorisationSignature";
import { PermitAssignment } from "../models/PermitAssignment";
import { Role } from "../models/Role";

interface Props {
    assignment: PermitAssignment;
    role?: Role;
    currentUser: User | null;
    onSubmit: (signature: PermitAuthorisationSignature, signatureDataUrl: string) => void;
    availableUsers: User[];
}

const ChangePermitAssigneeModal: React.FC<Props> = ({ assignment, role, currentUser, onSubmit, availableUsers }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [declarationCheckbox, setDeclarationCheckbox] = useState(false);
    const [showError, setShowError] = useState(false);
    const signPadWrapperRef = useRef<HTMLDivElement>(null);

    let signPadRef = useRef<SignatureCanvas>(null);

    const onDoneButtonClicked = () => {
        if (!selectedUser || !declarationCheckbox) {
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 5000);
            return;
        }

        if (signPadRef.current) {
            const signatureDataUrl = signPadRef.current.toDataURL("image/png");
            const signature = {
                id: Guid.create().toString(),
                roleId: assignment.roleId,
                userId: selectedUser.id,
                declaration: role?.permitRoleSettings.authorizationDeclaration,
                dateSigned: new Date(),
                loggedInUserId: currentUser?.id,
                signatureFileKey: "",
                signatory: {
                    name: selectedUser?.firstName + " " + selectedUser?.lastName,
                    roleName: role?.name,
                    phoneNumber: selectedUser?.telephoneNumber,
                },
            } as PermitAuthorisationSignature;

            onSubmit(signature, signatureDataUrl);
            onCloseModal();
        }
    };

    const onCloseModal = () => {
        setSelectedUser(undefined);
        setDeclarationCheckbox(false);
        setShowModal(false);
    };

    const handelDeclarationCheckbox = (e: CustomEvent) => {
        signPadWrapperRef.current?.setAttribute("tabindex", "0");
        signPadWrapperRef.current?.focus();
        setDeclarationCheckbox(e.detail.checked);
    };

    const renderAvailableUsers = (user: User) => {
        return (
            <IonSelectOption key={user.id} value={user.id}>
                {user.firstName + " " + user.lastName}
            </IonSelectOption>
        );
    };

    const handlePermitAssigneeAdded = (e: CustomEvent) => {
        let user = availableUsers.find((x) => x.id === e.detail.value);
        setSelectedUser(user);
    };

    return (
        <div>
            <IonModal isOpen={showModal} className="signature-modal">
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Please select new permit assignee:</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={onCloseModal} strong>
                                Cancel
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent class="outer-content">
                    <CustomBox>
                        <IonSelect
                            interface="alert"
                            placeholder="Please click to select"
                            onIonChange={(e) => handlePermitAssigneeAdded(e)}
                        >
                            {availableUsers.map(renderAvailableUsers)}
                        </IonSelect>
                        {selectedUser && role && (
                            <>
                                <CustomItem
                                    title="Role"
                                    text={role.name}
                                    tagIcon={call}
                                    tag={selectedUser.telephoneNumber}
                                    lines="none"
                                ></CustomItem>
                                <IonItem lines="none">
                                    <IonLabel class="ion-text-wrap">
                                        {role.permitRoleSettings.authorizationDeclaration}
                                    </IonLabel>
                                    <IonCheckbox
                                        slot="start"
                                        checked={declarationCheckbox}
                                        onIonChange={handelDeclarationCheckbox}
                                    />
                                </IonItem>
                            </>
                        )}
                    </CustomBox>
                    <CustomBox>
                        <div ref={signPadWrapperRef} className="sign-pad-wrapper">
                            <SignatureCanvas
                                ref={signPadRef}
                                canvasProps={{
                                    width: 520,
                                    height: 200,
                                    className: "sign-pad",
                                }}
                            />
                        </div>
                    </CustomBox>
                </IonContent>
                <IonButton onClick={onDoneButtonClicked}>Done</IonButton>
            </IonModal>
            <IonButton
                onClick={() => {
                    setShowModal(true);
                }}
                shape="round"
                color="medium"
                size="small"
                style={{ marginLeft: "16px" }}
            >
                <IonIcon slot="start" icon={swapHorizontal} />
                <IonLabel>Change</IonLabel>
            </IonButton>
            <IonToast
                isOpen={showError}
                message={`Please select permit assignee then read and confirm the declaration by clicking on the checkbox.`}
                position="top"
            />
        </div>
    );
};

export default ChangePermitAssigneeModal;
