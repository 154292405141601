import React, { useEffect } from "react";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import CustomBox from "../../components/CustomBox";
import { loadProjectUsers } from "./thunks";
import { User } from "../../models/User";
import { service } from "./service";
import { Permit } from "../../models/Permit";
import { PermitAssignment } from "../../models/PermitAssignment";
import { Role } from "../../models/Role";
import ChangePermitAssigneeModal from "../../components/ChangePermitAssigneeModal";
import { PermitAuthorisationSignature } from "../../models/PermitAuthorisationSignature";

interface PermitAssignmentsContainerProps {
    permit: Permit;
    projectId?: string;
    loadProjectUsers: typeof loadProjectUsers;
    projectUsers: User[];
    clientRoles: Role[];
    currentUser: User | null;
    onPermitAssignmentSignatureSubmitted: (signature: PermitAuthorisationSignature, signatureDataUrl: string) => void;
    onPermitAssigneeUpdated: (assignment: PermitAssignment) => void;
    isReadOnly: boolean;
}

const PermitAssignmentsContainer: React.FC<PermitAssignmentsContainerProps> = ({
    permit,
    projectId,
    loadProjectUsers,
    projectUsers,
    clientRoles,
    currentUser,
    onPermitAssignmentSignatureSubmitted,
    onPermitAssigneeUpdated,
    isReadOnly,
}) => {
    useEffect(() => {
        if (projectId) {
            loadProjectUsers(projectId);
        }
    }, [projectId, loadProjectUsers]);

    const handlePermitAssignmentChanged = (userId: string, assignment: PermitAssignment) => {
        const updatedAssignment = {
            id: assignment.id,
            roleId: assignment.roleId,
            userId: userId,
            assignedBy: currentUser?.id,
            dateAssigned: new Date(),
        } as PermitAssignment;

        onPermitAssigneeUpdated(updatedAssignment);
    };

    const handleChangePermitAssigneeModalSubmit = (
        signature: PermitAuthorisationSignature,
        signatureDataUrl: string
    ) => {
        const assignment = permit.permitAssignments?.find((a) => a.roleId === signature.roleId);

        if (assignment) {
            handlePermitAssignmentChanged(signature.userId, assignment);
        }

        onPermitAssignmentSignatureSubmitted(signature, signatureDataUrl);
    };

    const renderAvailableUsers = (user: User) => {
        return (
            <IonSelectOption key={user.id} value={user.id}>
                {user.firstName + " " + user.lastName}
            </IonSelectOption>
        );
    };

    if (!projectId) return null;

    const renderAssignment = (assignment: PermitAssignment) => {
        const hasPermitAssigneeSigned = service.hasPermitAssigneeSigned(assignment, permit);
        const isChangePermitAssigneesAvailable = service.getChangePermitAssigneesAvailability(
            permit,
            currentUser,
            clientRoles
        );
        const assignee = projectUsers.find((u) => u.id === assignment.userId);
        const availableUsers = service.availableUsers(projectId, projectUsers, assignment.roleId);
        const availableUsersToChange = availableUsers.filter((u) => u.id !== assignee?.id);

        const role = clientRoles.find((r) => r.id === assignment.roleId);
        const isPermitAssignmentReadOnly =
            isReadOnly || hasPermitAssigneeSigned || role?.permitRoleSettings.mandatoryOnSubmission;

        return (
            <div key={assignment.id}>
                {isPermitAssignmentReadOnly ? (
                    <>
                        {assignee ? (
                            <>
                                <IonItem key={assignment.id}>
                                    <IonLabel>{role?.name}</IonLabel>
                                    <IonLabel slot="end">{assignee.firstName + " " + assignee.lastName}</IonLabel>
                                </IonItem>
                                {isChangePermitAssigneesAvailable && (
                                    <ChangePermitAssigneeModal
                                        assignment={assignment}
                                        role={role}
                                        currentUser={currentUser}
                                        availableUsers={availableUsersToChange}
                                        onSubmit={handleChangePermitAssigneeModalSubmit}
                                    ></ChangePermitAssigneeModal>
                                )}
                            </>
                        ) : (
                            <p>
                                <IonLabel style={{ margin: "16px" }} class="ion-text-wrap">
                                    User not selected
                                </IonLabel>
                            </p>
                        )}
                    </>
                ) : (
                    <IonItem key={assignment.id}>
                        <IonLabel>{role?.name}</IonLabel>
                        <IonSelect
                            interface="alert"
                            value={assignment.userId}
                            placeholder="Select"
                            onIonChange={(e) => handlePermitAssignmentChanged(e.detail.value!, assignment)}
                        >
                            {availableUsers.map(renderAvailableUsers)}
                        </IonSelect>
                    </IonItem>
                )}
            </div>
        );
    };

    return (
        <CustomBox title="Permit Assignments">{permit.permitAssignments?.map((a) => renderAssignment(a))}</CustomBox>
    );
};

function mapStateToProps(state: AppState) {
    return {
        projectUsers: state.permitAssignments.projectUsers,
        clientRoles: state.myPermits.clientRoles,
        projectId: state.permitPage.permit?.project.id,
        currentUser: state.user.currentUser,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadProjectUsers: (projectId: string) => dispatch(loadProjectUsers(projectId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PermitAssignmentsContainer);
