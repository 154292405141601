import axios, { AxiosResponse } from "axios";
import { UserProfile } from "../../models/UserProfile";

export const authService = {
    login,
};

function login(username: string, password: string): Promise<UserProfile> {
    const webServerUrl = process.env.REACT_APP_WEB_SERVER;
    const tokenUrl = webServerUrl + "/api/token/";

    return axios
        .post(tokenUrl, {
            username,
            password,
        })
        .then((response: AxiosResponse<any>) => {
            return response.data;
        });
}
