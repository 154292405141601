import { UserProfile } from "../../models/UserProfile";
import { User } from "../../models/User";

// Constants
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_PROFILE = "SET_CURRENT_PROFILE";

// State Type
export interface UserState {
    loggedIn: boolean;
    loggingInProgress: boolean;
    currentProfile: UserProfile | null;
    currentUser: User | null;
    loginError: string;
}

// Action Types
interface LoginStartedAction {
    type: typeof LOGIN_STARTED;
}

interface LoginSuccessfulAction {
    type: typeof LOGIN_SUCCESSFUL;
    userProfile: UserProfile;
    currentUser?: User;
}

interface LoginFailedAction {
    type: typeof LOGIN_FAILED;
    error: Error;
}

interface SetCurrentUser {
    type: typeof SET_CURRENT_USER;
    user: User;
}

interface SetCurrentProfile {
    type: typeof SET_CURRENT_PROFILE;
    profile: UserProfile;
}

export type LoginActionTypes =
    | LoginStartedAction
    | LoginSuccessfulAction
    | LoginFailedAction
    | SetCurrentUser
    | SetCurrentProfile;
