import {
    PermitAssignmentsActionTypes,
    LOAD_PROJECT_USERS_STARTED,
    LOAD_PROJECT_USERS_SUCCESSFUL,
    LOAD_PROJECT_USERS_FAILED,
} from "./types";
import { User } from "../../models/User";

export function loadProjectUsersStarted(): PermitAssignmentsActionTypes {
    return {
        type: LOAD_PROJECT_USERS_STARTED,
    };
}

export function loadProjectUsersSuccessful(projectUsers: User[]): PermitAssignmentsActionTypes {
    return {
        type: LOAD_PROJECT_USERS_SUCCESSFUL,
        projectUsers,
    };
}

export function loadProjectUsersFailed(error: Error): PermitAssignmentsActionTypes {
    return {
        type: LOAD_PROJECT_USERS_FAILED,
        error,
    };
}
