import React from "react";
import CustomMap from "./CustomMap";

interface Props {
    longitude: number;
    latitude: number;
}

const locationViewer = ({ longitude, latitude }: Props) => {
    return <CustomMap longitude={longitude} latitude={latitude}></CustomMap>;
};

export default locationViewer;
