import React from "react";
import { HashRouter } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import HomePage from "./pages/HomePage";
import PrivateRoute from "./components/PrivateRoute";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import PermitPage from "./pages/PermitPage";
import ReferenceProcedurePage from "./pages/ReferenceProcedurePage";
import AppUrlListener from "./pages/AppUrlListener";

setupIonicReact();

const App: React.FC = () => {
    return (
        // < IonApp >
        //   <IonReactRouter>
        //     <IonRouterOutlet  >
        //       <PrivateRoute exact path="/" component={HomePage} />
        //       <Route path="/login" component={LoginPage} />
        //       <PrivateRoute path="/home" component={HomePage} exact={true} />
        //       <PrivateRoute path="/home/permit/:id" component={PermitPage} exact={true} />
        //       <PrivateRoute path="/home/referenceprocedure/:procedure/:fileKey" component={ReferenceProcedurePage} exact={true} />
        //     </IonRouterOutlet>
        //   </IonReactRouter>
        // </IonApp >
        <IonApp>
            <div
                style={{
                    position: "absolute",
                    zIndex: "3",
                    float: "right",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(200,200,200,0.5)",
                    fontSize: "12px",
                }}
            >
                {process.env.REACT_APP_ENVIRONMENT_NAME !== "production" ? process.env.REACT_APP_ENVIRONMENT_NAME : ""}
            </div>
            <HashRouter>
                <div>
                    <AppUrlListener />
                    <PrivateRoute exact path="/" component={HomePage} />
                    <PrivateRoute path="/home" component={HomePage} exact={true} />
                    <PrivateRoute path="/home/permit/:id/" component={PermitPage} exact={false} />
                    <PrivateRoute
                        path="/home/referenceprocedure/:procedure/:docId"
                        component={ReferenceProcedurePage}
                        exact={true}
                    />
                </div>
            </HashRouter>
        </IonApp>
    );
};

export default App;
