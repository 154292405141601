import React from "react";
import { IonLabel, IonChip } from "@ionic/react";
import CustomBox from "./CustomBox";
import { PermitAffectedService } from "../models/PermitAffectedService";

interface Props {
    affectedServices: PermitAffectedService[];
}

const PermitDetailsAffectedServices: React.FC<Props> = ({ affectedServices }) => {
    const noAffectedServices = !affectedServices || affectedServices.length === 0;

    const renderService = (serviceName: string) => {
        return (
            <IonChip color="tertiary" key={serviceName}>
                <IonLabel color="tertiary">{serviceName}</IonLabel>
            </IonChip>
        );
    };

    return (
        <CustomBox title="Service itemised below are present">
            {noAffectedServices ? (
                <div style={{ padding: 16 }}>No affected services</div>
            ) : (
                <div style={{ padding: 10 }}>
                    {affectedServices && affectedServices.map((service) => renderService(service.name))}
                </div>
            )}
        </CustomBox>
    );
};

export default PermitDetailsAffectedServices;
