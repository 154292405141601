import React from "react";
import { IonContent } from "@ionic/react";
import PermitDetails from "./PermitDetails";
import PermitDetailsAffectedServices from "./PermitDetailsAffectedServices";
import PermitDetailsSitePhoto from "./PermitDetailsSitePhoto";
import { Permit } from "../models/Permit";
import PermitAssignmentsContainer from "../containers/PermitAssignmentsContainer";
import { User } from "../models/User";
import { PermitAssignment } from "../models/PermitAssignment";
import { PermitAuthorisationSignature } from "../models/PermitAuthorisationSignature";

interface Props {
    permit: Permit;
    currentUser: User;
    permitSubmitterName: string;
    onPermitAssignmentSignatureSubmitted: (signature: PermitAuthorisationSignature, signatureDataUrl: string) => void;
    onPermitAssigneeUpdated: (assignment: PermitAssignment) => void;
    onSitePhotoCaptured: (dataUrl: string) => void;
    onSitePhotoDeleted: (fileKey: string) => void;
    isReadOnly: boolean;
    ableToAddPhotos: boolean;
}

const PermitDetailsTab: React.FC<Props> = ({
    permit,
    currentUser,
    permitSubmitterName,
    onPermitAssignmentSignatureSubmitted,
    onPermitAssigneeUpdated,
    onSitePhotoCaptured,
    onSitePhotoDeleted,
    isReadOnly,
    ableToAddPhotos,
}) => {
    return (
        <IonContent>
            <PermitDetails
                locationOfWork={permit.locationOfWork}
                validFrom={permit.validFrom}
                validTo={permit.validTo}
                comments={permit.comments}
                emergencyContact={permit.emergencyContact}
                submittedBy={permitSubmitterName}
                dateSubmitted={permit.dateSubmitted}
                workDescription={permit.worksDescription}
                maxPenetrationDepth={permit.maxPenetrationDepth}
                permittedEquipment={permit.permittedEquipment}
                subContractor={permit.subContractor}
                project={permit.project}
                catSerialNumber={currentUser.catSerialNumber}
                gennySerialNumber={currentUser.gennySerialNumber}
                catCalibrationDate={currentUser.catCalibrationDate}
                gennyCalibrationDate={currentUser.gennyCalibrationDate}
            />
            <PermitDetailsAffectedServices affectedServices={permit.affectedServices} />
            <PermitDetailsSitePhoto
                permitId={permit.id}
                isReadOnly={isReadOnly}
                sitePhotoFileKeys={permit.sitePhotoFileKeys || []}
                onSitePhotoCaptured={onSitePhotoCaptured}
                onSitePhotoDeleted={onSitePhotoDeleted}
                ableToAddPhotos={ableToAddPhotos}
            />
            <PermitAssignmentsContainer
                permit={permit}
                isReadOnly={isReadOnly}
                onPermitAssignmentSignatureSubmitted={onPermitAssignmentSignatureSubmitted}
                onPermitAssigneeUpdated={onPermitAssigneeUpdated}
            />
        </IonContent>
    );
};

export default PermitDetailsTab;
