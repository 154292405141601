import { IonLabel, IonList, IonItem, IonListHeader, IonModal } from "@ionic/react";
import React, { useRef } from "react";
import { Client } from "../models/Client";

interface SelectClientProps {
    isOpen: boolean;
    clients: Client[] | undefined;
    onClose: () => void;
    onSelectClient: (client: Client) => void;
}

const SelectClient: React.FC<SelectClientProps> = ({ isOpen, clients, onClose, onSelectClient }) => {
    const modal = useRef<HTMLIonModalElement>(null);

    const renderClient = (client: Client) => {
        return (
            <IonItem button lines="none" key={client.id} onClick={(e) => onSelectClient(client)}>
                <IonLabel>{client.name}</IonLabel>
            </IonItem>
        );
    };

    return (
        <IonModal
            ref={modal}
            isOpen={isOpen}
            onDidDismiss={onClose}
            initialBreakpoint={0.4}
            breakpoints={[0, 0.4, 0.8]}
        >
            <div className="wrapper">
                <IonList>
                    <IonListHeader>
                        <IonLabel color="primary">Select Client</IonLabel>
                    </IonListHeader>
                    {clients?.map(renderClient)}
                </IonList>
            </div>
        </IonModal>
    );
};

export default SelectClient;
