import {
    IonList,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonModal,
    IonTitle,
    IonContent,
    IonSearchbar,
    IonLabel,
    IonItem,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { GangMember } from "../models/GangMember";

interface AddPermitGangMemberModalProps {
    isOpen: boolean;
    onClose: (gangMember?: GangMember) => void;
    gangMembers: GangMember[];
}

const AddPermitGangMemberModal: React.FC<AddPermitGangMemberModalProps> = ({ isOpen, onClose, gangMembers }) => {
    const [filteredGangMembers, setFilteredGangMembers] = useState([] as GangMember[]);

    useEffect(() => {
        setFilteredGangMembers([...gangMembers]);
    }, [gangMembers]);

    const handleSearch = (e: CustomEvent) => {
        const searchTerm = e.detail.value!;

        if (!searchTerm) {
            setFilteredGangMembers([...gangMembers]);
        } else if (gangMembers) {
            setFilteredGangMembers(
                gangMembers.filter(
                    (b) =>
                        b.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        b.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        b.inductionNumber.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    };

    const renderList = (list: GangMember[]) => {
        return <IonList>{list.map(renderListItem)}</IonList>;
    };

    const renderListItem = (gangMember: GangMember) => {
        return (
            <IonItem key={gangMember.id} onClick={(event: any) => onClose(gangMember)}>
                <IonLabel>
                    <h2>{gangMember.firstName}</h2>
                    <h3>{gangMember.lastName}</h3>
                    <p>{gangMember.inductionNumber}</p>
                </IonLabel>
            </IonItem>
        );
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={(event: any) => onClose()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Add Gang Member</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={(event: any) => onClose()} strong>
                            Close
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent class="outer-content">
                <IonSearchbar onIonChange={(e) => handleSearch(e)}></IonSearchbar>
                {renderList(filteredGangMembers)}
            </IonContent>
        </IonModal>
    );
};

export default AddPermitGangMemberModal;
