/* export const PERMIT_REQUESTER = "Permit Requester";
export const PERMIT_ISSUER = "Permit Issuer";
export const PERMIT_RECEIVER = "Permit Receiver";
export const PERMIT_RECEIVER_CHANGE = "Permit Receiver Change";

export const PERMIT_ISSUER_DECLARATION =
    "I confirm I have marked on the ground all the known services within the permit to dig boundary and I have briefed the work gang on the location of those services and any allocated exclusion zones to those. The photo attached to the permit is a true representation of the site mark-up at the point of issuing the permit. I confirm the permit to be authorised";
export const PERMIT_RECEIVER_DECLARATION =
    "I can confirm i have been shown the service drawing and been briefed on the location of all known services within the permit boundary and fully understand any exclusion zones control measure. I can confirm i have been briefed on the Task Briefing and understand the works, methods associated to service and will manage this element of the works as appropriate. I can confirm i now take responsibility to manage and brief the work force on a daily basis in relation to their work activity's.";
export const PERMIT_RECEIVER_CHANGE_DECLARATION =
    "I can confirm i have been shown the service drawing and been briefed on the location of all known services within the permit boundary and fully understand any exclusion zones control measure. I can confirm i have been briefed on the Task Briefing and understand the works, methods associated to service and will manage this element of the works as appropriate. I can confirm i now take responsibility to manage and brief the work force on a daily basis in relation to their work activity's.";
export const PERMIT_REQUESTER_DECLARATION =
    "I can confirm I have briefed the team on the Task statement and I confirm this permit to be authorised."; */

export const READY_TO_ISSUE = "Ready To Issue";
export const ISSUED = "Issued";
export const EXPIRED = "Expired";
export const IN_PROGRESS = "In Progress";

export const ISSUE_PERMIT = "IssuePermit";
export const RUN_EIGHT_POINT_CHECKLIST = "RunEightPointChecklist";
export const CHANGE_PERMIT_ASSIGNEES_AFTER_ISSUE = "ChangePermitAssigneesAfterIssue";
export const VIEW_ISSUED_PERMITS = "ViewIssuedPermits";
export const VIEW_READY_TO_ISSUE_PERMITS = "ViewReadyToIssuePermits";
export const MARK_AS_WORK_COMPLETED = "MarkAsWorkCompleted";
export const MANAGE_GANG_MEMBER_OF_READY_TO_ISSUE_PERMITS = "ManageGangMembersOfReadyToIssuePermits";
export const REPORT_PROBLEM_ON_READY_TO_ISSUE_PERMITS = "ReportProblemOnReadyToIssuePermits";
