import { IonPage } from "@ionic/react";
import React from "react";
import MyPermitsContainer from "../containers/MyPermitsContainer";

const HomePage: React.FC = () => {
    return (
        <IonPage>
            <MyPermitsContainer />
        </IonPage>
    );
};

export default HomePage;
