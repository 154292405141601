import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    IonLoading,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonFooter,
} from "@ionic/react";
import { AppState } from "../../store";
import { loadMyPermits, loadReferenceProcedures, loadSyncedUser, tryToConnect } from "./thunks";
import HomeHeader from "../../components/HomeHeader";
import SyncStatusProgressBar from "../../components/SyncStatusProgressBar";
import { UserProfile } from "../../models/UserProfile";
import { setShowExpiredPermitsFlag, setSelectedProject, logOut, setSyncStatus } from "./actions";
import { setCurrentUser } from "../LoginContainer/actions";
import { Project } from "../../models/Project";
import { ReferenceProcedure } from "../../models/ReferenceProcedure";
import HomeFooter from "../../components/HomeFooter";
import { User } from "../../models/User";
import database from "../../Database";
import { Permit } from "../../models/Permit";
import database_Local from "../../Database_Local";
import { loadClientRoles } from "../MyPermitsContainer/thunks";
import { Role } from "../../models/Role";
import { partition, uniqBy } from "lodash";
import MyPermitsHeader from "../../components/MyPermitsHeader";
import MyPermitsList from "../../components/MyPermitsList";
import { useHistory } from "react-router";

interface MyPermitsContainerProps {
    permits: Permit[];
    clientRoles: Role[];
    referenceProcedures: ReferenceProcedure[];
    loading: boolean;
    showExpired: boolean;
    selectedProject: Project | null;
    loadMyPermits: typeof loadMyPermits;
    loadReferenceProcedures: typeof loadReferenceProcedures;
    loadClientRoles: typeof loadClientRoles;
    userProfile: UserProfile | null;
    currentUser: User | null;
    setShowExpiredPermitsFlag: typeof setShowExpiredPermitsFlag;
    setSelectedProjectChanged: typeof setSelectedProject;
    logOut: typeof logOut;
    setSyncStatus: typeof setSyncStatus;
    currentSyncStatus: string;
    setCurrentUser: typeof setCurrentUser;
    loadSyncedUser: typeof loadSyncedUser;
    tryToConnect: typeof tryToConnect;
}

const MyPermitsContainer: React.FC<MyPermitsContainerProps> = ({
    permits,
    clientRoles,
    referenceProcedures,
    loading,
    userProfile,
    currentUser,
    showExpired,
    setShowExpiredPermitsFlag,
    selectedProject,
    setSelectedProjectChanged,
    logOut,
    loadMyPermits,
    loadReferenceProcedures,
    loadClientRoles,
    setSyncStatus,
    currentSyncStatus,
    setCurrentUser,
    loadSyncedUser,
    tryToConnect,
}) => {
    let history = useHistory();

    const [selectedTab, setSelectedTab] = useState("assigned");

    useEffect(() => {
        if (currentUser) {
            if (clientRoles && clientRoles.length > 0) {
                loadMyPermits();
            }
            database.setReplicationCompletedEventHandler((docId) => {
                if (docId.startsWith("permit::")) loadMyPermits();
                if (docId.startsWith("role::")) loadClientRoles(currentUser.client.id);
                if (docId.startsWith("user::")) loadSyncedUser(docId.split("::")[1]);
            });

            database.setReplicatorStatusChangeEventHandler((newStatus) => {
                setSyncStatus(newStatus);
            });

            return () => {
                database.setReplicationCompletedEventHandler(undefined);
                database.setReplicatorStatusChangeEventHandler(undefined);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, clientRoles, loadMyPermits]);

    useEffect(() => {
        if (currentUser) {
            loadReferenceProcedures(currentUser.client.id);
            loadClientRoles(currentUser?.client.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, loadReferenceProcedures]);

    const handleTryToConnect = () => {
        userProfile && tryToConnect(userProfile);
    };

    const doRefresh = () => {
        if (currentUser) {
            loadClientRoles(currentUser?.client.id);
            if (clientRoles && clientRoles.length > 0) {
                loadMyPermits();
            }
            loadReferenceProcedures(currentUser.client.id);
        }

        const refresher: any = document.getElementById("refresher");
        setTimeout(() => {
            refresher.complete();
        }, 1000);
    };

    const handleSelectClientUser = (user: User | undefined) => {
        if (user) {
            setCurrentUser(user);
            database_Local.save("currentUser", user);
        }
    };

    const onLogOut = () => {
        database_Local.deleteAll();
        logOut();
    };

    const handleTabChange = (e: CustomEvent<any>) => {
        setSelectedTab(e.detail.value || "");
    };

    const userName = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : "";
    const permitPartitions = partition(permits, (p) => {
        return p.permitAssignments?.find((a) => a.userId === currentUser?.id);
    });
    const permitsToDisplay = selectedTab === "assigned" ? permitPartitions[0] : permitPartitions[1];
    const selectedProjectPermits =
        selectedProject === null ? [] : permitsToDisplay.filter((p) => p.project.id === selectedProject.id);

    const handleSelectPermit = (id: string): void => {
        history.push(`/home/permit/${id}`);
    };

    const projects = uniqBy(
        permits.map((permit) => permit.project),
        (project) => project.id
    );

    return (
        <>
            <IonContent>
                <SyncStatusProgressBar currentSyncStatus={currentSyncStatus} onTryToConnect={handleTryToConnect} />
                <HomeHeader
                    currentUser={currentUser}
                    clientUsers={userProfile?.clientUsers}
                    onSelectClientUser={handleSelectClientUser}
                    onLogOut={onLogOut}
                    userName={userName}
                />
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} id="refresher">
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <MyPermitsHeader
                    showExpired={showExpired}
                    onShowExpiredChange={setShowExpiredPermitsFlag}
                    selectedProject={selectedProject}
                    projects={projects}
                    onSelectedProjectChange={setSelectedProjectChanged}
                />
                <MyPermitsList
                    onSelectPermit={handleSelectPermit}
                    permits={selectedProjectPermits}
                    showExpired={showExpired}
                />
                <IonLoading isOpen={loading} message={"Loading..."} duration={150} />
            </IonContent>
            <IonFooter>
                <IonSegment color="dark" value={selectedTab} onIonChange={handleTabChange}>
                    <IonSegmentButton value="assigned">
                        <IonLabel>Assigned to me</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="all">
                        <IonLabel>Other permits</IonLabel>
                    </IonSegmentButton>
                </IonSegment>
                <HomeFooter referenceProcedures={referenceProcedures} />
            </IonFooter>
        </>
    );
};

function mapStateToProps(state: AppState) {
    return {
        loading: state.myPermits.loading,
        showExpired: state.myPermits.showExpired,
        permits: state.myPermits.permits,
        clientRoles: state.myPermits.clientRoles,
        referenceProcedures: state.myPermits.referenceProcedures,
        userProfile: state.user.currentProfile,
        currentUser: state.user.currentUser,
        selectedProject: state.myPermits.selectedProject,
        currentSyncStatus: state.myPermits.currentSyncStatus,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadMyPermits: () => dispatch(loadMyPermits()),
        loadReferenceProcedures: (clientId: string) => dispatch(loadReferenceProcedures(clientId)),
        loadClientRoles: (clientId: string) => dispatch(loadClientRoles(clientId)),
        setShowExpiredPermitsFlag: (value: boolean) => dispatch(setShowExpiredPermitsFlag(value)),
        setSelectedProjectChanged: (value: Project | null) => dispatch(setSelectedProject(value)),
        logOut: () => dispatch(logOut()),
        setSyncStatus: (newStatus: string) => dispatch(setSyncStatus(newStatus)),
        setCurrentUser: (user: User) => dispatch(setCurrentUser(user)),
        loadSyncedUser: (userId: string) => dispatch(loadSyncedUser(userId)),
        tryToConnect: (userProfile: UserProfile) => dispatch(tryToConnect(userProfile)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPermitsContainer);
