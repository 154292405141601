import { IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonCard, IonIcon } from "@ionic/react";
import React from "react";
import "./MyPermitsList.css";
import moment from "moment";
import PermitFlagService, { GreenFlag } from "../PermitFlagService";
import { Permit } from "../models/Permit";
import { EXPIRED, ISSUED, READY_TO_ISSUE } from "../constants";
import { informationCircleOutline } from "ionicons/icons";

interface MyPermitsListProps {
    permits: Permit[];
    showExpired: boolean;
    onSelectPermit: (id: string) => void;
}

const MyPermitsList: React.FC<MyPermitsListProps> = ({ permits, showExpired, onSelectPermit }) => {
    const permitGroups: string[] = Array.from(new Set(permits.map((p) => p.status)));

    const renderItem = (permit: Permit) => {
        return (
            <IonCard key={permit.id} mode="md">
                {permit.status === EXPIRED ? (
                    <IonItem>
                        <IonLabel className="expired">
                            <h3>
                                {permit.permitNumber} - {permit.worksDescription}
                            </h3>
                            <p>
                                {moment(permit.validFrom).format("L")}: {permit.locationOfWork}
                            </p>
                        </IonLabel>
                    </IonItem>
                ) : (
                    <IonItem
                        detail={true}
                        onClick={(e: any) => {
                            e.preventDefault();
                            onSelectPermit(permit.id);
                        }}
                    >
                        <IonLabel
                            className={
                                PermitFlagService.getPermitFlag(permit) === GreenFlag ? "flag-green" : "flag-red"
                            }
                        >
                            <h3>
                                {permit.permitNumber} - {permit.worksDescription}
                            </h3>
                            <p>
                                {moment(permit.validFrom).format("L")}: {permit.locationOfWork}
                            </p>
                        </IonLabel>
                    </IonItem>
                )}
            </IonCard>
        );
    };

    const renderGroup = (group: string) => {
        const groupPermits = permits
            .filter((p) => p.status === group)
            .sort((a, b) => (a.permitNumber < b.permitNumber ? 1 : -1));
        return (
            <IonItemGroup key={group}>
                <IonItemDivider sticky={true} style={{ fontWeight: 400, fontSize: 14 }}>
                    <IonLabel>
                        {group} ({groupPermits.length})
                    </IonLabel>
                </IonItemDivider>
                {groupPermits && groupPermits.map(renderItem)}
            </IonItemGroup>
        );
    };

    return (
        <IonList lines="full">
            {permits.length === 0 && (
                <IonItem>
                    <IonIcon icon={informationCircleOutline}></IonIcon>
                    <IonLabel color="medium">There are no permits to display!</IonLabel>
                </IonItem>
            )}
            {permitGroups.includes(READY_TO_ISSUE) && renderGroup(READY_TO_ISSUE)}
            {permitGroups.includes(ISSUED) && renderGroup(ISSUED)}
            {showExpired && permitGroups.includes(EXPIRED) && renderGroup(EXPIRED)}
        </IonList>
    );
};

export default MyPermitsList;
