import React, { Component } from "react";
import { Guid } from "guid-typescript";
import {
    IonApp,
    IonPage,
    IonContent,
    IonCard,
    IonButton,
    IonCardTitle,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonText,
} from "@ionic/react";

interface Props {
    children: any;
}
interface State {
    hasError: boolean;
    error: string;
    info: string;
    uuid: string | null;
}

export default class GlobalErrorHandler extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: "",
            info: "",
            uuid: null,
        };
    }

    componentDidCatch(error: any, info: any) {
        const uuidRef = Guid.create().toString();
        const errorMessage = error.message;
        const errorInfo = `${JSON.stringify(error, Object.getOwnPropertyNames(error))} at ${JSON.stringify(info)}`;

        // Display fallback UI
        this.setState({
            hasError: true,
            error: errorMessage,
            info: errorInfo,
            uuid: uuidRef,
        });
    }

    renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    render() {
        const { hasError, error, info } = this.state;

        if (hasError) {
            return (
                <IonApp>
                    <IonPage>
                        <IonContent>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle>Error</IonCardSubtitle>
                                    <IonCardTitle>Something went wrong.</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    <p>
                                        <IonButton href="/">Go back to Home</IonButton>
                                    </p>
                                    <IonText color="medium">
                                        <br />
                                        <hr />
                                        <br />
                                        <p>{this.renderHTML(error)}</p>
                                        <p>{this.renderHTML(JSON.stringify(info))}</p>
                                    </IonText>
                                </IonCardContent>
                            </IonCard>
                        </IonContent>
                    </IonPage>
                </IonApp>
            );
        }
        return this.props.children;
    }
}
