import Axios, { AxiosRequestConfig, Method, AxiosResponse, ResponseType } from "axios";

export interface Response<T = any> {
    data: T;
    status: number;
    statusText: string;
    headers: any;
}

export function request<T = any>(
    method: string,
    url: string,
    headers: any,
    responseType: string = "json"
): Promise<Response<T>> {
    const axiosConfig: AxiosRequestConfig = {
        headers,
        method: method as Method,
        url,
        responseType: responseType as ResponseType,
    };

    Axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response === 401) {
                console.log("Error 401");
            }
            return Promise.reject(error);
        }
    );

    return Axios.request<T>(axiosConfig).then((response: AxiosResponse<T>) => {
        return {
            data: response.data,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
        };
    });
}
